import './App.css';
import styled from "styled-components";
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const Wrapper = styled.div`
  padding: 55px;
`;

const MWrapper = styled.main`
  background-color: skyblue;
`;

const ImageWrapper = styled(ImageList)`
  grid-template-columns: repeat(5, 1fr) !important;
  gap: 30px !important;
`;

const ListWrapper = styled.div`
  background-color: white;
  padding: 14px;
  border-radius: 12px!important;
`;

const Title = styled.h2`
  font-size: 40px;
`;

const SubTitle = styled.p`
  font-size: 25px;
  padding-bottom: 40px;
`;

const NameSpan = styled.span`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const RankSpan = styled(NameSpan)`
  font-size: 14px;
`;

const itemData = [
    {
        url: './2/2220.png',
        name: 'BSTC#2220',
        rank: 'Rank1',
    },
    {
        url: './2/136.png',
        name: 'BSTC#136',
        rank: 'Rank2',
    },
    {
        url: './2/2174.png',
        name: 'BSTC#2174',
        rank: 'Rank3',
    },
    {
        url: './2/2057.png',
        name: 'BSTC#2057',
        rank: 'Rank4',
    },
    {
        url: './2/840.png',
        name: 'BSTC#840',
        rank: 'Rank5',
    },
    {
        url: './2/275.png',
        name: 'BSTC#275',
        rank: 'Rank6',
    },
    {
        url: './2/2518.png',
        name: 'BSTC#2518',
        rank: 'Rank7',
    },
    {
        url: './2/2794.png',
        name: 'BSTC#2794',
        rank: 'Rank8',
    },
    {
        url: './2/2091.png',
        name: 'BSTC#2091',
        rank: 'Rank9',
    },
    {
        url: './2/234.png',
        name: 'BSTC#234',
        rank: 'Rank10',
    },
    {
        url: './2/1693.png',
        name: 'BSTC#1693',
        rank: 'Rank11',
    },
    {
        url: './2/2712.png',
        name: 'BSTC#2712',
        rank: 'Rank12',
    },
    {
        url: './2/2676.png',
        name: 'BSTC#2676',
        rank: 'Rank13',
    },
    {
        url: './2/1692.png',
        name: 'BSTC#1692',
        rank: 'Rank14',
    },
    {
        url: './2/2242.png',
        name: 'BSTC#2242',
        rank: 'Rank15',
    },
    {
        url: './2/2732.png',
        name: 'BSTC#2732',
        rank: 'Rank16',
    },
    {
        url: './2/135.png',
        name: 'BSTC#135',
        rank: 'Rank17',
    },
    {
        url: './2/2963.png',
        name: 'BSTC#2963',
        rank: 'Rank18',
    },
    {
        url: './2/1115.png',
        name: 'BSTC#1115',
        rank: 'Rank19',
    },
    {
        url: './2/1007.png',
        name: 'BSTC#1007',
        rank: 'Rank20',
    },
    {
        url: './2/193.png',
        name: 'BSTC#193',
        rank: 'Rank21',
    },
    {
        url: './2/2308.png',
        name: 'BSTC#2308',
        rank: 'Rank22',
    },
    {
        url: './2/74.png',
        name: 'BSTC#74',
        rank: 'Rank23',
    },
    {
        url: './2/2225.png',
        name: 'BSTC#2225',
        rank: 'Rank24',
    },
    {
        url: './2/2894.png',
        name: 'BSTC#2894',
        rank: 'Rank25',
    },
    {
        url: './2/232.png',
        name: 'BSTC#232',
        rank: 'Rank26',
    },
    {
        url: './2/1032.png',
        name: 'BSTC#1032',
        rank: 'Rank27',
    },
    {
        url: './2/331.png',
        name: 'BSTC#331',
        rank: 'Rank28',
    },
    {
        url: './2/1224.png',
        name: 'BSTC#1224',
        rank: 'Rank29',
    },
    {
        url: './2/249.png',
        name: 'BSTC#249',
        rank: 'Rank30',
    },
    {
        url: './2/2610.png',
        name: 'BSTC#2610',
        rank: 'Rank31',
    },
    {
        url: './2/870.png',
        name: 'BSTC#870',
        rank: 'Rank32',
    },
    {
        url: './2/620.png',
        name: 'BSTC#620',
        rank: 'Rank33',
    },
    {
        url: './2/373.png',
        name: 'BSTC#373',
        rank: 'Rank34',
    },
    {
        url: './2/2050.png',
        name: 'BSTC#2050',
        rank: 'Rank35',
    },
    {
        url: './2/1043.png',
        name: 'BSTC#1043',
        rank: 'Rank36',
    },
    {
        url: './2/2116.png',
        name: 'BSTC#2116',
        rank: 'Rank37',
    },
    {
        url: './2/947.png',
        name: 'BSTC#947',
        rank: 'Rank38',
    },
    {
        url: './2/86.png',
        name: 'BSTC#86',
        rank: 'Rank39',
    },
    {
        url: './2/1164.png',
        name: 'BSTC#1164',
        rank: 'Rank40',
    },
    {
        url: './2/580.png',
        name: 'BSTC#580',
        rank: 'Rank41',
    },
    {
        url: './2/456.png',
        name: 'BSTC#456',
        rank: 'Rank42',
    },
    {
        url: './2/786.png',
        name: 'BSTC#786',
        rank: 'Rank43',
    },
    {
        url: './2/112.png',
        name: 'BSTC#112',
        rank: 'Rank44',
    },
    {
        url: './2/2689.png',
        name: 'BSTC#2689',
        rank: 'Rank45',
    },
    {
        url: './2/377.png',
        name: 'BSTC#377',
        rank: 'Rank46',
    },
    {
        url: './2/769.png',
        name: 'BSTC#769',
        rank: 'Rank47',
    },
    {
        url: './2/2771.png',
        name: 'BSTC#2771',
        rank: 'Rank48',
    },
    {
        url: './2/702.png',
        name: 'BSTC#702',
        rank: 'Rank49',
    },
    {
        url: './2/3109.png',
        name: 'BSTC#3109',
        rank: 'Rank50',
    },
    {
        url: './2/1339.png',
        name: 'BSTC#1339',
        rank: 'Rank51',
    },
    {
        url: './2/440.png',
        name: 'BSTC#440',
        rank: 'Rank52',
    },
    {
        url: './2/777.png',
        name: 'BSTC#777',
        rank: 'Rank53',
    },
    {
        url: './2/1965.png',
        name: 'BSTC#1965',
        rank: 'Rank54',
    },
    {
        url: './2/937.png',
        name: 'BSTC#937',
        rank: 'Rank55',
    },
    {
        url: './2/2691.png',
        name: 'BSTC#2691',
        rank: 'Rank56',
    },
    {
        url: './2/445.png',
        name: 'BSTC#445',
        rank: 'Rank57',
    },
    {
        url: './2/2767.png',
        name: 'BSTC#2767',
        rank: 'Rank58',
    },
    {
        url: './2/3064.png',
        name: 'BSTC#3064',
        rank: 'Rank59',
    },
    {
        url: './2/1088.png',
        name: 'BSTC#1088',
        rank: 'Rank60',
    },
    {
        url: './2/518.png',
        name: 'BSTC#518',
        rank: 'Rank61',
    },
    {
        url: './2/2470.png',
        name: 'BSTC#2470',
        rank: 'Rank62',
    },
    {
        url: './2/1804.png',
        name: 'BSTC#1804',
        rank: 'Rank63',
    },
    {
        url: './2/718.png',
        name: 'BSTC#718',
        rank: 'Rank64',
    },
    {
        url: './2/299.png',
        name: 'BSTC#299',
        rank: 'Rank65',
    },
    {
        url: './2/2300.png',
        name: 'BSTC#2300',
        rank: 'Rank66',
    },
    {
        url: './2/122.png',
        name: 'BSTC#122',
        rank: 'Rank67',
    },
    {
        url: './2/2372.png',
        name: 'BSTC#2372',
        rank: 'Rank68',
    },
    {
        url: './2/1676.png',
        name: 'BSTC#1676',
        rank: 'Rank69',
    },
    {
        url: './2/425.png',
        name: 'BSTC#425',
        rank: 'Rank70',
    },
    {
        url: './2/2394.png',
        name: 'BSTC#2394',
        rank: 'Rank71',
    },
    {
        url: './2/1951.png',
        name: 'BSTC#1951',
        rank: 'Rank72',
    },
    {
        url: './2/326.png',
        name: 'BSTC#326',
        rank: 'Rank73',
    },
    {
        url: './2/2541.png',
        name: 'BSTC#2541',
        rank: 'Rank74',
    },
    {
        url: './2/1931.png',
        name: 'BSTC#1931',
        rank: 'Rank75',
    },
    {
        url: './2/2702.png',
        name: 'BSTC#2702',
        rank: 'Rank76',
    },
    {
        url: './2/230.png',
        name: 'BSTC#230',
        rank: 'Rank77',
    },
    {
        url: './2/65.png',
        name: 'BSTC#65',
        rank: 'Rank78',
    },
    {
        url: './2/1778.png',
        name: 'BSTC#1778',
        rank: 'Rank79',
    },
    {
        url: './2/2615.png',
        name: 'BSTC#2615',
        rank: 'Rank80',
    },
    {
        url: './2/171.png',
        name: 'BSTC#171',
        rank: 'Rank81',
    },
    {
        url: './2/2370.png',
        name: 'BSTC#2370',
        rank: 'Rank82',
    },
    {
        url: './2/450.png',
        name: 'BSTC#450',
        rank: 'Rank83',
    },
    {
        url: './2/1026.png',
        name: 'BSTC#1026',
        rank: 'Rank84',
    },
    {
        url: './2/1908.png',
        name: 'BSTC#1908',
        rank: 'Rank85',
    },
    {
        url: './2/805.png',
        name: 'BSTC#805',
        rank: 'Rank86',
    },
    {
        url: './2/2973.png',
        name: 'BSTC#2973',
        rank: 'Rank87',
    },
    {
        url: './2/1451.png',
        name: 'BSTC#1451',
        rank: 'Rank88',
    },
    {
        url: './2/2797.png',
        name: 'BSTC#2797',
        rank: 'Rank89',
    },
    {
        url: './2/131.png',
        name: 'BSTC#131',
        rank: 'Rank90',
    },
    {
        url: './2/1751.png',
        name: 'BSTC#1751',
        rank: 'Rank91',
    },
    {
        url: './2/2868.png',
        name: 'BSTC#2868',
        rank: 'Rank92',
    },
    {
        url: './2/1800.png',
        name: 'BSTC#1800',
        rank: 'Rank93',
    },
    {
        url: './2/384.png',
        name: 'BSTC#384',
        rank: 'Rank94',
    },
    {
        url: './2/493.png',
        name: 'BSTC#493',
        rank: 'Rank95',
    },
    {
        url: './2/1724.png',
        name: 'BSTC#1724',
        rank: 'Rank96',
    },
    {
        url: './2/1964.png',
        name: 'BSTC#1964',
        rank: 'Rank97',
    },
    {
        url: './2/1488.png',
        name: 'BSTC#1488',
        rank: 'Rank98',
    },
    {
        url: './2/2912.png',
        name: 'BSTC#2912',
        rank: 'Rank99',
    },
    {
        url: './2/3135.png',
        name: 'BSTC#3135',
        rank: 'Rank100',
    },
    {
        url: './2/3119.png',
        name: 'BSTC#3119',
        rank: 'Rank101',
    },
    {
        url: './2/1690.png',
        name: 'BSTC#1690',
        rank: 'Rank102',
    },
    {
        url: './2/381.png',
        name: 'BSTC#381',
        rank: 'Rank103',
    },
    {
        url: './2/1191.png',
        name: 'BSTC#1191',
        rank: 'Rank104',
    },
    {
        url: './2/1720.png',
        name: 'BSTC#1720',
        rank: 'Rank105',
    },
    {
        url: './2/318.png',
        name: 'BSTC#318',
        rank: 'Rank106',
    },
    {
        url: './2/787.png',
        name: 'BSTC#787',
        rank: 'Rank107',
    },
    {
        url: './2/1527.png',
        name: 'BSTC#1527',
        rank: 'Rank108',
    },
    {
        url: './2/2043.png',
        name: 'BSTC#2043',
        rank: 'Rank109',
    },
    {
        url: './2/19.png',
        name: 'BSTC#19',
        rank: 'Rank110',
    },
    {
        url: './2/1053.png',
        name: 'BSTC#1053',
        rank: 'Rank111',
    },
    {
        url: './2/1775.png',
        name: 'BSTC#1775',
        rank: 'Rank112',
    },
    {
        url: './2/1398.png',
        name: 'BSTC#1398',
        rank: 'Rank113',
    },
    {
        url: './2/1600.png',
        name: 'BSTC#1600',
        rank: 'Rank114',
    },
    {
        url: './2/2680.png',
        name: 'BSTC#2680',
        rank: 'Rank115',
    },
    {
        url: './2/686.png',
        name: 'BSTC#686',
        rank: 'Rank116',
    },
    {
        url: './2/1734.png',
        name: 'BSTC#1734',
        rank: 'Rank117',
    },
    {
        url: './2/1018.png',
        name: 'BSTC#1018',
        rank: 'Rank118',
    },
    {
        url: './2/1228.png',
        name: 'BSTC#1228',
        rank: 'Rank119',
    },
    {
        url: './2/165.png',
        name: 'BSTC#165',
        rank: 'Rank120',
    },
    {
        url: './2/1312.png',
        name: 'BSTC#1312',
        rank: 'Rank121',
    },
    {
        url: './2/2826.png',
        name: 'BSTC#2826',
        rank: 'Rank122',
    },
    {
        url: './2/568.png',
        name: 'BSTC#568',
        rank: 'Rank123',
    },
    {
        url: './2/2965.png',
        name: 'BSTC#2965',
        rank: 'Rank124',
    },
    {
        url: './2/1391.png',
        name: 'BSTC#1391',
        rank: 'Rank125',
    },
    {
        url: './2/2414.png',
        name: 'BSTC#2414',
        rank: 'Rank126',
    },
    {
        url: './2/607.png',
        name: 'BSTC#607',
        rank: 'Rank127',
    },
    {
        url: './2/266.png',
        name: 'BSTC#266',
        rank: 'Rank128',
    },
    {
        url: './2/2191.png',
        name: 'BSTC#2191',
        rank: 'Rank129',
    },
    {
        url: './2/2306.png',
        name: 'BSTC#2306',
        rank: 'Rank130',
    },
    {
        url: './2/1572.png',
        name: 'BSTC#1572',
        rank: 'Rank131',
    },
    {
        url: './2/1267.png',
        name: 'BSTC#1267',
        rank: 'Rank132',
    },
    {
        url: './2/156.png',
        name: 'BSTC#156',
        rank: 'Rank133',
    },
    {
        url: './2/2420.png',
        name: 'BSTC#2420',
        rank: 'Rank134',
    },
    {
        url: './2/2539.png',
        name: 'BSTC#2539',
        rank: 'Rank135',
    },
    {
        url: './2/34.png',
        name: 'BSTC#34',
        rank: 'Rank136',
    },
    {
        url: './2/1821.png',
        name: 'BSTC#1821',
        rank: 'Rank137',
    },
    {
        url: './2/2240.png',
        name: 'BSTC#2240',
        rank: 'Rank138',
    },
    {
        url: './2/3067.png',
        name: 'BSTC#3067',
        rank: 'Rank139',
    },
    {
        url: './2/932.png',
        name: 'BSTC#932',
        rank: 'Rank140',
    },
    {
        url: './2/439.png',
        name: 'BSTC#439',
        rank: 'Rank141',
    },
    {
        url: './2/317.png',
        name: 'BSTC#317',
        rank: 'Rank142',
    },
    {
        url: './2/347.png',
        name: 'BSTC#347',
        rank: 'Rank143',
    },
    {
        url: './2/2128.png',
        name: 'BSTC#2128',
        rank: 'Rank144',
    },
    {
        url: './2/1245.png',
        name: 'BSTC#1245',
        rank: 'Rank145',
    },
    {
        url: './2/235.png',
        name: 'BSTC#235',
        rank: 'Rank146',
    },
    {
        url: './2/1471.png',
        name: 'BSTC#1471',
        rank: 'Rank147',
    },
    {
        url: './2/486.png',
        name: 'BSTC#486',
        rank: 'Rank148',
    },
    {
        url: './2/562.png',
        name: 'BSTC#562',
        rank: 'Rank149',
    },
    {
        url: './2/2045.png',
        name: 'BSTC#2045',
        rank: 'Rank150',
    },
    {
        url: './2/2319.png',
        name: 'BSTC#2319',
        rank: 'Rank151',
    },
    {
        url: './2/2296.png',
        name: 'BSTC#2296',
        rank: 'Rank152',
    },
    {
        url: './2/1116.png',
        name: 'BSTC#1116',
        rank: 'Rank153',
    },
    {
        url: './2/1279.png',
        name: 'BSTC#1279',
        rank: 'Rank154',
    },
    {
        url: './2/1954.png',
        name: 'BSTC#1954',
        rank: 'Rank155',
    },
    {
        url: './2/2480.png',
        name: 'BSTC#2480',
        rank: 'Rank156',
    },
    {
        url: './2/1050.png',
        name: 'BSTC#1050',
        rank: 'Rank157',
    },
    {
        url: './2/663.png',
        name: 'BSTC#663',
        rank: 'Rank158',
    },
    {
        url: './2/2374.png',
        name: 'BSTC#2374',
        rank: 'Rank159',
    },
    {
        url: './2/762.png',
        name: 'BSTC#762',
        rank: 'Rank160',
    },
    {
        url: './2/1387.png',
        name: 'BSTC#1387',
        rank: 'Rank161',
    },
    {
        url: './2/2791.png',
        name: 'BSTC#2791',
        rank: 'Rank162',
    },
    {
        url: './2/912.png',
        name: 'BSTC#912',
        rank: 'Rank163',
    },
    {
        url: './2/930.png',
        name: 'BSTC#930',
        rank: 'Rank164',
    },
    {
        url: './2/1068.png',
        name: 'BSTC#1068',
        rank: 'Rank165',
    },
    {
        url: './2/1250.png',
        name: 'BSTC#1250',
        rank: 'Rank166',
    },
    {
        url: './2/1289.png',
        name: 'BSTC#1289',
        rank: 'Rank167',
    },
    {
        url: './2/1769.png',
        name: 'BSTC#1769',
        rank: 'Rank168',
    },
    {
        url: './2/2823.png',
        name: 'BSTC#2823',
        rank: 'Rank169',
    },
    {
        url: './2/2180.png',
        name: 'BSTC#2180',
        rank: 'Rank170',
    },
    {
        url: './2/3052.png',
        name: 'BSTC#3052',
        rank: 'Rank171',
    },
    {
        url: './2/1416.png',
        name: 'BSTC#1416',
        rank: 'Rank172',
    },
    {
        url: './2/2921.png',
        name: 'BSTC#2921',
        rank: 'Rank173',
    },
    {
        url: './2/2040.png',
        name: 'BSTC#2040',
        rank: 'Rank174',
    },
    {
        url: './2/2698.png',
        name: 'BSTC#2698',
        rank: 'Rank175',
    },
    {
        url: './2/1443.png',
        name: 'BSTC#1443',
        rank: 'Rank176',
    },
    {
        url: './2/2533.png',
        name: 'BSTC#2533',
        rank: 'Rank177',
    },
    {
        url: './2/2293.png',
        name: 'BSTC#2293',
        rank: 'Rank178',
    },
    {
        url: './2/860.png',
        name: 'BSTC#860',
        rank: 'Rank179',
    },
    {
        url: './2/1107.png',
        name: 'BSTC#1107',
        rank: 'Rank180',
    },
    {
        url: './2/1594.png',
        name: 'BSTC#1594',
        rank: 'Rank181',
    },
    {
        url: './2/467.png',
        name: 'BSTC#467',
        rank: 'Rank182',
    },
    {
        url: './2/682.png',
        name: 'BSTC#682',
        rank: 'Rank183',
    },
    {
        url: './2/434.png',
        name: 'BSTC#434',
        rank: 'Rank184',
    },
    {
        url: './2/2534.png',
        name: 'BSTC#2534',
        rank: 'Rank185',
    },
    {
        url: './2/617.png',
        name: 'BSTC#617',
        rank: 'Rank186',
    },
    {
        url: './2/147.png',
        name: 'BSTC#147',
        rank: 'Rank187',
    },
    {
        url: './2/198.png',
        name: 'BSTC#198',
        rank: 'Rank188',
    },
    {
        url: './2/2355.png',
        name: 'BSTC#2355',
        rank: 'Rank189',
    },
    {
        url: './2/958.png',
        name: 'BSTC#958',
        rank: 'Rank190',
    },
    {
        url: './2/2439.png',
        name: 'BSTC#2439',
        rank: 'Rank191',
    },
    {
        url: './2/367.png',
        name: 'BSTC#367',
        rank: 'Rank192',
    },
    {
        url: './2/2501.png',
        name: 'BSTC#2501',
        rank: 'Rank193',
    },
    {
        url: './2/1792.png',
        name: 'BSTC#1792',
        rank: 'Rank194',
    },
    {
        url: './2/2266.png',
        name: 'BSTC#2266',
        rank: 'Rank195',
    },
    {
        url: './2/354.png',
        name: 'BSTC#354',
        rank: 'Rank196',
    },
    {
        url: './2/238.png',
        name: 'BSTC#238',
        rank: 'Rank197',
    },
    {
        url: './2/2467.png',
        name: 'BSTC#2467',
        rank: 'Rank198',
    },
    {
        url: './2/2752.png',
        name: 'BSTC#2752',
        rank: 'Rank199',
    },
    {
        url: './2/1397.png',
        name: 'BSTC#1397',
        rank: 'Rank200',
    },
    {
        url: './2/2158.png',
        name: 'BSTC#2158',
        rank: 'Rank201',
    },
    {
        url: './2/2757.png',
        name: 'BSTC#2757',
        rank: 'Rank202',
    },
    {
        url: './2/694.png',
        name: 'BSTC#694',
        rank: 'Rank203',
    },
    {
        url: './2/671.png',
        name: 'BSTC#671',
        rank: 'Rank204',
    },
    {
        url: './2/1944.png',
        name: 'BSTC#1944',
        rank: 'Rank205',
    },
    {
        url: './2/2096.png',
        name: 'BSTC#2096',
        rank: 'Rank206',
    },
    {
        url: './2/211.png',
        name: 'BSTC#211',
        rank: 'Rank207',
    },
    {
        url: './2/1121.png',
        name: 'BSTC#1121',
        rank: 'Rank208',
    },
    {
        url: './2/2100.png',
        name: 'BSTC#2100',
        rank: 'Rank209',
    },
    {
        url: './2/894.png',
        name: 'BSTC#894',
        rank: 'Rank210',
    },
    {
        url: './2/176.png',
        name: 'BSTC#176',
        rank: 'Rank211',
    },
    {
        url: './2/3075.png',
        name: 'BSTC#3075',
        rank: 'Rank212',
    },
    {
        url: './2/902.png',
        name: 'BSTC#902',
        rank: 'Rank213',
    },
    {
        url: './2/2634.png',
        name: 'BSTC#2634',
        rank: 'Rank214',
    },
    {
        url: './2/1401.png',
        name: 'BSTC#1401',
        rank: 'Rank215',
    },
    {
        url: './2/2867.png',
        name: 'BSTC#2867',
        rank: 'Rank216',
    },
    {
        url: './2/943.png',
        name: 'BSTC#943',
        rank: 'Rank217',
    },
    {
        url: './2/2720.png',
        name: 'BSTC#2720',
        rank: 'Rank218',
    },
    {
        url: './2/1958.png',
        name: 'BSTC#1958',
        rank: 'Rank219',
    },
    {
        url: './2/453.png',
        name: 'BSTC#453',
        rank: 'Rank220',
    },
    {
        url: './2/2930.png',
        name: 'BSTC#2930',
        rank: 'Rank221',
    },
    {
        url: './2/547.png',
        name: 'BSTC#547',
        rank: 'Rank222',
    },
    {
        url: './2/2302.png',
        name: 'BSTC#2302',
        rank: 'Rank223',
    },
    {
        url: './2/2815.png',
        name: 'BSTC#2815',
        rank: 'Rank224',
    },
    {
        url: './2/3074.png',
        name: 'BSTC#3074',
        rank: 'Rank225',
    },
    {
        url: './2/308.png',
        name: 'BSTC#308',
        rank: 'Rank226',
    },
    {
        url: './2/167.png',
        name: 'BSTC#167',
        rank: 'Rank227',
    },
    {
        url: './2/1436.png',
        name: 'BSTC#1436',
        rank: 'Rank228',
    },
    {
        url: './2/2564.png',
        name: 'BSTC#2564',
        rank: 'Rank229',
    },
    {
        url: './2/683.png',
        name: 'BSTC#683',
        rank: 'Rank230',
    },
    {
        url: './2/861.png',
        name: 'BSTC#861',
        rank: 'Rank231',
    },
    {
        url: './2/881.png',
        name: 'BSTC#881',
        rank: 'Rank232',
    },
    {
        url: './2/1667.png',
        name: 'BSTC#1667',
        rank: 'Rank233',
    },
    {
        url: './2/2137.png',
        name: 'BSTC#2137',
        rank: 'Rank234',
    },
    {
        url: './2/2219.png',
        name: 'BSTC#2219',
        rank: 'Rank235',
    },
    {
        url: './2/3142.png',
        name: 'BSTC#3142',
        rank: 'Rank236',
    },
    {
        url: './2/3054.png',
        name: 'BSTC#3054',
        rank: 'Rank237',
    },
    {
        url: './2/879.png',
        name: 'BSTC#879',
        rank: 'Rank238',
    },
    {
        url: './2/2918.png',
        name: 'BSTC#2918',
        rank: 'Rank239',
    },
    {
        url: './2/1382.png',
        name: 'BSTC#1382',
        rank: 'Rank240',
    },
    {
        url: './2/3110.png',
        name: 'BSTC#3110',
        rank: 'Rank241',
    },
    {
        url: './2/80.png',
        name: 'BSTC#80',
        rank: 'Rank242',
    },
    {
        url: './2/182.png',
        name: 'BSTC#182',
        rank: 'Rank243',
    },
    {
        url: './2/761.png',
        name: 'BSTC#761',
        rank: 'Rank244',
    },
    {
        url: './2/523.png',
        name: 'BSTC#523',
        rank: 'Rank245',
    },
    {
        url: './2/2643.png',
        name: 'BSTC#2643',
        rank: 'Rank246',
    },
    {
        url: './2/776.png',
        name: 'BSTC#776',
        rank: 'Rank247',
    },
    {
        url: './2/1980.png',
        name: 'BSTC#1980',
        rank: 'Rank248',
    },
    {
        url: './2/1094.png',
        name: 'BSTC#1094',
        rank: 'Rank249',
    },
    {
        url: './2/1016.png',
        name: 'BSTC#1016',
        rank: 'Rank250',
    },
    {
        url: './2/118.png',
        name: 'BSTC#118',
        rank: 'Rank251',
    },
    {
        url: './2/1773.png',
        name: 'BSTC#1773',
        rank: 'Rank252',
    },
    {
        url: './2/2833.png',
        name: 'BSTC#2833',
        rank: 'Rank253',
    },
    {
        url: './2/1870.png',
        name: 'BSTC#1870',
        rank: 'Rank254',
    },
    {
        url: './2/1881.png',
        name: 'BSTC#1881',
        rank: 'Rank255',
    },
    {
        url: './2/1009.png',
        name: 'BSTC#1009',
        rank: 'Rank256',
    },
    {
        url: './2/1060.png',
        name: 'BSTC#1060',
        rank: 'Rank257',
    },
    {
        url: './2/2505.png',
        name: 'BSTC#2505',
        rank: 'Rank258',
    },
    {
        url: './2/2292.png',
        name: 'BSTC#2292',
        rank: 'Rank259',
    },
    {
        url: './2/1515.png',
        name: 'BSTC#1515',
        rank: 'Rank260',
    },
    {
        url: './2/2646.png',
        name: 'BSTC#2646',
        rank: 'Rank261',
    },
    {
        url: './2/248.png',
        name: 'BSTC#248',
        rank: 'Rank262',
    },
    {
        url: './2/506.png',
        name: 'BSTC#506',
        rank: 'Rank263',
    },
    {
        url: './2/412.png',
        name: 'BSTC#412',
        rank: 'Rank264',
    },
    {
        url: './2/1520.png',
        name: 'BSTC#1520',
        rank: 'Rank265',
    },
    {
        url: './2/366.png',
        name: 'BSTC#366',
        rank: 'Rank266',
    },
    {
        url: './2/1628.png',
        name: 'BSTC#1628',
        rank: 'Rank267',
    },
    {
        url: './2/2184.png',
        name: 'BSTC#2184',
        rank: 'Rank268',
    },
    {
        url: './2/2507.png',
        name: 'BSTC#2507',
        rank: 'Rank269',
    },
    {
        url: './2/1619.png',
        name: 'BSTC#1619',
        rank: 'Rank270',
    },
    {
        url: './2/1242.png',
        name: 'BSTC#1242',
        rank: 'Rank271',
    },
    {
        url: './2/826.png',
        name: 'BSTC#826',
        rank: 'Rank272',
    },
    {
        url: './2/3066.png',
        name: 'BSTC#3066',
        rank: 'Rank273',
    },
    {
        url: './2/2856.png',
        name: 'BSTC#2856',
        rank: 'Rank274',
    },
    {
        url: './2/1728.png',
        name: 'BSTC#1728',
        rank: 'Rank275',
    },
    {
        url: './2/33.png',
        name: 'BSTC#33',
        rank: 'Rank276',
    },
    {
        url: './2/98.png',
        name: 'BSTC#98',
        rank: 'Rank277',
    },
    {
        url: './2/1328.png',
        name: 'BSTC#1328',
        rank: 'Rank278',
    },
    {
        url: './2/981.png',
        name: 'BSTC#981',
        rank: 'Rank279',
    },
    {
        url: './2/1138.png',
        name: 'BSTC#1138',
        rank: 'Rank280',
    },
    {
        url: './2/1352.png',
        name: 'BSTC#1352',
        rank: 'Rank281',
    },
    {
        url: './2/1649.png',
        name: 'BSTC#1649',
        rank: 'Rank282',
    },
    {
        url: './2/2893.png',
        name: 'BSTC#2893',
        rank: 'Rank283',
    },
    {
        url: './2/1979.png',
        name: 'BSTC#1979',
        rank: 'Rank284',
    },
    {
        url: './2/863.png',
        name: 'BSTC#863',
        rank: 'Rank285',
    },
    {
        url: './2/1323.png',
        name: 'BSTC#1323',
        rank: 'Rank286',
    },
    {
        url: './2/625.png',
        name: 'BSTC#625',
        rank: 'Rank287',
    },
    {
        url: './2/1848.png',
        name: 'BSTC#1848',
        rank: 'Rank288',
    },
    {
        url: './2/1955.png',
        name: 'BSTC#1955',
        rank: 'Rank289',
    },
    {
        url: './2/1905.png',
        name: 'BSTC#1905',
        rank: 'Rank290',
    },
    {
        url: './2/1995.png',
        name: 'BSTC#1995',
        rank: 'Rank291',
    },
    {
        url: './2/1006.png',
        name: 'BSTC#1006',
        rank: 'Rank292',
    },
    {
        url: './2/1155.png',
        name: 'BSTC#1155',
        rank: 'Rank293',
    },
    {
        url: './2/1316.png',
        name: 'BSTC#1316',
        rank: 'Rank294',
    },
    {
        url: './2/1430.png',
        name: 'BSTC#1430',
        rank: 'Rank295',
    },
    {
        url: './2/2449.png',
        name: 'BSTC#2449',
        rank: 'Rank296',
    },
    {
        url: './2/2782.png',
        name: 'BSTC#2782',
        rank: 'Rank297',
    },
    {
        url: './2/1084.png',
        name: 'BSTC#1084',
        rank: 'Rank298',
    },
    {
        url: './2/2954.png',
        name: 'BSTC#2954',
        rank: 'Rank299',
    },
    {
        url: './2/2037.png',
        name: 'BSTC#2037',
        rank: 'Rank300',
    },
    {
        url: './2/43.png',
        name: 'BSTC#43',
        rank: 'Rank301',
    },
    {
        url: './2/1597.png',
        name: 'BSTC#1597',
        rank: 'Rank302',
    },
    {
        url: './2/2843.png',
        name: 'BSTC#2843',
        rank: 'Rank303',
    },
    {
        url: './2/575.png',
        name: 'BSTC#575',
        rank: 'Rank304',
    },
    {
        url: './2/2015.png',
        name: 'BSTC#2015',
        rank: 'Rank305',
    },
    {
        url: './2/163.png',
        name: 'BSTC#163',
        rank: 'Rank306',
    },
    {
        url: './2/1361.png',
        name: 'BSTC#1361',
        rank: 'Rank307',
    },
    {
        url: './2/680.png',
        name: 'BSTC#680',
        rank: 'Rank308',
    },
    {
        url: './2/1923.png',
        name: 'BSTC#1923',
        rank: 'Rank309',
    },
    {
        url: './2/1766.png',
        name: 'BSTC#1766',
        rank: 'Rank310',
    },
    {
        url: './2/245.png',
        name: 'BSTC#245',
        rank: 'Rank311',
    },
    {
        url: './2/1262.png',
        name: 'BSTC#1262',
        rank: 'Rank312',
    },
    {
        url: './2/2199.png',
        name: 'BSTC#2199',
        rank: 'Rank313',
    },
    {
        url: './2/2211.png',
        name: 'BSTC#2211',
        rank: 'Rank314',
    },
    {
        url: './2/598.png',
        name: 'BSTC#598',
        rank: 'Rank315',
    },
    {
        url: './2/1206.png',
        name: 'BSTC#1206',
        rank: 'Rank316',
    },
    {
        url: './2/1319.png',
        name: 'BSTC#1319',
        rank: 'Rank317',
    },
    {
        url: './2/2934.png',
        name: 'BSTC#2934',
        rank: 'Rank318',
    },
    {
        url: './2/2875.png',
        name: 'BSTC#2875',
        rank: 'Rank319',
    },
    {
        url: './2/600.png',
        name: 'BSTC#600',
        rank: 'Rank320',
    },
    {
        url: './2/1171.png',
        name: 'BSTC#1171',
        rank: 'Rank321',
    },
    {
        url: './2/24.png',
        name: 'BSTC#24',
        rank: 'Rank322',
    },
    {
        url: './2/162.png',
        name: 'BSTC#162',
        rank: 'Rank323',
    },
    {
        url: './2/430.png',
        name: 'BSTC#430',
        rank: 'Rank324',
    },
    {
        url: './2/811.png',
        name: 'BSTC#811',
        rank: 'Rank325',
    },
    {
        url: './2/1768.png',
        name: 'BSTC#1768',
        rank: 'Rank326',
    },
    {
        url: './2/1945.png',
        name: 'BSTC#1945',
        rank: 'Rank327',
    },
    {
        url: './2/2592.png',
        name: 'BSTC#2592',
        rank: 'Rank328',
    },
    {
        url: './2/2717.png',
        name: 'BSTC#2717',
        rank: 'Rank329',
    },
    {
        url: './2/1317.png',
        name: 'BSTC#1317',
        rank: 'Rank330',
    },
    {
        url: './2/1238.png',
        name: 'BSTC#1238',
        rank: 'Rank331',
    },
    {
        url: './2/1496.png',
        name: 'BSTC#1496',
        rank: 'Rank332',
    },
    {
        url: './2/81.png',
        name: 'BSTC#81',
        rank: 'Rank333',
    },
    {
        url: './2/758.png',
        name: 'BSTC#758',
        rank: 'Rank334',
    },
    {
        url: './2/2884.png',
        name: 'BSTC#2884',
        rank: 'Rank335',
    },
    {
        url: './2/3043.png',
        name: 'BSTC#3043',
        rank: 'Rank336',
    },
    {
        url: './2/2482.png',
        name: 'BSTC#2482',
        rank: 'Rank337',
    },
    {
        url: './2/379.png',
        name: 'BSTC#379',
        rank: 'Rank338',
    },
    {
        url: './2/2510.png',
        name: 'BSTC#2510',
        rank: 'Rank339',
    },
    {
        url: './2/221.png',
        name: 'BSTC#221',
        rank: 'Rank340',
    },
    {
        url: './2/1265.png',
        name: 'BSTC#1265',
        rank: 'Rank341',
    },
    {
        url: './2/1408.png',
        name: 'BSTC#1408',
        rank: 'Rank342',
    },
    {
        url: './2/2581.png',
        name: 'BSTC#2581',
        rank: 'Rank343',
    },
    {
        url: './2/2756.png',
        name: 'BSTC#2756',
        rank: 'Rank344',
    },
    {
        url: './2/2429.png',
        name: 'BSTC#2429',
        rank: 'Rank345',
    },
    {
        url: './2/10.png',
        name: 'BSTC#10',
        rank: 'Rank346',
    },
    {
        url: './2/233.png',
        name: 'BSTC#233',
        rank: 'Rank347',
    },
    {
        url: './2/403.png',
        name: 'BSTC#403',
        rank: 'Rank348',
    },
    {
        url: './2/2631.png',
        name: 'BSTC#2631',
        rank: 'Rank349',
    },
    {
        url: './2/2889.png',
        name: 'BSTC#2889',
        rank: 'Rank350',
    },
    {
        url: './2/408.png',
        name: 'BSTC#408',
        rank: 'Rank351',
    },
    {
        url: './2/2336.png',
        name: 'BSTC#2336',
        rank: 'Rank352',
    },
    {
        url: './2/1307.png',
        name: 'BSTC#1307',
        rank: 'Rank353',
    },
    {
        url: './2/832.png',
        name: 'BSTC#832',
        rank: 'Rank354',
    },
    {
        url: './2/1591.png',
        name: 'BSTC#1591',
        rank: 'Rank355',
    },
    {
        url: './2/2598.png',
        name: 'BSTC#2598',
        rank: 'Rank356',
    },
    {
        url: './2/1134.png',
        name: 'BSTC#1134',
        rank: 'Rank357',
    },
    {
        url: './2/2.png',
        name: 'BSTC#2',
        rank: 'Rank358',
    },
    {
        url: './2/511.png',
        name: 'BSTC#511',
        rank: 'Rank359',
    },
    {
        url: './2/2515.png',
        name: 'BSTC#2515',
        rank: 'Rank360',
    },
    {
        url: './2/1671.png',
        name: 'BSTC#1671',
        rank: 'Rank361',
    },
    {
        url: './2/623.png',
        name: 'BSTC#623',
        rank: 'Rank362',
    },
    {
        url: './2/2000.png',
        name: 'BSTC#2000',
        rank: 'Rank363',
    },
    {
        url: './2/2061.png',
        name: 'BSTC#2061',
        rank: 'Rank364',
    },
    {
        url: './2/2167.png',
        name: 'BSTC#2167',
        rank: 'Rank365',
    },
    {
        url: './2/9.png',
        name: 'BSTC#9',
        rank: 'Rank366',
    },
    {
        url: './2/764.png',
        name: 'BSTC#764',
        rank: 'Rank367',
    },
    {
        url: './2/470.png',
        name: 'BSTC#470',
        rank: 'Rank368',
    },
    {
        url: './2/2908.png',
        name: 'BSTC#2908',
        rank: 'Rank369',
    },
    {
        url: './2/3123.png',
        name: 'BSTC#3123',
        rank: 'Rank370',
    },
    {
        url: './2/433.png',
        name: 'BSTC#433',
        rank: 'Rank371',
    },
    {
        url: './2/1962.png',
        name: 'BSTC#1962',
        rank: 'Rank372',
    },
    {
        url: './2/2653.png',
        name: 'BSTC#2653',
        rank: 'Rank373',
    },
    {
        url: './2/721.png',
        name: 'BSTC#721',
        rank: 'Rank374',
    },
    {
        url: './2/1033.png',
        name: 'BSTC#1033',
        rank: 'Rank375',
    },
    {
        url: './2/651.png',
        name: 'BSTC#651',
        rank: 'Rank376',
    },
    {
        url: './2/2248.png',
        name: 'BSTC#2248',
        rank: 'Rank377',
    },
    {
        url: './2/820.png',
        name: 'BSTC#820',
        rank: 'Rank378',
    },
    {
        url: './2/2830.png',
        name: 'BSTC#2830',
        rank: 'Rank379',
    },
    {
        url: './2/2344.png',
        name: 'BSTC#2344',
        rank: 'Rank380',
    },
    {
        url: './2/2460.png',
        name: 'BSTC#2460',
        rank: 'Rank381',
    },
    {
        url: './2/2580.png',
        name: 'BSTC#2580',
        rank: 'Rank382',
    },
    {
        url: './2/2160.png',
        name: 'BSTC#2160',
        rank: 'Rank383',
    },
    {
        url: './2/1185.png',
        name: 'BSTC#1185',
        rank: 'Rank384',
    },
    {
        url: './2/2802.png',
        name: 'BSTC#2802',
        rank: 'Rank385',
    },
    {
        url: './2/1942.png',
        name: 'BSTC#1942',
        rank: 'Rank386',
    },
    {
        url: './2/2428.png',
        name: 'BSTC#2428',
        rank: 'Rank387',
    },
    {
        url: './2/803.png',
        name: 'BSTC#803',
        rank: 'Rank388',
    },
    {
        url: './2/1052.png',
        name: 'BSTC#1052',
        rank: 'Rank389',
    },
    {
        url: './2/54.png',
        name: 'BSTC#54',
        rank: 'Rank390',
    },
    {
        url: './2/609.png',
        name: 'BSTC#609',
        rank: 'Rank391',
    },
    {
        url: './2/899.png',
        name: 'BSTC#899',
        rank: 'Rank392',
    },
    {
        url: './2/2192.png',
        name: 'BSTC#2192',
        rank: 'Rank393',
    },
    {
        url: './2/2612.png',
        name: 'BSTC#2612',
        rank: 'Rank394',
    },
    {
        url: './2/3093.png',
        name: 'BSTC#3093',
        rank: 'Rank395',
    },
    {
        url: './2/3102.png',
        name: 'BSTC#3102',
        rank: 'Rank396',
    },
    {
        url: './2/1985.png',
        name: 'BSTC#1985',
        rank: 'Rank397',
    },
    {
        url: './2/939.png',
        name: 'BSTC#939',
        rank: 'Rank398',
    },
    {
        url: './2/1988.png',
        name: 'BSTC#1988',
        rank: 'Rank399',
    },
    {
        url: './2/2723.png',
        name: 'BSTC#2723',
        rank: 'Rank400',
    },
    {
        url: './2/2932.png',
        name: 'BSTC#2932',
        rank: 'Rank401',
    },
    {
        url: './2/1991.png',
        name: 'BSTC#1991',
        rank: 'Rank402',
    },
    {
        url: './2/2343.png',
        name: 'BSTC#2343',
        rank: 'Rank403',
    },
    {
        url: './2/2422.png',
        name: 'BSTC#2422',
        rank: 'Rank404',
    },
    {
        url: './2/241.png',
        name: 'BSTC#241',
        rank: 'Rank405',
    },
    {
        url: './2/596.png',
        name: 'BSTC#596',
        rank: 'Rank406',
    },
    {
        url: './2/1442.png',
        name: 'BSTC#1442',
        rank: 'Rank407',
    },
    {
        url: './2/50.png',
        name: 'BSTC#50',
        rank: 'Rank408',
    },
    {
        url: './2/1097.png',
        name: 'BSTC#1097',
        rank: 'Rank409',
    },
    {
        url: './2/1725.png',
        name: 'BSTC#1725',
        rank: 'Rank410',
    },
    {
        url: './2/537.png',
        name: 'BSTC#537',
        rank: 'Rank411',
    },
    {
        url: './2/1188.png',
        name: 'BSTC#1188',
        rank: 'Rank412',
    },
    {
        url: './2/2430.png',
        name: 'BSTC#2430',
        rank: 'Rank413',
    },
    {
        url: './2/2836.png',
        name: 'BSTC#2836',
        rank: 'Rank414',
    },
    {
        url: './2/1525.png',
        name: 'BSTC#1525',
        rank: 'Rank415',
    },
    {
        url: './2/495.png',
        name: 'BSTC#495',
        rank: 'Rank416',
    },
    {
        url: './2/1644.png',
        name: 'BSTC#1644',
        rank: 'Rank417',
    },
    {
        url: './2/2239.png',
        name: 'BSTC#2239',
        rank: 'Rank418',
    },
    {
        url: './2/854.png',
        name: 'BSTC#854',
        rank: 'Rank419',
    },
    {
        url: './2/782.png',
        name: 'BSTC#782',
        rank: 'Rank420',
    },
    {
        url: './2/2607.png',
        name: 'BSTC#2607',
        rank: 'Rank421',
    },
    {
        url: './2/1617.png',
        name: 'BSTC#1617',
        rank: 'Rank422',
    },
    {
        url: './2/348.png',
        name: 'BSTC#348',
        rank: 'Rank423',
    },
    {
        url: './2/3096.png',
        name: 'BSTC#3096',
        rank: 'Rank424',
    },
    {
        url: './2/515.png',
        name: 'BSTC#515',
        rank: 'Rank425',
    },
    {
        url: './2/2816.png',
        name: 'BSTC#2816',
        rank: 'Rank426',
    },
    {
        url: './2/325.png',
        name: 'BSTC#325',
        rank: 'Rank427',
    },
    {
        url: './2/2842.png',
        name: 'BSTC#2842',
        rank: 'Rank428',
    },
    {
        url: './2/2787.png',
        name: 'BSTC#2787',
        rank: 'Rank429',
    },
    {
        url: './2/2486.png',
        name: 'BSTC#2486',
        rank: 'Rank430',
    },
    {
        url: './2/2919.png',
        name: 'BSTC#2919',
        rank: 'Rank431',
    },
    {
        url: './2/1363.png',
        name: 'BSTC#1363',
        rank: 'Rank432',
    },
    {
        url: './2/1541.png',
        name: 'BSTC#1541',
        rank: 'Rank433',
    },
    {
        url: './2/2352.png',
        name: 'BSTC#2352',
        rank: 'Rank434',
    },
    {
        url: './2/1825.png',
        name: 'BSTC#1825',
        rank: 'Rank435',
    },
    {
        url: './2/2366.png',
        name: 'BSTC#2366',
        rank: 'Rank436',
    },
    {
        url: './2/1466.png',
        name: 'BSTC#1466',
        rank: 'Rank437',
    },
    {
        url: './2/1548.png',
        name: 'BSTC#1548',
        rank: 'Rank438',
    },
    {
        url: './2/468.png',
        name: 'BSTC#468',
        rank: 'Rank439',
    },
    {
        url: './2/1927.png',
        name: 'BSTC#1927',
        rank: 'Rank440',
    },
    {
        url: './2/2403.png',
        name: 'BSTC#2403',
        rank: 'Rank441',
    },
    {
        url: './2/2553.png',
        name: 'BSTC#2553',
        rank: 'Rank442',
    },
    {
        url: './2/1385.png',
        name: 'BSTC#1385',
        rank: 'Rank443',
    },
    {
        url: './2/13.png',
        name: 'BSTC#13',
        rank: 'Rank444',
    },
    {
        url: './2/862.png',
        name: 'BSTC#862',
        rank: 'Rank445',
    },
    {
        url: './2/1259.png',
        name: 'BSTC#1259',
        rank: 'Rank446',
    },
    {
        url: './2/371.png',
        name: 'BSTC#371',
        rank: 'Rank447',
    },
    {
        url: './2/588.png',
        name: 'BSTC#588',
        rank: 'Rank448',
    },
    {
        url: './2/2764.png',
        name: 'BSTC#2764',
        rank: 'Rank449',
    },
    {
        url: './2/222.png',
        name: 'BSTC#222',
        rank: 'Rank450',
    },
    {
        url: './2/480.png',
        name: 'BSTC#480',
        rank: 'Rank451',
    },
    {
        url: './2/1381.png',
        name: 'BSTC#1381',
        rank: 'Rank452',
    },
    {
        url: './2/1777.png',
        name: 'BSTC#1777',
        rank: 'Rank453',
    },
    {
        url: './2/3080.png',
        name: 'BSTC#3080',
        rank: 'Rank454',
    },
    {
        url: './2/239.png',
        name: 'BSTC#239',
        rank: 'Rank455',
    },
    {
        url: './2/1731.png',
        name: 'BSTC#1731',
        rank: 'Rank456',
    },
    {
        url: './2/2969.png',
        name: 'BSTC#2969',
        rank: 'Rank457',
    },
    {
        url: './2/2243.png',
        name: 'BSTC#2243',
        rank: 'Rank458',
    },
    {
        url: './2/49.png',
        name: 'BSTC#49',
        rank: 'Rank459',
    },
    {
        url: './2/314.png',
        name: 'BSTC#314',
        rank: 'Rank460',
    },
    {
        url: './2/2409.png',
        name: 'BSTC#2409',
        rank: 'Rank461',
    },
    {
        url: './2/1839.png',
        name: 'BSTC#1839',
        rank: 'Rank462',
    },
    {
        url: './2/2034.png',
        name: 'BSTC#2034',
        rank: 'Rank463',
    },
    {
        url: './2/2939.png',
        name: 'BSTC#2939',
        rank: 'Rank464',
    },
    {
        url: './2/2016.png',
        name: 'BSTC#2016',
        rank: 'Rank465',
    },
    {
        url: './2/2318.png',
        name: 'BSTC#2318',
        rank: 'Rank466',
    },
    {
        url: './2/487.png',
        name: 'BSTC#487',
        rank: 'Rank467',
    },
    {
        url: './2/1297.png',
        name: 'BSTC#1297',
        rank: 'Rank468',
    },
    {
        url: './2/1705.png',
        name: 'BSTC#1705',
        rank: 'Rank469',
    },
    {
        url: './2/1713.png',
        name: 'BSTC#1713',
        rank: 'Rank470',
    },
    {
        url: './2/2256.png',
        name: 'BSTC#2256',
        rank: 'Rank471',
    },
    {
        url: './2/993.png',
        name: 'BSTC#993',
        rank: 'Rank472',
    },
    {
        url: './2/1806.png',
        name: 'BSTC#1806',
        rank: 'Rank473',
    },
    {
        url: './2/3025.png',
        name: 'BSTC#3025',
        rank: 'Rank474',
    },
    {
        url: './2/677.png',
        name: 'BSTC#677',
        rank: 'Rank475',
    },
    {
        url: './2/180.png',
        name: 'BSTC#180',
        rank: 'Rank476',
    },
    {
        url: './2/2106.png',
        name: 'BSTC#2106',
        rank: 'Rank477',
    },
    {
        url: './2/2477.png',
        name: 'BSTC#2477',
        rank: 'Rank478',
    },
    {
        url: './2/280.png',
        name: 'BSTC#280',
        rank: 'Rank479',
    },
    {
        url: './2/1846.png',
        name: 'BSTC#1846',
        rank: 'Rank480',
    },
    {
        url: './2/2213.png',
        name: 'BSTC#2213',
        rank: 'Rank481',
    },
    {
        url: './2/2339.png',
        name: 'BSTC#2339',
        rank: 'Rank482',
    },
    {
        url: './2/2758.png',
        name: 'BSTC#2758',
        rank: 'Rank483',
    },
    {
        url: './2/1819.png',
        name: 'BSTC#1819',
        rank: 'Rank484',
    },
    {
        url: './2/1092.png',
        name: 'BSTC#1092',
        rank: 'Rank485',
    },
    {
        url: './2/157.png',
        name: 'BSTC#157',
        rank: 'Rank486',
    },
    {
        url: './2/179.png',
        name: 'BSTC#179',
        rank: 'Rank487',
    },
    {
        url: './2/1306.png',
        name: 'BSTC#1306',
        rank: 'Rank488',
    },
    {
        url: './2/1216.png',
        name: 'BSTC#1216',
        rank: 'Rank489',
    },
    {
        url: './2/593.png',
        name: 'BSTC#593',
        rank: 'Rank490',
    },
    {
        url: './2/865.png',
        name: 'BSTC#865',
        rank: 'Rank491',
    },
    {
        url: './2/960.png',
        name: 'BSTC#960',
        rank: 'Rank492',
    },
    {
        url: './2/1142.png',
        name: 'BSTC#1142',
        rank: 'Rank493',
    },
    {
        url: './2/2250.png',
        name: 'BSTC#2250',
        rank: 'Rank494',
    },
    {
        url: './2/3125.png',
        name: 'BSTC#3125',
        rank: 'Rank495',
    },
    {
        url: './2/1304.png',
        name: 'BSTC#1304',
        rank: 'Rank496',
    },
    {
        url: './2/398.png',
        name: 'BSTC#398',
        rank: 'Rank497',
    },
    {
        url: './2/610.png',
        name: 'BSTC#610',
        rank: 'Rank498',
    },
    {
        url: './2/704.png',
        name: 'BSTC#704',
        rank: 'Rank499',
    },
    {
        url: './2/774.png',
        name: 'BSTC#774',
        rank: 'Rank500',
    },
    {
        url: './2/828.png',
        name: 'BSTC#828',
        rank: 'Rank501',
    },
    {
        url: './2/888.png',
        name: 'BSTC#888',
        rank: 'Rank502',
    },
    {
        url: './2/1476.png',
        name: 'BSTC#1476',
        rank: 'Rank503',
    },
    {
        url: './2/2152.png',
        name: 'BSTC#2152',
        rank: 'Rank504',
    },
    {
        url: './2/2995.png',
        name: 'BSTC#2995',
        rank: 'Rank505',
    },
    {
        url: './2/200.png',
        name: 'BSTC#200',
        rank: 'Rank506',
    },
    {
        url: './2/465.png',
        name: 'BSTC#465',
        rank: 'Rank507',
    },
    {
        url: './2/953.png',
        name: 'BSTC#953',
        rank: 'Rank508',
    },
    {
        url: './2/2734.png',
        name: 'BSTC#2734',
        rank: 'Rank509',
    },
    {
        url: './2/2360.png',
        name: 'BSTC#2360',
        rank: 'Rank510',
    },
    {
        url: './2/2297.png',
        name: 'BSTC#2297',
        rank: 'Rank511',
    },
    {
        url: './2/1399.png',
        name: 'BSTC#1399',
        rank: 'Rank512',
    },
    {
        url: './2/2030.png',
        name: 'BSTC#2030',
        rank: 'Rank513',
    },
    {
        url: './2/2516.png',
        name: 'BSTC#2516',
        rank: 'Rank514',
    },
    {
        url: './2/358.png',
        name: 'BSTC#358',
        rank: 'Rank515',
    },
    {
        url: './2/1733.png',
        name: 'BSTC#1733',
        rank: 'Rank516',
    },
    {
        url: './2/1293.png',
        name: 'BSTC#1293',
        rank: 'Rank517',
    },
    {
        url: './2/1370.png',
        name: 'BSTC#1370',
        rank: 'Rank518',
    },
    {
        url: './2/102.png',
        name: 'BSTC#102',
        rank: 'Rank519',
    },
    {
        url: './2/563.png',
        name: 'BSTC#563',
        rank: 'Rank520',
    },
    {
        url: './2/2161.png',
        name: 'BSTC#2161',
        rank: 'Rank521',
    },
    {
        url: './2/829.png',
        name: 'BSTC#829',
        rank: 'Rank522',
    },
    {
        url: './2/2898.png',
        name: 'BSTC#2898',
        rank: 'Rank523',
    },
    {
        url: './2/138.png',
        name: 'BSTC#138',
        rank: 'Rank524',
    },
    {
        url: './2/2686.png',
        name: 'BSTC#2686',
        rank: 'Rank525',
    },
    {
        url: './2/52.png',
        name: 'BSTC#52',
        rank: 'Rank526',
    },
    {
        url: './2/77.png',
        name: 'BSTC#77',
        rank: 'Rank527',
    },
    {
        url: './2/1516.png',
        name: 'BSTC#1516',
        rank: 'Rank528',
    },
    {
        url: './2/2177.png',
        name: 'BSTC#2177',
        rank: 'Rank529',
    },
    {
        url: './2/2693.png',
        name: 'BSTC#2693',
        rank: 'Rank530',
    },
    {
        url: './2/1802.png',
        name: 'BSTC#1802',
        rank: 'Rank531',
    },
    {
        url: './2/729.png',
        name: 'BSTC#729',
        rank: 'Rank532',
    },
    {
        url: './2/823.png',
        name: 'BSTC#823',
        rank: 'Rank533',
    },
    {
        url: './2/990.png',
        name: 'BSTC#990',
        rank: 'Rank534',
    },
    {
        url: './2/1187.png',
        name: 'BSTC#1187',
        rank: 'Rank535',
    },
    {
        url: './2/1774.png',
        name: 'BSTC#1774',
        rank: 'Rank536',
    },
    {
        url: './2/2754.png',
        name: 'BSTC#2754',
        rank: 'Rank537',
    },
    {
        url: './2/125.png',
        name: 'BSTC#125',
        rank: 'Rank538',
    },
    {
        url: './2/868.png',
        name: 'BSTC#868',
        rank: 'Rank539',
    },
    {
        url: './2/2575.png',
        name: 'BSTC#2575',
        rank: 'Rank540',
    },
    {
        url: './2/1180.png',
        name: 'BSTC#1180',
        rank: 'Rank541',
    },
    {
        url: './2/2865.png',
        name: 'BSTC#2865',
        rank: 'Rank542',
    },
    {
        url: './2/757.png',
        name: 'BSTC#757',
        rank: 'Rank543',
    },
    {
        url: './2/2994.png',
        name: 'BSTC#2994',
        rank: 'Rank544',
    },
    {
        url: './2/1587.png',
        name: 'BSTC#1587',
        rank: 'Rank545',
    },
    {
        url: './2/84.png',
        name: 'BSTC#84',
        rank: 'Rank546',
    },
    {
        url: './2/825.png',
        name: 'BSTC#825',
        rank: 'Rank547',
    },
    {
        url: './2/2153.png',
        name: 'BSTC#2153',
        rank: 'Rank548',
    },
    {
        url: './2/3020.png',
        name: 'BSTC#3020',
        rank: 'Rank549',
    },
    {
        url: './2/571.png',
        name: 'BSTC#571',
        rank: 'Rank550',
    },
    {
        url: './2/3002.png',
        name: 'BSTC#3002',
        rank: 'Rank551',
    },
    {
        url: './2/657.png',
        name: 'BSTC#657',
        rank: 'Rank552',
    },
    {
        url: './2/1405.png',
        name: 'BSTC#1405',
        rank: 'Rank553',
    },
    {
        url: './2/586.png',
        name: 'BSTC#586',
        rank: 'Rank554',
    },
    {
        url: './2/658.png',
        name: 'BSTC#658',
        rank: 'Rank555',
    },
    {
        url: './2/991.png',
        name: 'BSTC#991',
        rank: 'Rank556',
    },
    {
        url: './2/2348.png',
        name: 'BSTC#2348',
        rank: 'Rank557',
    },
    {
        url: './2/933.png',
        name: 'BSTC#933',
        rank: 'Rank558',
    },
    {
        url: './2/1374.png',
        name: 'BSTC#1374',
        rank: 'Rank559',
    },
    {
        url: './2/1101.png',
        name: 'BSTC#1101',
        rank: 'Rank560',
    },
    {
        url: './2/1722.png',
        name: 'BSTC#1722',
        rank: 'Rank561',
    },
    {
        url: './2/2674.png',
        name: 'BSTC#2674',
        rank: 'Rank562',
    },
    {
        url: './2/327.png',
        name: 'BSTC#327',
        rank: 'Rank563',
    },
    {
        url: './2/457.png',
        name: 'BSTC#457',
        rank: 'Rank564',
    },
    {
        url: './2/2544.png',
        name: 'BSTC#2544',
        rank: 'Rank565',
    },
    {
        url: './2/3032.png',
        name: 'BSTC#3032',
        rank: 'Rank566',
    },
    {
        url: './2/889.png',
        name: 'BSTC#889',
        rank: 'Rank567',
    },
    {
        url: './2/488.png',
        name: 'BSTC#488',
        rank: 'Rank568',
    },
    {
        url: './2/503.png',
        name: 'BSTC#503',
        rank: 'Rank569',
    },
    {
        url: './2/926.png',
        name: 'BSTC#926',
        rank: 'Rank570',
    },
    {
        url: './2/1217.png',
        name: 'BSTC#1217',
        rank: 'Rank571',
    },
    {
        url: './2/2362.png',
        name: 'BSTC#2362',
        rank: 'Rank572',
    },
    {
        url: './2/2551.png',
        name: 'BSTC#2551',
        rank: 'Rank573',
    },
    {
        url: './2/2136.png',
        name: 'BSTC#2136',
        rank: 'Rank574',
    },
    {
        url: './2/1166.png',
        name: 'BSTC#1166',
        rank: 'Rank575',
    },
    {
        url: './2/2704.png',
        name: 'BSTC#2704',
        rank: 'Rank576',
    },
    {
        url: './2/1181.png',
        name: 'BSTC#1181',
        rank: 'Rank577',
    },
    {
        url: './2/2587.png',
        name: 'BSTC#2587',
        rank: 'Rank578',
    },
    {
        url: './2/1735.png',
        name: 'BSTC#1735',
        rank: 'Rank579',
    },
    {
        url: './2/1664.png',
        name: 'BSTC#1664',
        rank: 'Rank580',
    },
    {
        url: './2/2371.png',
        name: 'BSTC#2371',
        rank: 'Rank581',
    },
    {
        url: './2/1404.png',
        name: 'BSTC#1404',
        rank: 'Rank582',
    },
    {
        url: './2/2316.png',
        name: 'BSTC#2316',
        rank: 'Rank583',
    },
    {
        url: './2/1465.png',
        name: 'BSTC#1465',
        rank: 'Rank584',
    },
    {
        url: './2/316.png',
        name: 'BSTC#316',
        rank: 'Rank585',
    },
    {
        url: './2/3086.png',
        name: 'BSTC#3086',
        rank: 'Rank586',
    },
    {
        url: './2/4.png',
        name: 'BSTC#4',
        rank: 'Rank587',
    },
    {
        url: './2/228.png',
        name: 'BSTC#228',
        rank: 'Rank588',
    },
    {
        url: './2/306.png',
        name: 'BSTC#306',
        rank: 'Rank589',
    },
    {
        url: './2/785.png',
        name: 'BSTC#785',
        rank: 'Rank590',
    },
    {
        url: './2/2668.png',
        name: 'BSTC#2668',
        rank: 'Rank591',
    },
    {
        url: './2/3097.png',
        name: 'BSTC#3097',
        rank: 'Rank592',
    },
    {
        url: './2/2763.png',
        name: 'BSTC#2763',
        rank: 'Rank593',
    },
    {
        url: './2/1455.png',
        name: 'BSTC#1455',
        rank: 'Rank594',
    },
    {
        url: './2/1110.png',
        name: 'BSTC#1110',
        rank: 'Rank595',
    },
    {
        url: './2/1746.png',
        name: 'BSTC#1746',
        rank: 'Rank596',
    },
    {
        url: './2/2864.png',
        name: 'BSTC#2864',
        rank: 'Rank597',
    },
    {
        url: './2/107.png',
        name: 'BSTC#107',
        rank: 'Rank598',
    },
    {
        url: './2/978.png',
        name: 'BSTC#978',
        rank: 'Rank599',
    },
    {
        url: './2/2513.png',
        name: 'BSTC#2513',
        rank: 'Rank600',
    },
    {
        url: './2/1348.png',
        name: 'BSTC#1348',
        rank: 'Rank601',
    },
    {
        url: './2/809.png',
        name: 'BSTC#809',
        rank: 'Rank602',
    },
    {
        url: './2/1820.png',
        name: 'BSTC#1820',
        rank: 'Rank603',
    },
    {
        url: './2/2164.png',
        name: 'BSTC#2164',
        rank: 'Rank604',
    },
    {
        url: './2/149.png',
        name: 'BSTC#149',
        rank: 'Rank605',
    },
    {
        url: './2/2383.png',
        name: 'BSTC#2383',
        rank: 'Rank606',
    },
    {
        url: './2/712.png',
        name: 'BSTC#712',
        rank: 'Rank607',
    },
    {
        url: './2/1556.png',
        name: 'BSTC#1556',
        rank: 'Rank608',
    },
    {
        url: './2/1702.png',
        name: 'BSTC#1702',
        rank: 'Rank609',
    },
    {
        url: './2/608.png',
        name: 'BSTC#608',
        rank: 'Rank610',
    },
    {
        url: './2/2550.png',
        name: 'BSTC#2550',
        rank: 'Rank611',
    },
    {
        url: './2/2841.png',
        name: 'BSTC#2841',
        rank: 'Rank612',
    },
    {
        url: './2/2276.png',
        name: 'BSTC#2276',
        rank: 'Rank613',
    },
    {
        url: './2/1849.png',
        name: 'BSTC#1849',
        rank: 'Rank614',
    },
    {
        url: './2/1236.png',
        name: 'BSTC#1236',
        rank: 'Rank615',
    },
    {
        url: './2/2504.png',
        name: 'BSTC#2504',
        rank: 'Rank616',
    },
    {
        url: './2/1585.png',
        name: 'BSTC#1585',
        rank: 'Rank617',
    },
    {
        url: './2/2805.png',
        name: 'BSTC#2805',
        rank: 'Rank618',
    },
    {
        url: './2/3069.png',
        name: 'BSTC#3069',
        rank: 'Rank619',
    },
    {
        url: './2/1012.png',
        name: 'BSTC#1012',
        rank: 'Rank620',
    },
    {
        url: './2/1665.png',
        name: 'BSTC#1665',
        rank: 'Rank621',
    },
    {
        url: './2/2264.png',
        name: 'BSTC#2264',
        rank: 'Rank622',
    },
    {
        url: './2/2454.png',
        name: 'BSTC#2454',
        rank: 'Rank623',
    },
    {
        url: './2/2938.png',
        name: 'BSTC#2938',
        rank: 'Rank624',
    },
    {
        url: './2/853.png',
        name: 'BSTC#853',
        rank: 'Rank625',
    },
    {
        url: './2/1103.png',
        name: 'BSTC#1103',
        rank: 'Rank626',
    },
    {
        url: './2/1921.png',
        name: 'BSTC#1921',
        rank: 'Rank627',
    },
    {
        url: './2/896.png',
        name: 'BSTC#896',
        rank: 'Rank628',
    },
    {
        url: './2/7.png',
        name: 'BSTC#7',
        rank: 'Rank629',
    },
    {
        url: './2/491.png',
        name: 'BSTC#491',
        rank: 'Rank630',
    },
    {
        url: './2/1910.png',
        name: 'BSTC#1910',
        rank: 'Rank631',
    },
    {
        url: './2/1301.png',
        name: 'BSTC#1301',
        rank: 'Rank632',
    },
    {
        url: './2/1150.png',
        name: 'BSTC#1150',
        rank: 'Rank633',
    },
    {
        url: './2/1714.png',
        name: 'BSTC#1714',
        rank: 'Rank634',
    },
    {
        url: './2/1036.png',
        name: 'BSTC#1036',
        rank: 'Rank635',
    },
    {
        url: './2/995.png',
        name: 'BSTC#995',
        rank: 'Rank636',
    },
    {
        url: './2/1741.png',
        name: 'BSTC#1741',
        rank: 'Rank637',
    },
    {
        url: './2/2749.png',
        name: 'BSTC#2749',
        rank: 'Rank638',
    },
    {
        url: './2/908.png',
        name: 'BSTC#908',
        rank: 'Rank639',
    },
    {
        url: './2/2497.png',
        name: 'BSTC#2497',
        rank: 'Rank640',
    },
    {
        url: './2/3085.png',
        name: 'BSTC#3085',
        rank: 'Rank641',
    },
    {
        url: './2/64.png',
        name: 'BSTC#64',
        rank: 'Rank642',
    },
    {
        url: './2/1377.png',
        name: 'BSTC#1377',
        rank: 'Rank643',
    },
    {
        url: './2/2141.png',
        name: 'BSTC#2141',
        rank: 'Rank644',
    },
    {
        url: './2/711.png',
        name: 'BSTC#711',
        rank: 'Rank645',
    },
    {
        url: './2/1173.png',
        name: 'BSTC#1173',
        rank: 'Rank646',
    },
    {
        url: './2/1478.png',
        name: 'BSTC#1478',
        rank: 'Rank647',
    },
    {
        url: './2/1550.png',
        name: 'BSTC#1550',
        rank: 'Rank648',
    },
    {
        url: './2/3124.png',
        name: 'BSTC#3124',
        rank: 'Rank649',
    },
    {
        url: './2/3139.png',
        name: 'BSTC#3139',
        rank: 'Rank650',
    },
    {
        url: './2/1075.png',
        name: 'BSTC#1075',
        rank: 'Rank651',
    },
    {
        url: './2/968.png',
        name: 'BSTC#968',
        rank: 'Rank652',
    },
    {
        url: './2/1019.png',
        name: 'BSTC#1019',
        rank: 'Rank653',
    },
    {
        url: './2/2937.png',
        name: 'BSTC#2937',
        rank: 'Rank654',
    },
    {
        url: './2/207.png',
        name: 'BSTC#207',
        rank: 'Rank655',
    },
    {
        url: './2/1748.png',
        name: 'BSTC#1748',
        rank: 'Rank656',
    },
    {
        url: './2/1887.png',
        name: 'BSTC#1887',
        rank: 'Rank657',
    },
    {
        url: './2/759.png',
        name: 'BSTC#759',
        rank: 'Rank658',
    },
    {
        url: './2/2475.png',
        name: 'BSTC#2475',
        rank: 'Rank659',
    },
    {
        url: './2/3062.png',
        name: 'BSTC#3062',
        rank: 'Rank660',
    },
    {
        url: './2/556.png',
        name: 'BSTC#556',
        rank: 'Rank661',
    },
    {
        url: './2/1914.png',
        name: 'BSTC#1914',
        rank: 'Rank662',
    },
    {
        url: './2/2215.png',
        name: 'BSTC#2215',
        rank: 'Rank663',
    },
    {
        url: './2/2835.png',
        name: 'BSTC#2835',
        rank: 'Rank664',
    },
    {
        url: './2/2848.png',
        name: 'BSTC#2848',
        rank: 'Rank665',
    },
    {
        url: './2/2400.png',
        name: 'BSTC#2400',
        rank: 'Rank666',
    },
    {
        url: './2/2845.png',
        name: 'BSTC#2845',
        rank: 'Rank667',
    },
    {
        url: './2/364.png',
        name: 'BSTC#364',
        rank: 'Rank668',
    },
    {
        url: './2/454.png',
        name: 'BSTC#454',
        rank: 'Rank669',
    },
    {
        url: './2/622.png',
        name: 'BSTC#622',
        rank: 'Rank670',
    },
    {
        url: './2/2883.png',
        name: 'BSTC#2883',
        rank: 'Rank671',
    },
    {
        url: './2/1694.png',
        name: 'BSTC#1694',
        rank: 'Rank672',
    },
    {
        url: './2/172.png',
        name: 'BSTC#172',
        rank: 'Rank673',
    },
    {
        url: './2/3106.png',
        name: 'BSTC#3106',
        rank: 'Rank674',
    },
    {
        url: './2/2097.png',
        name: 'BSTC#2097',
        rank: 'Rank675',
    },
    {
        url: './2/1395.png',
        name: 'BSTC#1395',
        rank: 'Rank676',
    },
    {
        url: './2/482.png',
        name: 'BSTC#482',
        rank: 'Rank677',
    },
    {
        url: './2/2427.png',
        name: 'BSTC#2427',
        rank: 'Rank678',
    },
    {
        url: './2/1105.png',
        name: 'BSTC#1105',
        rank: 'Rank679',
    },
    {
        url: './2/2244.png',
        name: 'BSTC#2244',
        rank: 'Rank680',
    },
    {
        url: './2/1357.png',
        name: 'BSTC#1357',
        rank: 'Rank681',
    },
    {
        url: './2/2385.png',
        name: 'BSTC#2385',
        rank: 'Rank682',
    },
    {
        url: './2/3127.png',
        name: 'BSTC#3127',
        rank: 'Rank683',
    },
    {
        url: './2/304.png',
        name: 'BSTC#304',
        rank: 'Rank684',
    },
    {
        url: './2/2232.png',
        name: 'BSTC#2232',
        rank: 'Rank685',
    },
    {
        url: './2/2546.png',
        name: 'BSTC#2546',
        rank: 'Rank686',
    },
    {
        url: './2/1679.png',
        name: 'BSTC#1679',
        rank: 'Rank687',
    },
    {
        url: './2/716.png',
        name: 'BSTC#716',
        rank: 'Rank688',
    },
    {
        url: './2/998.png',
        name: 'BSTC#998',
        rank: 'Rank689',
    },
    {
        url: './2/2345.png',
        name: 'BSTC#2345',
        rank: 'Rank690',
    },
    {
        url: './2/2540.png',
        name: 'BSTC#2540',
        rank: 'Rank691',
    },
    {
        url: './2/3037.png',
        name: 'BSTC#3037',
        rank: 'Rank692',
    },
    {
        url: './2/319.png',
        name: 'BSTC#319',
        rank: 'Rank693',
    },
    {
        url: './2/1077.png',
        name: 'BSTC#1077',
        rank: 'Rank694',
    },
    {
        url: './2/1632.png',
        name: 'BSTC#1632',
        rank: 'Rank695',
    },
    {
        url: './2/2413.png',
        name: 'BSTC#2413',
        rank: 'Rank696',
    },
    {
        url: './2/22.png',
        name: 'BSTC#22',
        rank: 'Rank697',
    },
    {
        url: './2/1081.png',
        name: 'BSTC#1081',
        rank: 'Rank698',
    },
    {
        url: './2/1535.png',
        name: 'BSTC#1535',
        rank: 'Rank699',
    },
    {
        url: './2/2024.png',
        name: 'BSTC#2024',
        rank: 'Rank700',
    },
    {
        url: './2/2074.png',
        name: 'BSTC#2074',
        rank: 'Rank701',
    },
    {
        url: './2/2419.png',
        name: 'BSTC#2419',
        rank: 'Rank702',
    },
    {
        url: './2/763.png',
        name: 'BSTC#763',
        rank: 'Rank703',
    },
    {
        url: './2/656.png',
        name: 'BSTC#656',
        rank: 'Rank704',
    },
    {
        url: './2/1230.png',
        name: 'BSTC#1230',
        rank: 'Rank705',
    },
    {
        url: './2/146.png',
        name: 'BSTC#146',
        rank: 'Rank706',
    },
    {
        url: './2/168.png',
        name: 'BSTC#168',
        rank: 'Rank707',
    },
    {
        url: './2/2018.png',
        name: 'BSTC#2018',
        rank: 'Rank708',
    },
    {
        url: './2/730.png',
        name: 'BSTC#730',
        rank: 'Rank709',
    },
    {
        url: './2/2309.png',
        name: 'BSTC#2309',
        rank: 'Rank710',
    },
    {
        url: './2/3108.png',
        name: 'BSTC#3108',
        rank: 'Rank711',
    },
    {
        url: './2/1311.png',
        name: 'BSTC#1311',
        rank: 'Rank712',
    },
    {
        url: './2/2725.png',
        name: 'BSTC#2725',
        rank: 'Rank713',
    },
    {
        url: './2/1273.png',
        name: 'BSTC#1273',
        rank: 'Rank714',
    },
    {
        url: './2/498.png',
        name: 'BSTC#498',
        rank: 'Rank715',
    },
    {
        url: './2/1340.png',
        name: 'BSTC#1340',
        rank: 'Rank716',
    },
    {
        url: './2/3117.png',
        name: 'BSTC#3117',
        rank: 'Rank717',
    },
    {
        url: './2/1004.png',
        name: 'BSTC#1004',
        rank: 'Rank718',
    },
    {
        url: './2/1833.png',
        name: 'BSTC#1833',
        rank: 'Rank719',
    },
    {
        url: './2/3029.png',
        name: 'BSTC#3029',
        rank: 'Rank720',
    },
    {
        url: './2/924.png',
        name: 'BSTC#924',
        rank: 'Rank721',
    },
    {
        url: './2/723.png',
        name: 'BSTC#723',
        rank: 'Rank722',
    },
    {
        url: './2/2458.png',
        name: 'BSTC#2458',
        rank: 'Rank723',
    },
    {
        url: './2/1175.png',
        name: 'BSTC#1175',
        rank: 'Rank724',
    },
    {
        url: './2/2305.png',
        name: 'BSTC#2305',
        rank: 'Rank725',
    },
    {
        url: './2/2659.png',
        name: 'BSTC#2659',
        rank: 'Rank726',
    },
    {
        url: './2/2738.png',
        name: 'BSTC#2738',
        rank: 'Rank727',
    },
    {
        url: './2/395.png',
        name: 'BSTC#395',
        rank: 'Rank728',
    },
    {
        url: './2/1066.png',
        name: 'BSTC#1066',
        rank: 'Rank729',
    },
    {
        url: './2/3035.png',
        name: 'BSTC#3035',
        rank: 'Rank730',
    },
    {
        url: './2/1160.png',
        name: 'BSTC#1160',
        rank: 'Rank731',
    },
    {
        url: './2/2335.png',
        name: 'BSTC#2335',
        rank: 'Rank732',
    },
    {
        url: './2/1607.png',
        name: 'BSTC#1607',
        rank: 'Rank733',
    },
    {
        url: './2/410.png',
        name: 'BSTC#410',
        rank: 'Rank734',
    },
    {
        url: './2/1213.png',
        name: 'BSTC#1213',
        rank: 'Rank735',
    },
    {
        url: './2/1234.png',
        name: 'BSTC#1234',
        rank: 'Rank736',
    },
    {
        url: './2/1729.png',
        name: 'BSTC#1729',
        rank: 'Rank737',
    },
    {
        url: './2/490.png',
        name: 'BSTC#490',
        rank: 'Rank738',
    },
    {
        url: './2/1300.png',
        name: 'BSTC#1300',
        rank: 'Rank739',
    },
    {
        url: './2/2081.png',
        name: 'BSTC#2081',
        rank: 'Rank740',
    },
    {
        url: './2/2982.png',
        name: 'BSTC#2982',
        rank: 'Rank741',
    },
    {
        url: './2/1253.png',
        name: 'BSTC#1253',
        rank: 'Rank742',
    },
    {
        url: './2/733.png',
        name: 'BSTC#733',
        rank: 'Rank743',
    },
    {
        url: './2/1452.png',
        name: 'BSTC#1452',
        rank: 'Rank744',
    },
    {
        url: './2/545.png',
        name: 'BSTC#545',
        rank: 'Rank745',
    },
    {
        url: './2/1882.png',
        name: 'BSTC#1882',
        rank: 'Rank746',
    },
    {
        url: './2/2423.png',
        name: 'BSTC#2423',
        rank: 'Rank747',
    },
    {
        url: './2/2625.png',
        name: 'BSTC#2625',
        rank: 'Rank748',
    },
    {
        url: './2/2711.png',
        name: 'BSTC#2711',
        rank: 'Rank749',
    },
    {
        url: './2/2731.png',
        name: 'BSTC#2731',
        rank: 'Rank750',
    },
    {
        url: './2/1949.png',
        name: 'BSTC#1949',
        rank: 'Rank751',
    },
    {
        url: './2/505.png',
        name: 'BSTC#505',
        rank: 'Rank752',
    },
    {
        url: './2/635.png',
        name: 'BSTC#635',
        rank: 'Rank753',
    },
    {
        url: './2/305.png',
        name: 'BSTC#305',
        rank: 'Rank754',
    },
    {
        url: './2/783.png',
        name: 'BSTC#783',
        rank: 'Rank755',
    },
    {
        url: './2/857.png',
        name: 'BSTC#857',
        rank: 'Rank756',
    },
    {
        url: './2/1426.png',
        name: 'BSTC#1426',
        rank: 'Rank757',
    },
    {
        url: './2/2001.png',
        name: 'BSTC#2001',
        rank: 'Rank758',
    },
    {
        url: './2/2547.png',
        name: 'BSTC#2547',
        rank: 'Rank759',
    },
    {
        url: './2/2907.png',
        name: 'BSTC#2907',
        rank: 'Rank760',
    },
    {
        url: './2/1595.png',
        name: 'BSTC#1595',
        rank: 'Rank761',
    },
    {
        url: './2/360.png',
        name: 'BSTC#360',
        rank: 'Rank762',
    },
    {
        url: './2/405.png',
        name: 'BSTC#405',
        rank: 'Rank763',
    },
    {
        url: './2/2144.png',
        name: 'BSTC#2144',
        rank: 'Rank764',
    },
    {
        url: './2/2179.png',
        name: 'BSTC#2179',
        rank: 'Rank765',
    },
    {
        url: './2/3009.png',
        name: 'BSTC#3009',
        rank: 'Rank766',
    },
    {
        url: './2/1454.png',
        name: 'BSTC#1454',
        rank: 'Rank767',
    },
    {
        url: './2/2121.png',
        name: 'BSTC#2121',
        rank: 'Rank768',
    },
    {
        url: './2/1365.png',
        name: 'BSTC#1365',
        rank: 'Rank769',
    },
    {
        url: './2/277.png',
        name: 'BSTC#277',
        rank: 'Rank770',
    },
    {
        url: './2/3081.png',
        name: 'BSTC#3081',
        rank: 'Rank771',
    },
    {
        url: './2/1521.png',
        name: 'BSTC#1521',
        rank: 'Rank772',
    },
    {
        url: './2/878.png',
        name: 'BSTC#878',
        rank: 'Rank773',
    },
    {
        url: './2/1512.png',
        name: 'BSTC#1512',
        rank: 'Rank774',
    },
    {
        url: './2/1654.png',
        name: 'BSTC#1654',
        rank: 'Rank775',
    },
    {
        url: './2/1902.png',
        name: 'BSTC#1902',
        rank: 'Rank776',
    },
    {
        url: './2/1046.png',
        name: 'BSTC#1046',
        rank: 'Rank777',
    },
    {
        url: './2/60.png',
        name: 'BSTC#60',
        rank: 'Rank778',
    },
    {
        url: './2/150.png',
        name: 'BSTC#150',
        rank: 'Rank779',
    },
    {
        url: './2/629.png',
        name: 'BSTC#629',
        rank: 'Rank780',
    },
    {
        url: './2/1141.png',
        name: 'BSTC#1141',
        rank: 'Rank781',
    },
    {
        url: './2/2298.png',
        name: 'BSTC#2298',
        rank: 'Rank782',
    },
    {
        url: './2/2315.png',
        name: 'BSTC#2315',
        rank: 'Rank783',
    },
    {
        url: './2/3055.png',
        name: 'BSTC#3055',
        rank: 'Rank784',
    },
    {
        url: './2/1789.png',
        name: 'BSTC#1789',
        rank: 'Rank785',
    },
    {
        url: './2/767.png',
        name: 'BSTC#767',
        rank: 'Rank786',
    },
    {
        url: './2/690.png',
        name: 'BSTC#690',
        rank: 'Rank787',
    },
    {
        url: './2/190.png',
        name: 'BSTC#190',
        rank: 'Rank788',
    },
    {
        url: './2/799.png',
        name: 'BSTC#799',
        rank: 'Rank789',
    },
    {
        url: './2/1015.png',
        name: 'BSTC#1015',
        rank: 'Rank790',
    },
    {
        url: './2/2027.png',
        name: 'BSTC#2027',
        rank: 'Rank791',
    },
    {
        url: './2/2673.png',
        name: 'BSTC#2673',
        rank: 'Rank792',
    },
    {
        url: './2/288.png',
        name: 'BSTC#288',
        rank: 'Rank793',
    },
    {
        url: './2/2255.png',
        name: 'BSTC#2255',
        rank: 'Rank794',
    },
    {
        url: './2/1207.png',
        name: 'BSTC#1207',
        rank: 'Rank795',
    },
    {
        url: './2/806.png',
        name: 'BSTC#806',
        rank: 'Rank796',
    },
    {
        url: './2/2433.png',
        name: 'BSTC#2433',
        rank: 'Rank797',
    },
    {
        url: './2/2398.png',
        name: 'BSTC#2398',
        rank: 'Rank798',
    },
    {
        url: './2/1635.png',
        name: 'BSTC#1635',
        rank: 'Rank799',
    },
    {
        url: './2/1787.png',
        name: 'BSTC#1787',
        rank: 'Rank800',
    },
    {
        url: './2/145.png',
        name: 'BSTC#145',
        rank: 'Rank801',
    },
    {
        url: './2/1272.png',
        name: 'BSTC#1272',
        rank: 'Rank802',
    },
    {
        url: './2/1383.png',
        name: 'BSTC#1383',
        rank: 'Rank803',
    },
    {
        url: './2/1553.png',
        name: 'BSTC#1553',
        rank: 'Rank804',
    },
    {
        url: './2/2980.png',
        name: 'BSTC#2980',
        rank: 'Rank805',
    },
    {
        url: './2/808.png',
        name: 'BSTC#808',
        rank: 'Rank806',
    },
    {
        url: './2/219.png',
        name: 'BSTC#219',
        rank: 'Rank807',
    },
    {
        url: './2/2048.png',
        name: 'BSTC#2048',
        rank: 'Rank808',
    },
    {
        url: './2/2198.png',
        name: 'BSTC#2198',
        rank: 'Rank809',
    },
    {
        url: './2/1524.png',
        name: 'BSTC#1524',
        rank: 'Rank810',
    },
    {
        url: './2/1261.png',
        name: 'BSTC#1261',
        rank: 'Rank811',
    },
    {
        url: './2/1473.png',
        name: 'BSTC#1473',
        rank: 'Rank812',
    },
    {
        url: './2/2301.png',
        name: 'BSTC#2301',
        rank: 'Rank813',
    },
    {
        url: './2/2947.png',
        name: 'BSTC#2947',
        rank: 'Rank814',
    },
    {
        url: './2/615.png',
        name: 'BSTC#615',
        rank: 'Rank815',
    },
    {
        url: './2/141.png',
        name: 'BSTC#141',
        rank: 'Rank816',
    },
    {
        url: './2/965.png',
        name: 'BSTC#965',
        rank: 'Rank817',
    },
    {
        url: './2/2127.png',
        name: 'BSTC#2127',
        rank: 'Rank818',
    },
    {
        url: './2/1930.png',
        name: 'BSTC#1930',
        rank: 'Rank819',
    },
    {
        url: './2/1445.png',
        name: 'BSTC#1445',
        rank: 'Rank820',
    },
    {
        url: './2/2294.png',
        name: 'BSTC#2294',
        rank: 'Rank821',
    },
    {
        url: './2/2785.png',
        name: 'BSTC#2785',
        rank: 'Rank822',
    },
    {
        url: './2/866.png',
        name: 'BSTC#866',
        rank: 'Rank823',
    },
    {
        url: './2/1708.png',
        name: 'BSTC#1708',
        rank: 'Rank824',
    },
    {
        url: './2/2829.png',
        name: 'BSTC#2829',
        rank: 'Rank825',
    },
    {
        url: './2/3083.png',
        name: 'BSTC#3083',
        rank: 'Rank826',
    },
    {
        url: './2/914.png',
        name: 'BSTC#914',
        rank: 'Rank827',
    },
    {
        url: './2/1049.png',
        name: 'BSTC#1049',
        rank: 'Rank828',
    },
    {
        url: './2/2761.png',
        name: 'BSTC#2761',
        rank: 'Rank829',
    },
    {
        url: './2/2788.png',
        name: 'BSTC#2788',
        rank: 'Rank830',
    },
    {
        url: './2/1510.png',
        name: 'BSTC#1510',
        rank: 'Rank831',
    },
    {
        url: './2/334.png',
        name: 'BSTC#334',
        rank: 'Rank832',
    },
    {
        url: './2/2906.png',
        name: 'BSTC#2906',
        rank: 'Rank833',
    },
    {
        url: './2/1939.png',
        name: 'BSTC#1939',
        rank: 'Rank834',
    },
    {
        url: './2/2111.png',
        name: 'BSTC#2111',
        rank: 'Rank835',
    },
    {
        url: './2/1672.png',
        name: 'BSTC#1672',
        rank: 'Rank836',
    },
    {
        url: './2/1993.png',
        name: 'BSTC#1993',
        rank: 'Rank837',
    },
    {
        url: './2/2295.png',
        name: 'BSTC#2295',
        rank: 'Rank838',
    },
    {
        url: './2/1076.png',
        name: 'BSTC#1076',
        rank: 'Rank839',
    },
    {
        url: './2/1248.png',
        name: 'BSTC#1248',
        rank: 'Rank840',
    },
    {
        url: './2/1798.png',
        name: 'BSTC#1798',
        rank: 'Rank841',
    },
    {
        url: './2/1880.png',
        name: 'BSTC#1880',
        rank: 'Rank842',
    },
    {
        url: './2/1373.png',
        name: 'BSTC#1373',
        rank: 'Rank843',
    },
    {
        url: './2/2367.png',
        name: 'BSTC#2367',
        rank: 'Rank844',
    },
    {
        url: './2/2468.png',
        name: 'BSTC#2468',
        rank: 'Rank845',
    },
    {
        url: './2/2968.png',
        name: 'BSTC#2968',
        rank: 'Rank846',
    },
    {
        url: './2/962.png',
        name: 'BSTC#962',
        rank: 'Rank847',
    },
    {
        url: './2/963.png',
        name: 'BSTC#963',
        rank: 'Rank848',
    },
    {
        url: './2/2038.png',
        name: 'BSTC#2038',
        rank: 'Rank849',
    },
    {
        url: './2/1086.png',
        name: 'BSTC#1086',
        rank: 'Rank850',
    },
    {
        url: './2/1558.png',
        name: 'BSTC#1558',
        rank: 'Rank851',
    },
    {
        url: './2/1855.png',
        name: 'BSTC#1855',
        rank: 'Rank852',
    },
    {
        url: './2/872.png',
        name: 'BSTC#872',
        rank: 'Rank853',
    },
    {
        url: './2/1508.png',
        name: 'BSTC#1508',
        rank: 'Rank854',
    },
    {
        url: './2/632.png',
        name: 'BSTC#632',
        rank: 'Rank855',
    },
    {
        url: './2/154.png',
        name: 'BSTC#154',
        rank: 'Rank856',
    },
    {
        url: './2/1894.png',
        name: 'BSTC#1894',
        rank: 'Rank857',
    },
    {
        url: './2/2284.png',
        name: 'BSTC#2284',
        rank: 'Rank858',
    },
    {
        url: './2/2356.png',
        name: 'BSTC#2356',
        rank: 'Rank859',
    },
    {
        url: './2/134.png',
        name: 'BSTC#134',
        rank: 'Rank860',
    },
    {
        url: './2/901.png',
        name: 'BSTC#901',
        rank: 'Rank861',
    },
    {
        url: './2/728.png',
        name: 'BSTC#728',
        rank: 'Rank862',
    },
    {
        url: './2/2382.png',
        name: 'BSTC#2382',
        rank: 'Rank863',
    },
    {
        url: './2/1330.png',
        name: 'BSTC#1330',
        rank: 'Rank864',
    },
    {
        url: './2/1449.png',
        name: 'BSTC#1449',
        rank: 'Rank865',
    },
    {
        url: './2/1453.png',
        name: 'BSTC#1453',
        rank: 'Rank866',
    },
    {
        url: './2/1545.png',
        name: 'BSTC#1545',
        rank: 'Rank867',
    },
    {
        url: './2/332.png',
        name: 'BSTC#332',
        rank: 'Rank868',
    },
    {
        url: './2/509.png',
        name: 'BSTC#509',
        rank: 'Rank869',
    },
    {
        url: './2/1257.png',
        name: 'BSTC#1257',
        rank: 'Rank870',
    },
    {
        url: './2/1450.png',
        name: 'BSTC#1450',
        rank: 'Rank871',
    },
    {
        url: './2/1407.png',
        name: 'BSTC#1407',
        rank: 'Rank872',
    },
    {
        url: './2/1614.png',
        name: 'BSTC#1614',
        rank: 'Rank873',
    },
    {
        url: './2/1843.png',
        name: 'BSTC#1843',
        rank: 'Rank874',
    },
    {
        url: './2/1952.png',
        name: 'BSTC#1952',
        rank: 'Rank875',
    },
    {
        url: './2/2834.png',
        name: 'BSTC#2834',
        rank: 'Rank876',
    },
    {
        url: './2/2855.png',
        name: 'BSTC#2855',
        rank: 'Rank877',
    },
    {
        url: './2/2902.png',
        name: 'BSTC#2902',
        rank: 'Rank878',
    },
    {
        url: './2/2923.png',
        name: 'BSTC#2923',
        rank: 'Rank879',
    },
    {
        url: './2/47.png',
        name: 'BSTC#47',
        rank: 'Rank880',
    },
    {
        url: './2/812.png',
        name: 'BSTC#812',
        rank: 'Rank881',
    },
    {
        url: './2/813.png',
        name: 'BSTC#813',
        rank: 'Rank882',
    },
    {
        url: './2/846.png',
        name: 'BSTC#846',
        rank: 'Rank883',
    },
    {
        url: './2/1003.png',
        name: 'BSTC#1003',
        rank: 'Rank884',
    },
    {
        url: './2/1074.png',
        name: 'BSTC#1074',
        rank: 'Rank885',
    },
    {
        url: './2/2008.png',
        name: 'BSTC#2008',
        rank: 'Rank886',
    },
    {
        url: './2/2314.png',
        name: 'BSTC#2314',
        rank: 'Rank887',
    },
    {
        url: './2/2426.png',
        name: 'BSTC#2426',
        rank: 'Rank888',
    },
    {
        url: './2/2678.png',
        name: 'BSTC#2678',
        rank: 'Rank889',
    },
    {
        url: './2/2441.png',
        name: 'BSTC#2441',
        rank: 'Rank890',
    },
    {
        url: './2/192.png',
        name: 'BSTC#192',
        rank: 'Rank891',
    },
    {
        url: './2/1712.png',
        name: 'BSTC#1712',
        rank: 'Rank892',
    },
    {
        url: './2/1608.png',
        name: 'BSTC#1608',
        rank: 'Rank893',
    },
    {
        url: './2/3049.png',
        name: 'BSTC#3049',
        rank: 'Rank894',
    },
    {
        url: './2/1196.png',
        name: 'BSTC#1196',
        rank: 'Rank895',
    },
    {
        url: './2/1356.png',
        name: 'BSTC#1356',
        rank: 'Rank896',
    },
    {
        url: './2/2014.png',
        name: 'BSTC#2014',
        rank: 'Rank897',
    },
    {
        url: './2/791.png',
        name: 'BSTC#791',
        rank: 'Rank898',
    },
    {
        url: './2/831.png',
        name: 'BSTC#831',
        rank: 'Rank899',
    },
    {
        url: './2/1159.png',
        name: 'BSTC#1159',
        rank: 'Rank900',
    },
    {
        url: './2/2263.png',
        name: 'BSTC#2263',
        rank: 'Rank901',
    },
    {
        url: './2/814.png',
        name: 'BSTC#814',
        rank: 'Rank902',
    },
    {
        url: './2/775.png',
        name: 'BSTC#775',
        rank: 'Rank903',
    },
    {
        url: './2/2434.png',
        name: 'BSTC#2434',
        rank: 'Rank904',
    },
    {
        url: './2/3036.png',
        name: 'BSTC#3036',
        rank: 'Rank905',
    },
    {
        url: './2/1815.png',
        name: 'BSTC#1815',
        rank: 'Rank906',
    },
    {
        url: './2/1629.png',
        name: 'BSTC#1629',
        rank: 'Rank907',
    },
    {
        url: './2/1495.png',
        name: 'BSTC#1495',
        rank: 'Rank908',
    },
    {
        url: './2/1532.png',
        name: 'BSTC#1532',
        rank: 'Rank909',
    },
    {
        url: './2/822.png',
        name: 'BSTC#822',
        rank: 'Rank910',
    },
    {
        url: './2/2560.png',
        name: 'BSTC#2560',
        rank: 'Rank911',
    },
    {
        url: './2/1109.png',
        name: 'BSTC#1109',
        rank: 'Rank912',
    },
    {
        url: './2/2064.png',
        name: 'BSTC#2064',
        rank: 'Rank913',
    },
    {
        url: './2/2953.png',
        name: 'BSTC#2953',
        rank: 'Rank914',
    },
    {
        url: './2/976.png',
        name: 'BSTC#976',
        rank: 'Rank915',
    },
    {
        url: './2/1859.png',
        name: 'BSTC#1859',
        rank: 'Rank916',
    },
    {
        url: './2/199.png',
        name: 'BSTC#199',
        rank: 'Rank917',
    },
    {
        url: './2/259.png',
        name: 'BSTC#259',
        rank: 'Rank918',
    },
    {
        url: './2/2716.png',
        name: 'BSTC#2716',
        rank: 'Rank919',
    },
    {
        url: './2/2743.png',
        name: 'BSTC#2743',
        rank: 'Rank920',
    },
    {
        url: './2/252.png',
        name: 'BSTC#252',
        rank: 'Rank921',
    },
    {
        url: './2/655.png',
        name: 'BSTC#655',
        rank: 'Rank922',
    },
    {
        url: './2/1499.png',
        name: 'BSTC#1499',
        rank: 'Rank923',
    },
    {
        url: './2/2112.png',
        name: 'BSTC#2112',
        rank: 'Rank924',
    },
    {
        url: './2/2715.png',
        name: 'BSTC#2715',
        rank: 'Rank925',
    },
    {
        url: './2/637.png',
        name: 'BSTC#637',
        rank: 'Rank926',
    },
    {
        url: './2/2863.png',
        name: 'BSTC#2863',
        rank: 'Rank927',
    },
    {
        url: './2/2347.png',
        name: 'BSTC#2347',
        rank: 'Rank928',
    },
    {
        url: './2/2011.png',
        name: 'BSTC#2011',
        rank: 'Rank929',
    },
    {
        url: './2/2021.png',
        name: 'BSTC#2021',
        rank: 'Rank930',
    },
    {
        url: './2/2851.png',
        name: 'BSTC#2851',
        rank: 'Rank931',
    },
    {
        url: './2/2839.png',
        name: 'BSTC#2839',
        rank: 'Rank932',
    },
    {
        url: './2/1276.png',
        name: 'BSTC#1276',
        rank: 'Rank933',
    },
    {
        url: './2/1844.png',
        name: 'BSTC#1844',
        rank: 'Rank934',
    },
    {
        url: './2/39.png',
        name: 'BSTC#39',
        rank: 'Rank935',
    },
    {
        url: './2/1223.png',
        name: 'BSTC#1223',
        rank: 'Rank936',
    },
    {
        url: './2/2891.png',
        name: 'BSTC#2891',
        rank: 'Rank937',
    },
    {
        url: './2/628.png',
        name: 'BSTC#628',
        rank: 'Rank938',
    },
    {
        url: './2/2970.png',
        name: 'BSTC#2970',
        rank: 'Rank939',
    },
    {
        url: './2/341.png',
        name: 'BSTC#341',
        rank: 'Rank940',
    },
    {
        url: './2/2952.png',
        name: 'BSTC#2952',
        rank: 'Rank941',
    },
    {
        url: './2/110.png',
        name: 'BSTC#110',
        rank: 'Rank942',
    },
    {
        url: './2/66.png',
        name: 'BSTC#66',
        rank: 'Rank943',
    },
    {
        url: './2/1221.png',
        name: 'BSTC#1221',
        rank: 'Rank944',
    },
    {
        url: './2/892.png',
        name: 'BSTC#892',
        rank: 'Rank945',
    },
    {
        url: './2/1149.png',
        name: 'BSTC#1149',
        rank: 'Rank946',
    },
    {
        url: './2/2252.png',
        name: 'BSTC#2252',
        rank: 'Rank947',
    },
    {
        url: './2/2573.png',
        name: 'BSTC#2573',
        rank: 'Rank948',
    },
    {
        url: './2/1850.png',
        name: 'BSTC#1850',
        rank: 'Rank949',
    },
    {
        url: './2/2737.png',
        name: 'BSTC#2737',
        rank: 'Rank950',
    },
    {
        url: './2/2837.png',
        name: 'BSTC#2837',
        rank: 'Rank951',
    },
    {
        url: './2/3006.png',
        name: 'BSTC#3006',
        rank: 'Rank952',
    },
    {
        url: './2/290.png',
        name: 'BSTC#290',
        rank: 'Rank953',
    },
    {
        url: './2/229.png',
        name: 'BSTC#229',
        rank: 'Rank954',
    },
    {
        url: './2/2596.png',
        name: 'BSTC#2596',
        rank: 'Rank955',
    },
    {
        url: './2/123.png',
        name: 'BSTC#123',
        rank: 'Rank956',
    },
    {
        url: './2/915.png',
        name: 'BSTC#915',
        rank: 'Rank957',
    },
    {
        url: './2/691.png',
        name: 'BSTC#691',
        rank: 'Rank958',
    },
    {
        url: './2/906.png',
        name: 'BSTC#906',
        rank: 'Rank959',
    },
    {
        url: './2/2459.png',
        name: 'BSTC#2459',
        rank: 'Rank960',
    },
    {
        url: './2/507.png',
        name: 'BSTC#507',
        rank: 'Rank961',
    },
    {
        url: './2/12.png',
        name: 'BSTC#12',
        rank: 'Rank962',
    },
    {
        url: './2/2809.png',
        name: 'BSTC#2809',
        rank: 'Rank963',
    },
    {
        url: './2/386.png',
        name: 'BSTC#386',
        rank: 'Rank964',
    },
    {
        url: './2/170.png',
        name: 'BSTC#170',
        rank: 'Rank965',
    },
    {
        url: './2/2961.png',
        name: 'BSTC#2961',
        rank: 'Rank966',
    },
    {
        url: './2/1343.png',
        name: 'BSTC#1343',
        rank: 'Rank967',
    },
    {
        url: './2/717.png',
        name: 'BSTC#717',
        rank: 'Rank968',
    },
    {
        url: './2/818.png',
        name: 'BSTC#818',
        rank: 'Rank969',
    },
    {
        url: './2/1573.png',
        name: 'BSTC#1573',
        rank: 'Rank970',
    },
    {
        url: './2/2073.png',
        name: 'BSTC#2073',
        rank: 'Rank971',
    },
    {
        url: './2/1502.png',
        name: 'BSTC#1502',
        rank: 'Rank972',
    },
    {
        url: './2/1091.png',
        name: 'BSTC#1091',
        rank: 'Rank973',
    },
    {
        url: './2/1237.png',
        name: 'BSTC#1237',
        rank: 'Rank974',
    },
    {
        url: './2/1409.png',
        name: 'BSTC#1409',
        rank: 'Rank975',
    },
    {
        url: './2/152.png',
        name: 'BSTC#152',
        rank: 'Rank976',
    },
    {
        url: './2/1805.png',
        name: 'BSTC#1805',
        rank: 'Rank977',
    },
    {
        url: './2/1799.png',
        name: 'BSTC#1799',
        rank: 'Rank978',
    },
    {
        url: './2/513.png',
        name: 'BSTC#513',
        rank: 'Rank979',
    },
    {
        url: './2/1231.png',
        name: 'BSTC#1231',
        rank: 'Rank980',
    },
    {
        url: './2/174.png',
        name: 'BSTC#174',
        rank: 'Rank981',
    },
    {
        url: './2/698.png',
        name: 'BSTC#698',
        rank: 'Rank982',
    },
    {
        url: './2/2935.png',
        name: 'BSTC#2935',
        rank: 'Rank983',
    },
    {
        url: './2/36.png',
        name: 'BSTC#36',
        rank: 'Rank984',
    },
    {
        url: './2/2862.png',
        name: 'BSTC#2862',
        rank: 'Rank985',
    },
    {
        url: './2/739.png',
        name: 'BSTC#739',
        rank: 'Rank986',
    },
    {
        url: './2/1685.png',
        name: 'BSTC#1685',
        rank: 'Rank987',
    },
    {
        url: './2/409.png',
        name: 'BSTC#409',
        rank: 'Rank988',
    },
    {
        url: './2/2571.png',
        name: 'BSTC#2571',
        rank: 'Rank989',
    },
    {
        url: './2/2469.png',
        name: 'BSTC#2469',
        rank: 'Rank990',
    },
    {
        url: './2/2267.png',
        name: 'BSTC#2267',
        rank: 'Rank991',
    },
    {
        url: './2/3094.png',
        name: 'BSTC#3094',
        rank: 'Rank992',
    },
    {
        url: './2/2484.png',
        name: 'BSTC#2484',
        rank: 'Rank993',
    },
    {
        url: './2/2962.png',
        name: 'BSTC#2962',
        rank: 'Rank994',
    },
    {
        url: './2/917.png',
        name: 'BSTC#917',
        rank: 'Rank995',
    },
    {
        url: './2/464.png',
        name: 'BSTC#464',
        rank: 'Rank996',
    },
    {
        url: './2/1717.png',
        name: 'BSTC#1717',
        rank: 'Rank997',
    },
    {
        url: './2/2888.png',
        name: 'BSTC#2888',
        rank: 'Rank998',
    },
    {
        url: './2/532.png',
        name: 'BSTC#532',
        rank: 'Rank999',
    },
    {
        url: './2/830.png',
        name: 'BSTC#830',
        rank: 'Rank1000',
    },
    {
        url: './2/987.png',
        name: 'BSTC#987',
        rank: 'Rank1001',
    },
    {
        url: './2/2067.png',
        name: 'BSTC#2067',
        rank: 'Rank1002',
    },
    {
        url: './2/2649.png',
        name: 'BSTC#2649',
        rank: 'Rank1003',
    },
    {
        url: './2/2578.png',
        name: 'BSTC#2578',
        rank: 'Rank1004',
    },
    {
        url: './2/397.png',
        name: 'BSTC#397',
        rank: 'Rank1005',
    },
    {
        url: './2/539.png',
        name: 'BSTC#539',
        rank: 'Rank1006',
    },
    {
        url: './2/2750.png',
        name: 'BSTC#2750',
        rank: 'Rank1007',
    },
    {
        url: './2/1650.png',
        name: 'BSTC#1650',
        rank: 'Rank1008',
    },
    {
        url: './2/665.png',
        name: 'BSTC#665',
        rank: 'Rank1009',
    },
    {
        url: './2/1402.png',
        name: 'BSTC#1402',
        rank: 'Rank1010',
    },
    {
        url: './2/2563.png',
        name: 'BSTC#2563',
        rank: 'Rank1011',
    },
    {
        url: './2/427.png',
        name: 'BSTC#427',
        rank: 'Rank1012',
    },
    {
        url: './2/3003.png',
        name: 'BSTC#3003',
        rank: 'Rank1013',
    },
    {
        url: './2/2416.png',
        name: 'BSTC#2416',
        rank: 'Rank1014',
    },
    {
        url: './2/2983.png',
        name: 'BSTC#2983',
        rank: 'Rank1015',
    },
    {
        url: './2/1992.png',
        name: 'BSTC#1992',
        rank: 'Rank1016',
    },
    {
        url: './2/1926.png',
        name: 'BSTC#1926',
        rank: 'Rank1017',
    },
    {
        url: './2/2393.png',
        name: 'BSTC#2393',
        rank: 'Rank1018',
    },
    {
        url: './2/2684.png',
        name: 'BSTC#2684',
        rank: 'Rank1019',
    },
    {
        url: './2/1400.png',
        name: 'BSTC#1400',
        rank: 'Rank1020',
    },
    {
        url: './2/1463.png',
        name: 'BSTC#1463',
        rank: 'Rank1021',
    },
    {
        url: './2/2175.png',
        name: 'BSTC#2175',
        rank: 'Rank1022',
    },
    {
        url: './2/2979.png',
        name: 'BSTC#2979',
        rank: 'Rank1023',
    },
    {
        url: './2/3056.png',
        name: 'BSTC#3056',
        rank: 'Rank1024',
    },
    {
        url: './2/3015.png',
        name: 'BSTC#3015',
        rank: 'Rank1025',
    },
    {
        url: './2/1041.png',
        name: 'BSTC#1041',
        rank: 'Rank1026',
    },
    {
        url: './2/979.png',
        name: 'BSTC#979',
        rank: 'Rank1027',
    },
    {
        url: './2/2102.png',
        name: 'BSTC#2102',
        rank: 'Rank1028',
    },
    {
        url: './2/1784.png',
        name: 'BSTC#1784',
        rank: 'Rank1029',
    },
    {
        url: './2/2391.png',
        name: 'BSTC#2391',
        rank: 'Rank1030',
    },
    {
        url: './2/1701.png',
        name: 'BSTC#1701',
        rank: 'Rank1031',
    },
    {
        url: './2/287.png',
        name: 'BSTC#287',
        rank: 'Rank1032',
    },
    {
        url: './2/2361.png',
        name: 'BSTC#2361',
        rank: 'Rank1033',
    },
    {
        url: './2/2892.png',
        name: 'BSTC#2892',
        rank: 'Rank1034',
    },
    {
        url: './2/416.png',
        name: 'BSTC#416',
        rank: 'Rank1035',
    },
    {
        url: './2/1697.png',
        name: 'BSTC#1697',
        rank: 'Rank1036',
    },
    {
        url: './2/599.png',
        name: 'BSTC#599',
        rank: 'Rank1037',
    },
    {
        url: './2/2195.png',
        name: 'BSTC#2195',
        rank: 'Rank1038',
    },
    {
        url: './2/1152.png',
        name: 'BSTC#1152',
        rank: 'Rank1039',
    },
    {
        url: './2/844.png',
        name: 'BSTC#844',
        rank: 'Rank1040',
    },
    {
        url: './2/747.png',
        name: 'BSTC#747',
        rank: 'Rank1041',
    },
    {
        url: './2/2490.png',
        name: 'BSTC#2490',
        rank: 'Rank1042',
    },
    {
        url: './2/2955.png',
        name: 'BSTC#2955',
        rank: 'Rank1043',
    },
    {
        url: './2/1359.png',
        name: 'BSTC#1359',
        rank: 'Rank1044',
    },
    {
        url: './2/1909.png',
        name: 'BSTC#1909',
        rank: 'Rank1045',
    },
    {
        url: './2/697.png',
        name: 'BSTC#697',
        rank: 'Rank1046',
    },
    {
        url: './2/2208.png',
        name: 'BSTC#2208',
        rank: 'Rank1047',
    },
    {
        url: './2/2572.png',
        name: 'BSTC#2572',
        rank: 'Rank1048',
    },
    {
        url: './2/2629.png',
        name: 'BSTC#2629',
        rank: 'Rank1049',
    },
    {
        url: './2/113.png',
        name: 'BSTC#113',
        rank: 'Rank1050',
    },
    {
        url: './2/2072.png',
        name: 'BSTC#2072',
        rank: 'Rank1051',
    },
    {
        url: './2/483.png',
        name: 'BSTC#483',
        rank: 'Rank1052',
    },
    {
        url: './2/884.png',
        name: 'BSTC#884',
        rank: 'Rank1053',
    },
    {
        url: './2/1.png',
        name: 'BSTC#1',
        rank: 'Rank1054',
    },
    {
        url: './2/1082.png',
        name: 'BSTC#1082',
        rank: 'Rank1055',
    },
    {
        url: './2/1294.png',
        name: 'BSTC#1294',
        rank: 'Rank1056',
    },
    {
        url: './2/2831.png',
        name: 'BSTC#2831',
        rank: 'Rank1057',
    },
    {
        url: './2/567.png',
        name: 'BSTC#567',
        rank: 'Rank1058',
    },
    {
        url: './2/1847.png',
        name: 'BSTC#1847',
        rank: 'Rank1059',
    },
    {
        url: './2/1067.png',
        name: 'BSTC#1067',
        rank: 'Rank1060',
    },
    {
        url: './2/1750.png',
        name: 'BSTC#1750',
        rank: 'Rank1061',
    },
    {
        url: './2/1826.png',
        name: 'BSTC#1826',
        rank: 'Rank1062',
    },
    {
        url: './2/2004.png',
        name: 'BSTC#2004',
        rank: 'Rank1063',
    },
    {
        url: './2/2386.png',
        name: 'BSTC#2386',
        rank: 'Rank1064',
    },
    {
        url: './2/2655.png',
        name: 'BSTC#2655',
        rank: 'Rank1065',
    },
    {
        url: './2/312.png',
        name: 'BSTC#312',
        rank: 'Rank1066',
    },
    {
        url: './2/476.png',
        name: 'BSTC#476',
        rank: 'Rank1067',
    },
    {
        url: './2/1083.png',
        name: 'BSTC#1083',
        rank: 'Rank1068',
    },
    {
        url: './2/1920.png',
        name: 'BSTC#1920',
        rank: 'Rank1069',
    },
    {
        url: './2/2373.png',
        name: 'BSTC#2373',
        rank: 'Rank1070',
    },
    {
        url: './2/1204.png',
        name: 'BSTC#1204',
        rank: 'Rank1071',
    },
    {
        url: './2/1113.png',
        name: 'BSTC#1113',
        rank: 'Rank1072',
    },
    {
        url: './2/3000.png',
        name: 'BSTC#3000',
        rank: 'Rank1073',
    },
    {
        url: './2/1351.png',
        name: 'BSTC#1351',
        rank: 'Rank1074',
    },
    {
        url: './2/2632.png',
        name: 'BSTC#2632',
        rank: 'Rank1075',
    },
    {
        url: './2/2019.png',
        name: 'BSTC#2019',
        rank: 'Rank1076',
    },
    {
        url: './2/76.png',
        name: 'BSTC#76',
        rank: 'Rank1077',
    },
    {
        url: './2/500.png',
        name: 'BSTC#500',
        rank: 'Rank1078',
    },
    {
        url: './2/372.png',
        name: 'BSTC#372',
        rank: 'Rank1079',
    },
    {
        url: './2/699.png',
        name: 'BSTC#699',
        rank: 'Rank1080',
    },
    {
        url: './2/851.png',
        name: 'BSTC#851',
        rank: 'Rank1081',
    },
    {
        url: './2/2807.png',
        name: 'BSTC#2807',
        rank: 'Rank1082',
    },
    {
        url: './2/927.png',
        name: 'BSTC#927',
        rank: 'Rank1083',
    },
    {
        url: './2/1807.png',
        name: 'BSTC#1807',
        rank: 'Rank1084',
    },
    {
        url: './2/2944.png',
        name: 'BSTC#2944',
        rank: 'Rank1085',
    },
    {
        url: './2/560.png',
        name: 'BSTC#560',
        rank: 'Rank1086',
    },
    {
        url: './2/2235.png',
        name: 'BSTC#2235',
        rank: 'Rank1087',
    },
    {
        url: './2/2341.png',
        name: 'BSTC#2341',
        rank: 'Rank1088',
    },
    {
        url: './2/2325.png',
        name: 'BSTC#2325',
        rank: 'Rank1089',
    },
    {
        url: './2/630.png',
        name: 'BSTC#630',
        rank: 'Rank1090',
    },
    {
        url: './2/934.png',
        name: 'BSTC#934',
        rank: 'Rank1091',
    },
    {
        url: './2/2718.png',
        name: 'BSTC#2718',
        rank: 'Rank1092',
    },
    {
        url: './2/501.png',
        name: 'BSTC#501',
        rank: 'Rank1093',
    },
    {
        url: './2/542.png',
        name: 'BSTC#542',
        rank: 'Rank1094',
    },
    {
        url: './2/1917.png',
        name: 'BSTC#1917',
        rank: 'Rank1095',
    },
    {
        url: './2/893.png',
        name: 'BSTC#893',
        rank: 'Rank1096',
    },
    {
        url: './2/2827.png',
        name: 'BSTC#2827',
        rank: 'Rank1097',
    },
    {
        url: './2/2940.png',
        name: 'BSTC#2940',
        rank: 'Rank1098',
    },
    {
        url: './2/534.png',
        name: 'BSTC#534',
        rank: 'Rank1099',
    },
    {
        url: './2/1059.png',
        name: 'BSTC#1059',
        rank: 'Rank1100',
    },
    {
        url: './2/2357.png',
        name: 'BSTC#2357',
        rank: 'Rank1101',
    },
    {
        url: './2/2665.png',
        name: 'BSTC#2665',
        rank: 'Rank1102',
    },
    {
        url: './2/1790.png',
        name: 'BSTC#1790',
        rank: 'Rank1103',
    },
    {
        url: './2/3082.png',
        name: 'BSTC#3082',
        rank: 'Rank1104',
    },
    {
        url: './2/1996.png',
        name: 'BSTC#1996',
        rank: 'Rank1105',
    },
    {
        url: './2/795.png',
        name: 'BSTC#795',
        rank: 'Rank1106',
    },
    {
        url: './2/2110.png',
        name: 'BSTC#2110',
        rank: 'Rank1107',
    },
    {
        url: './2/2554.png',
        name: 'BSTC#2554',
        rank: 'Rank1108',
    },
    {
        url: './2/2623.png',
        name: 'BSTC#2623',
        rank: 'Rank1109',
    },
    {
        url: './2/1640.png',
        name: 'BSTC#1640',
        rank: 'Rank1110',
    },
    {
        url: './2/1111.png',
        name: 'BSTC#1111',
        rank: 'Rank1111',
    },
    {
        url: './2/2351.png',
        name: 'BSTC#2351',
        rank: 'Rank1112',
    },
    {
        url: './2/692.png',
        name: 'BSTC#692',
        rank: 'Rank1113',
    },
    {
        url: './2/44.png',
        name: 'BSTC#44',
        rank: 'Rank1114',
    },
    {
        url: './2/2065.png',
        name: 'BSTC#2065',
        rank: 'Rank1115',
    },
    {
        url: './2/2707.png',
        name: 'BSTC#2707',
        rank: 'Rank1116',
    },
    {
        url: './2/3115.png',
        name: 'BSTC#3115',
        rank: 'Rank1117',
    },
    {
        url: './2/880.png',
        name: 'BSTC#880',
        rank: 'Rank1118',
    },
    {
        url: './2/907.png',
        name: 'BSTC#907',
        rank: 'Rank1119',
    },
    {
        url: './2/929.png',
        name: 'BSTC#929',
        rank: 'Rank1120',
    },
    {
        url: './2/1626.png',
        name: 'BSTC#1626',
        rank: 'Rank1121',
    },
    {
        url: './2/3004.png',
        name: 'BSTC#3004',
        rank: 'Rank1122',
    },
    {
        url: './2/528.png',
        name: 'BSTC#528',
        rank: 'Rank1123',
    },
    {
        url: './2/1492.png',
        name: 'BSTC#1492',
        rank: 'Rank1124',
    },
    {
        url: './2/1686.png',
        name: 'BSTC#1686',
        rank: 'Rank1125',
    },
    {
        url: './2/56.png',
        name: 'BSTC#56',
        rank: 'Rank1126',
    },
    {
        url: './2/320.png',
        name: 'BSTC#320',
        rank: 'Rank1127',
    },
    {
        url: './2/536.png',
        name: 'BSTC#536',
        rank: 'Rank1128',
    },
    {
        url: './2/779.png',
        name: 'BSTC#779',
        rank: 'Rank1129',
    },
    {
        url: './2/790.png',
        name: 'BSTC#790',
        rank: 'Rank1130',
    },
    {
        url: './2/1599.png',
        name: 'BSTC#1599',
        rank: 'Rank1131',
    },
    {
        url: './2/1601.png',
        name: 'BSTC#1601',
        rank: 'Rank1132',
    },
    {
        url: './2/2920.png',
        name: 'BSTC#2920',
        rank: 'Rank1133',
    },
    {
        url: './2/1782.png',
        name: 'BSTC#1782',
        rank: 'Rank1134',
    },
    {
        url: './2/2500.png',
        name: 'BSTC#2500',
        rank: 'Rank1135',
    },
    {
        url: './2/58.png',
        name: 'BSTC#58',
        rank: 'Rank1136',
    },
    {
        url: './2/2727.png',
        name: 'BSTC#2727',
        rank: 'Rank1137',
    },
    {
        url: './2/3048.png',
        name: 'BSTC#3048',
        rank: 'Rank1138',
    },
    {
        url: './2/1474.png',
        name: 'BSTC#1474',
        rank: 'Rank1139',
    },
    {
        url: './2/1975.png',
        name: 'BSTC#1975',
        rank: 'Rank1140',
    },
    {
        url: './2/1469.png',
        name: 'BSTC#1469',
        rank: 'Rank1141',
    },
    {
        url: './2/282.png',
        name: 'BSTC#282',
        rank: 'Rank1142',
    },
    {
        url: './2/1643.png',
        name: 'BSTC#1643',
        rank: 'Rank1143',
    },
    {
        url: './2/1069.png',
        name: 'BSTC#1069',
        rank: 'Rank1144',
    },
    {
        url: './2/701.png',
        name: 'BSTC#701',
        rank: 'Rank1145',
    },
    {
        url: './2/989.png',
        name: 'BSTC#989',
        rank: 'Rank1146',
    },
    {
        url: './2/2914.png',
        name: 'BSTC#2914',
        rank: 'Rank1147',
    },
    {
        url: './2/1314.png',
        name: 'BSTC#1314',
        rank: 'Rank1148',
    },
    {
        url: './2/2579.png',
        name: 'BSTC#2579',
        rank: 'Rank1149',
    },
    {
        url: './2/139.png',
        name: 'BSTC#139',
        rank: 'Rank1150',
    },
    {
        url: './2/984.png',
        name: 'BSTC#984',
        rank: 'Rank1151',
    },
    {
        url: './2/1303.png',
        name: 'BSTC#1303',
        rank: 'Rank1152',
    },
    {
        url: './2/576.png',
        name: 'BSTC#576',
        rank: 'Rank1153',
    },
    {
        url: './2/595.png',
        name: 'BSTC#595',
        rank: 'Rank1154',
    },
    {
        url: './2/603.png',
        name: 'BSTC#603',
        rank: 'Rank1155',
    },
    {
        url: './2/869.png',
        name: 'BSTC#869',
        rank: 'Rank1156',
    },
    {
        url: './2/973.png',
        name: 'BSTC#973',
        rank: 'Rank1157',
    },
    {
        url: './2/1014.png',
        name: 'BSTC#1014',
        rank: 'Rank1158',
    },
    {
        url: './2/1479.png',
        name: 'BSTC#1479',
        rank: 'Rank1159',
    },
    {
        url: './2/1903.png',
        name: 'BSTC#1903',
        rank: 'Rank1160',
    },
    {
        url: './2/2062.png',
        name: 'BSTC#2062',
        rank: 'Rank1161',
    },
    {
        url: './2/2463.png',
        name: 'BSTC#2463',
        rank: 'Rank1162',
    },
    {
        url: './2/2681.png',
        name: 'BSTC#2681',
        rank: 'Rank1163',
    },
    {
        url: './2/2694.png',
        name: 'BSTC#2694',
        rank: 'Rank1164',
    },
    {
        url: './2/920.png',
        name: 'BSTC#920',
        rank: 'Rank1165',
    },
    {
        url: './2/2886.png',
        name: 'BSTC#2886',
        rank: 'Rank1166',
    },
    {
        url: './2/2402.png',
        name: 'BSTC#2402',
        rank: 'Rank1167',
    },
    {
        url: './2/3028.png',
        name: 'BSTC#3028',
        rank: 'Rank1168',
    },
    {
        url: './2/1458.png',
        name: 'BSTC#1458',
        rank: 'Rank1169',
    },
    {
        url: './2/1513.png',
        name: 'BSTC#1513',
        rank: 'Rank1170',
    },
    {
        url: './2/442.png',
        name: 'BSTC#442',
        rank: 'Rank1171',
    },
    {
        url: './2/59.png',
        name: 'BSTC#59',
        rank: 'Rank1172',
    },
    {
        url: './2/1270.png',
        name: 'BSTC#1270',
        rank: 'Rank1173',
    },
    {
        url: './2/1346.png',
        name: 'BSTC#1346',
        rank: 'Rank1174',
    },
    {
        url: './2/2233.png',
        name: 'BSTC#2233',
        rank: 'Rank1175',
    },
    {
        url: './2/2958.png',
        name: 'BSTC#2958',
        rank: 'Rank1176',
    },
    {
        url: './2/2931.png',
        name: 'BSTC#2931',
        rank: 'Rank1177',
    },
    {
        url: './2/726.png',
        name: 'BSTC#726',
        rank: 'Rank1178',
    },
    {
        url: './2/1062.png',
        name: 'BSTC#1062',
        rank: 'Rank1179',
    },
    {
        url: './2/251.png',
        name: 'BSTC#251',
        rank: 'Rank1180',
    },
    {
        url: './2/2080.png',
        name: 'BSTC#2080',
        rank: 'Rank1181',
    },
    {
        url: './2/1005.png',
        name: 'BSTC#1005',
        rank: 'Rank1182',
    },
    {
        url: './2/437.png',
        name: 'BSTC#437',
        rank: 'Rank1183',
    },
    {
        url: './2/1318.png',
        name: 'BSTC#1318',
        rank: 'Rank1184',
    },
    {
        url: './2/2101.png',
        name: 'BSTC#2101',
        rank: 'Rank1185',
    },
    {
        url: './2/1102.png',
        name: 'BSTC#1102',
        rank: 'Rank1186',
    },
    {
        url: './2/270.png',
        name: 'BSTC#270',
        rank: 'Rank1187',
    },
    {
        url: './2/2285.png',
        name: 'BSTC#2285',
        rank: 'Rank1188',
    },
    {
        url: './2/1362.png',
        name: 'BSTC#1362',
        rank: 'Rank1189',
    },
    {
        url: './2/1648.png',
        name: 'BSTC#1648',
        rank: 'Rank1190',
    },
    {
        url: './2/247.png',
        name: 'BSTC#247',
        rank: 'Rank1191',
    },
    {
        url: './2/2181.png',
        name: 'BSTC#2181',
        rank: 'Rank1192',
    },
    {
        url: './2/2455.png',
        name: 'BSTC#2455',
        rank: 'Rank1193',
    },
    {
        url: './2/2063.png',
        name: 'BSTC#2063',
        rank: 'Rank1194',
    },
    {
        url: './2/1044.png',
        name: 'BSTC#1044',
        rank: 'Rank1195',
    },
    {
        url: './2/1051.png',
        name: 'BSTC#1051',
        rank: 'Rank1196',
    },
    {
        url: './2/73.png',
        name: 'BSTC#73',
        rank: 'Rank1197',
    },
    {
        url: './2/2755.png',
        name: 'BSTC#2755',
        rank: 'Rank1198',
    },
    {
        url: './2/2084.png',
        name: 'BSTC#2084',
        rank: 'Rank1199',
    },
    {
        url: './2/2840.png',
        name: 'BSTC#2840',
        rank: 'Rank1200',
    },
    {
        url: './2/3065.png',
        name: 'BSTC#3065',
        rank: 'Rank1201',
    },
    {
        url: './2/645.png',
        name: 'BSTC#645',
        rank: 'Rank1202',
    },
    {
        url: './2/1056.png',
        name: 'BSTC#1056',
        rank: 'Rank1203',
    },
    {
        url: './2/1483.png',
        name: 'BSTC#1483',
        rank: 'Rank1204',
    },
    {
        url: './2/572.png',
        name: 'BSTC#572',
        rank: 'Rank1205',
    },
    {
        url: './2/1055.png',
        name: 'BSTC#1055',
        rank: 'Rank1206',
    },
    {
        url: './2/3038.png',
        name: 'BSTC#3038',
        rank: 'Rank1207',
    },
    {
        url: './2/155.png',
        name: 'BSTC#155',
        rank: 'Rank1208',
    },
    {
        url: './2/1588.png',
        name: 'BSTC#1588',
        rank: 'Rank1209',
    },
    {
        url: './2/1911.png',
        name: 'BSTC#1911',
        rank: 'Rank1210',
    },
    {
        url: './2/2814.png',
        name: 'BSTC#2814',
        rank: 'Rank1211',
    },
    {
        url: './2/75.png',
        name: 'BSTC#75',
        rank: 'Rank1212',
    },
    {
        url: './2/1048.png',
        name: 'BSTC#1048',
        rank: 'Rank1213',
    },
    {
        url: './2/1127.png',
        name: 'BSTC#1127',
        rank: 'Rank1214',
    },
    {
        url: './2/1384.png',
        name: 'BSTC#1384',
        rank: 'Rank1215',
    },
    {
        url: './2/1431.png',
        name: 'BSTC#1431',
        rank: 'Rank1216',
    },
    {
        url: './2/2095.png',
        name: 'BSTC#2095',
        rank: 'Rank1217',
    },
    {
        url: './2/2719.png',
        name: 'BSTC#2719',
        rank: 'Rank1218',
    },
    {
        url: './2/2941.png',
        name: 'BSTC#2941',
        rank: 'Rank1219',
    },
    {
        url: './2/2950.png',
        name: 'BSTC#2950',
        rank: 'Rank1220',
    },
    {
        url: './2/1411.png',
        name: 'BSTC#1411',
        rank: 'Rank1221',
    },
    {
        url: './2/2989.png',
        name: 'BSTC#2989',
        rank: 'Rank1222',
    },
    {
        url: './2/424.png',
        name: 'BSTC#424',
        rank: 'Rank1223',
    },
    {
        url: './2/210.png',
        name: 'BSTC#210',
        rank: 'Rank1224',
    },
    {
        url: './2/447.png',
        name: 'BSTC#447',
        rank: 'Rank1225',
    },
    {
        url: './2/1440.png',
        name: 'BSTC#1440',
        rank: 'Rank1226',
    },
    {
        url: './2/1885.png',
        name: 'BSTC#1885',
        rank: 'Rank1227',
    },
    {
        url: './2/46.png',
        name: 'BSTC#46',
        rank: 'Rank1228',
    },
    {
        url: './2/177.png',
        name: 'BSTC#177',
        rank: 'Rank1229',
    },
    {
        url: './2/492.png',
        name: 'BSTC#492',
        rank: 'Rank1230',
    },
    {
        url: './2/654.png',
        name: 'BSTC#654',
        rank: 'Rank1231',
    },
    {
        url: './2/1130.png',
        name: 'BSTC#1130',
        rank: 'Rank1232',
    },
    {
        url: './2/1284.png',
        name: 'BSTC#1284',
        rank: 'Rank1233',
    },
    {
        url: './2/3126.png',
        name: 'BSTC#3126',
        rank: 'Rank1234',
    },
    {
        url: './2/452.png',
        name: 'BSTC#452',
        rank: 'Rank1235',
    },
    {
        url: './2/636.png',
        name: 'BSTC#636',
        rank: 'Rank1236',
    },
    {
        url: './2/106.png',
        name: 'BSTC#106',
        rank: 'Rank1237',
    },
    {
        url: './2/2559.png',
        name: 'BSTC#2559',
        rank: 'Rank1238',
    },
    {
        url: './2/2813.png',
        name: 'BSTC#2813',
        rank: 'Rank1239',
    },
    {
        url: './2/3105.png',
        name: 'BSTC#3105',
        rank: 'Rank1240',
    },
    {
        url: './2/1418.png',
        name: 'BSTC#1418',
        rank: 'Rank1241',
    },
    {
        url: './2/948.png',
        name: 'BSTC#948',
        rank: 'Rank1242',
    },
    {
        url: './2/1021.png',
        name: 'BSTC#1021',
        rank: 'Rank1243',
    },
    {
        url: './2/3118.png',
        name: 'BSTC#3118',
        rank: 'Rank1244',
    },
    {
        url: './2/922.png',
        name: 'BSTC#922',
        rank: 'Rank1245',
    },
    {
        url: './2/2945.png',
        name: 'BSTC#2945',
        rank: 'Rank1246',
    },
    {
        url: './2/590.png',
        name: 'BSTC#590',
        rank: 'Rank1247',
    },
    {
        url: './2/1637.png',
        name: 'BSTC#1637',
        rank: 'Rank1248',
    },
    {
        url: './2/2274.png',
        name: 'BSTC#2274',
        rank: 'Rank1249',
    },
    {
        url: './2/2017.png',
        name: 'BSTC#2017',
        rank: 'Rank1250',
    },
    {
        url: './2/78.png',
        name: 'BSTC#78',
        rank: 'Rank1251',
    },
    {
        url: './2/538.png',
        name: 'BSTC#538',
        rank: 'Rank1252',
    },
    {
        url: './2/1540.png',
        name: 'BSTC#1540',
        rank: 'Rank1253',
    },
    {
        url: './2/2536.png',
        name: 'BSTC#2536',
        rank: 'Rank1254',
    },
    {
        url: './2/31.png',
        name: 'BSTC#31',
        rank: 'Rank1255',
    },
    {
        url: './2/137.png',
        name: 'BSTC#137',
        rank: 'Rank1256',
    },
    {
        url: './2/315.png',
        name: 'BSTC#315',
        rank: 'Rank1257',
    },
    {
        url: './2/1695.png',
        name: 'BSTC#1695',
        rank: 'Rank1258',
    },
    {
        url: './2/3060.png',
        name: 'BSTC#3060',
        rank: 'Rank1259',
    },
    {
        url: './2/1106.png',
        name: 'BSTC#1106',
        rank: 'Rank1260',
    },
    {
        url: './2/2595.png',
        name: 'BSTC#2595',
        rank: 'Rank1261',
    },
    {
        url: './2/2642.png',
        name: 'BSTC#2642',
        rank: 'Rank1262',
    },
    {
        url: './2/3090.png',
        name: 'BSTC#3090',
        rank: 'Rank1263',
    },
    {
        url: './2/1212.png',
        name: 'BSTC#1212',
        rank: 'Rank1264',
    },
    {
        url: './2/333.png',
        name: 'BSTC#333',
        rank: 'Rank1265',
    },
    {
        url: './2/390.png',
        name: 'BSTC#390',
        rank: 'Rank1266',
    },
    {
        url: './2/1412.png',
        name: 'BSTC#1412',
        rank: 'Rank1267',
    },
    {
        url: './2/297.png',
        name: 'BSTC#297',
        rank: 'Rank1268',
    },
    {
        url: './2/1079.png',
        name: 'BSTC#1079',
        rank: 'Rank1269',
    },
    {
        url: './2/1522.png',
        name: 'BSTC#1522',
        rank: 'Rank1270',
    },
    {
        url: './2/2476.png',
        name: 'BSTC#2476',
        rank: 'Rank1271',
    },
    {
        url: './2/3014.png',
        name: 'BSTC#3014',
        rank: 'Rank1272',
    },
    {
        url: './2/2679.png',
        name: 'BSTC#2679',
        rank: 'Rank1273',
    },
    {
        url: './2/2488.png',
        name: 'BSTC#2488',
        rank: 'Rank1274',
    },
    {
        url: './2/2751.png',
        name: 'BSTC#2751',
        rank: 'Rank1275',
    },
    {
        url: './2/1148.png',
        name: 'BSTC#1148',
        rank: 'Rank1276',
    },
    {
        url: './2/414.png',
        name: 'BSTC#414',
        rank: 'Rank1277',
    },
    {
        url: './2/890.png',
        name: 'BSTC#890',
        rank: 'Rank1278',
    },
    {
        url: './2/1308.png',
        name: 'BSTC#1308',
        rank: 'Rank1279',
    },
    {
        url: './2/732.png',
        name: 'BSTC#732',
        rank: 'Rank1280',
    },
    {
        url: './2/817.png',
        name: 'BSTC#817',
        rank: 'Rank1281',
    },
    {
        url: './2/1970.png',
        name: 'BSTC#1970',
        rank: 'Rank1282',
    },
    {
        url: './2/2904.png',
        name: 'BSTC#2904',
        rank: 'Rank1283',
    },
    {
        url: './2/202.png',
        name: 'BSTC#202',
        rank: 'Rank1284',
    },
    {
        url: './2/2042.png',
        name: 'BSTC#2042',
        rank: 'Rank1285',
    },
    {
        url: './2/3070.png',
        name: 'BSTC#3070',
        rank: 'Rank1286',
    },
    {
        url: './2/1243.png',
        name: 'BSTC#1243',
        rank: 'Rank1287',
    },
    {
        url: './2/2502.png',
        name: 'BSTC#2502',
        rank: 'Rank1288',
    },
    {
        url: './2/2917.png',
        name: 'BSTC#2917',
        rank: 'Rank1289',
    },
    {
        url: './2/40.png',
        name: 'BSTC#40',
        rank: 'Rank1290',
    },
    {
        url: './2/2186.png',
        name: 'BSTC#2186',
        rank: 'Rank1291',
    },
    {
        url: './2/2328.png',
        name: 'BSTC#2328',
        rank: 'Rank1292',
    },
    {
        url: './2/2828.png',
        name: 'BSTC#2828',
        rank: 'Rank1293',
    },
    {
        url: './2/2924.png',
        name: 'BSTC#2924',
        rank: 'Rank1294',
    },
    {
        url: './2/267.png',
        name: 'BSTC#267',
        rank: 'Rank1295',
    },
    {
        url: './2/559.png',
        name: 'BSTC#559',
        rank: 'Rank1296',
    },
    {
        url: './2/1023.png',
        name: 'BSTC#1023',
        rank: 'Rank1297',
    },
    {
        url: './2/3017.png',
        name: 'BSTC#3017',
        rank: 'Rank1298',
    },
    {
        url: './2/2545.png',
        name: 'BSTC#2545',
        rank: 'Rank1299',
    },
    {
        url: './2/2996.png',
        name: 'BSTC#2996',
        rank: 'Rank1300',
    },
    {
        url: './2/1883.png',
        name: 'BSTC#1883',
        rank: 'Rank1301',
    },
    {
        url: './2/1298.png',
        name: 'BSTC#1298',
        rank: 'Rank1302',
    },
    {
        url: './2/731.png',
        name: 'BSTC#731',
        rank: 'Rank1303',
    },
    {
        url: './2/1621.png',
        name: 'BSTC#1621',
        rank: 'Rank1304',
    },
    {
        url: './2/2511.png',
        name: 'BSTC#2511',
        rank: 'Rank1305',
    },
    {
        url: './2/2609.png',
        name: 'BSTC#2609',
        rank: 'Rank1306',
    },
    {
        url: './2/2820.png',
        name: 'BSTC#2820',
        rank: 'Rank1307',
    },
    {
        url: './2/352.png',
        name: 'BSTC#352',
        rank: 'Rank1308',
    },
    {
        url: './2/362.png',
        name: 'BSTC#362',
        rank: 'Rank1309',
    },
    {
        url: './2/1099.png',
        name: 'BSTC#1099',
        rank: 'Rank1310',
    },
    {
        url: './2/1884.png',
        name: 'BSTC#1884',
        rank: 'Rank1311',
    },
    {
        url: './2/2852.png',
        name: 'BSTC#2852',
        rank: 'Rank1312',
    },
    {
        url: './2/48.png',
        name: 'BSTC#48',
        rank: 'Rank1313',
    },
    {
        url: './2/938.png',
        name: 'BSTC#938',
        rank: 'Rank1314',
    },
    {
        url: './2/262.png',
        name: 'BSTC#262',
        rank: 'Rank1315',
    },
    {
        url: './2/1935.png',
        name: 'BSTC#1935',
        rank: 'Rank1316',
    },
    {
        url: './2/3116.png',
        name: 'BSTC#3116',
        rank: 'Rank1317',
    },
    {
        url: './2/693.png',
        name: 'BSTC#693',
        rank: 'Rank1318',
    },
    {
        url: './2/602.png',
        name: 'BSTC#602',
        rank: 'Rank1319',
    },
    {
        url: './2/1646.png',
        name: 'BSTC#1646',
        rank: 'Rank1320',
    },
    {
        url: './2/1986.png',
        name: 'BSTC#1986',
        rank: 'Rank1321',
    },
    {
        url: './2/224.png',
        name: 'BSTC#224',
        rank: 'Rank1322',
    },
    {
        url: './2/2664.png',
        name: 'BSTC#2664',
        rank: 'Rank1323',
    },
    {
        url: './2/1095.png',
        name: 'BSTC#1095',
        rank: 'Rank1324',
    },
    {
        url: './2/621.png',
        name: 'BSTC#621',
        rank: 'Rank1325',
    },
    {
        url: './2/570.png',
        name: 'BSTC#570',
        rank: 'Rank1326',
    },
    {
        url: './2/2368.png',
        name: 'BSTC#2368',
        rank: 'Rank1327',
    },
    {
        url: './2/2411.png',
        name: 'BSTC#2411',
        rank: 'Rank1328',
    },
    {
        url: './2/2442.png',
        name: 'BSTC#2442',
        rank: 'Rank1329',
    },
    {
        url: './2/375.png',
        name: 'BSTC#375',
        rank: 'Rank1330',
    },
    {
        url: './2/1129.png',
        name: 'BSTC#1129',
        rank: 'Rank1331',
    },
    {
        url: './2/1325.png',
        name: 'BSTC#1325',
        rank: 'Rank1332',
    },
    {
        url: './2/2002.png',
        name: 'BSTC#2002',
        rank: 'Rank1333',
    },
    {
        url: './2/2168.png',
        name: 'BSTC#2168',
        rank: 'Rank1334',
    },
    {
        url: './2/2236.png',
        name: 'BSTC#2236',
        rank: 'Rank1335',
    },
    {
        url: './2/2538.png',
        name: 'BSTC#2538',
        rank: 'Rank1336',
    },
    {
        url: './2/2988.png',
        name: 'BSTC#2988',
        rank: 'Rank1337',
    },
    {
        url: './2/0.png',
        name: 'BSTC#0',
        rank: 'Rank1338',
    },
    {
        url: './2/1656.png',
        name: 'BSTC#1656',
        rank: 'Rank1339',
    },
    {
        url: './2/2304.png',
        name: 'BSTC#2304',
        rank: 'Rank1340',
    },
    {
        url: './2/189.png',
        name: 'BSTC#189',
        rank: 'Rank1341',
    },
    {
        url: './2/821.png',
        name: 'BSTC#821',
        rank: 'Rank1342',
    },
    {
        url: './2/2381.png',
        name: 'BSTC#2381',
        rank: 'Rank1343',
    },
    {
        url: './2/237.png',
        name: 'BSTC#237',
        rank: 'Rank1344',
    },
    {
        url: './2/1439.png',
        name: 'BSTC#1439',
        rank: 'Rank1345',
    },
    {
        url: './2/2872.png',
        name: 'BSTC#2872',
        rank: 'Rank1346',
    },
    {
        url: './2/2378.png',
        name: 'BSTC#2378',
        rank: 'Rank1347',
    },
    {
        url: './2/1948.png',
        name: 'BSTC#1948',
        rank: 'Rank1348',
    },
    {
        url: './2/931.png',
        name: 'BSTC#931',
        rank: 'Rank1349',
    },
    {
        url: './2/111.png',
        name: 'BSTC#111',
        rank: 'Rank1350',
    },
    {
        url: './2/1186.png',
        name: 'BSTC#1186',
        rank: 'Rank1351',
    },
    {
        url: './2/2974.png',
        name: 'BSTC#2974',
        rank: 'Rank1352',
    },
    {
        url: './2/2943.png',
        name: 'BSTC#2943',
        rank: 'Rank1353',
    },
    {
        url: './2/1222.png',
        name: 'BSTC#1222',
        rank: 'Rank1354',
    },
    {
        url: './2/422.png',
        name: 'BSTC#422',
        rank: 'Rank1355',
    },
    {
        url: './2/1957.png',
        name: 'BSTC#1957',
        rank: 'Rank1356',
    },
    {
        url: './2/2671.png',
        name: 'BSTC#2671',
        rank: 'Rank1357',
    },
    {
        url: './2/1592.png',
        name: 'BSTC#1592',
        rank: 'Rank1358',
    },
    {
        url: './2/263.png',
        name: 'BSTC#263',
        rank: 'Rank1359',
    },
    {
        url: './2/3095.png',
        name: 'BSTC#3095',
        rank: 'Rank1360',
    },
    {
        url: './2/1536.png',
        name: 'BSTC#1536',
        rank: 'Rank1361',
    },
    {
        url: './2/1732.png',
        name: 'BSTC#1732',
        rank: 'Rank1362',
    },
    {
        url: './2/2133.png',
        name: 'BSTC#2133',
        rank: 'Rank1363',
    },
    {
        url: './2/243.png',
        name: 'BSTC#243',
        rank: 'Rank1364',
    },
    {
        url: './2/1226.png',
        name: 'BSTC#1226',
        rank: 'Rank1365',
    },
    {
        url: './2/218.png',
        name: 'BSTC#218',
        rank: 'Rank1366',
    },
    {
        url: './2/242.png',
        name: 'BSTC#242',
        rank: 'Rank1367',
    },
    {
        url: './2/766.png',
        name: 'BSTC#766',
        rank: 'Rank1368',
    },
    {
        url: './2/1200.png',
        name: 'BSTC#1200',
        rank: 'Rank1369',
    },
    {
        url: './2/2444.png',
        name: 'BSTC#2444',
        rank: 'Rank1370',
    },
    {
        url: './2/2774.png',
        name: 'BSTC#2774',
        rank: 'Rank1371',
    },
    {
        url: './2/2697.png',
        name: 'BSTC#2697',
        rank: 'Rank1372',
    },
    {
        url: './2/2494.png',
        name: 'BSTC#2494',
        rank: 'Rank1373',
    },
    {
        url: './2/329.png',
        name: 'BSTC#329',
        rank: 'Rank1374',
    },
    {
        url: './2/2140.png',
        name: 'BSTC#2140',
        rank: 'Rank1375',
    },
    {
        url: './2/21.png',
        name: 'BSTC#21',
        rank: 'Rank1376',
    },
    {
        url: './2/2032.png',
        name: 'BSTC#2032',
        rank: 'Rank1377',
    },
    {
        url: './2/2567.png',
        name: 'BSTC#2567',
        rank: 'Rank1378',
    },
    {
        url: './2/2223.png',
        name: 'BSTC#2223',
        rank: 'Rank1379',
    },
    {
        url: './2/2431.png',
        name: 'BSTC#2431',
        rank: 'Rank1380',
    },
    {
        url: './2/37.png',
        name: 'BSTC#37',
        rank: 'Rank1381',
    },
    {
        url: './2/709.png',
        name: 'BSTC#709',
        rank: 'Rank1382',
    },
    {
        url: './2/1177.png',
        name: 'BSTC#1177',
        rank: 'Rank1383',
    },
    {
        url: './2/1682.png',
        name: 'BSTC#1682',
        rank: 'Rank1384',
    },
    {
        url: './2/2053.png',
        name: 'BSTC#2053',
        rank: 'Rank1385',
    },
    {
        url: './2/2059.png',
        name: 'BSTC#2059',
        rank: 'Rank1386',
    },
    {
        url: './2/2123.png',
        name: 'BSTC#2123',
        rank: 'Rank1387',
    },
    {
        url: './2/2331.png',
        name: 'BSTC#2331',
        rank: 'Rank1388',
    },
    {
        url: './2/2640.png',
        name: 'BSTC#2640',
        rank: 'Rank1389',
    },
    {
        url: './2/3134.png',
        name: 'BSTC#3134',
        rank: 'Rank1390',
    },
    {
        url: './2/2377.png',
        name: 'BSTC#2377',
        rank: 'Rank1391',
    },
    {
        url: './2/800.png',
        name: 'BSTC#800',
        rank: 'Rank1392',
    },
    {
        url: './2/1124.png',
        name: 'BSTC#1124',
        rank: 'Rank1393',
    },
    {
        url: './2/2682.png',
        name: 'BSTC#2682',
        rank: 'Rank1394',
    },
    {
        url: './2/751.png',
        name: 'BSTC#751',
        rank: 'Rank1395',
    },
    {
        url: './2/1475.png',
        name: 'BSTC#1475',
        rank: 'Rank1396',
    },
    {
        url: './2/1779.png',
        name: 'BSTC#1779',
        rank: 'Rank1397',
    },
    {
        url: './2/2323.png',
        name: 'BSTC#2323',
        rank: 'Rank1398',
    },
    {
        url: './2/1201.png',
        name: 'BSTC#1201',
        rank: 'Rank1399',
    },
    {
        url: './2/2496.png',
        name: 'BSTC#2496',
        rank: 'Rank1400',
    },
    {
        url: './2/2527.png',
        name: 'BSTC#2527',
        rank: 'Rank1401',
    },
    {
        url: './2/1831.png',
        name: 'BSTC#1831',
        rank: 'Rank1402',
    },
    {
        url: './2/640.png',
        name: 'BSTC#640',
        rank: 'Rank1403',
    },
    {
        url: './2/2389.png',
        name: 'BSTC#2389',
        rank: 'Rank1404',
    },
    {
        url: './2/1669.png',
        name: 'BSTC#1669',
        rank: 'Rank1405',
    },
    {
        url: './2/2031.png',
        name: 'BSTC#2031',
        rank: 'Rank1406',
    },
    {
        url: './2/310.png',
        name: 'BSTC#310',
        rank: 'Rank1407',
    },
    {
        url: './2/1190.png',
        name: 'BSTC#1190',
        rank: 'Rank1408',
    },
    {
        url: './2/1554.png',
        name: 'BSTC#1554',
        rank: 'Rank1409',
    },
    {
        url: './2/1860.png',
        name: 'BSTC#1860',
        rank: 'Rank1410',
    },
    {
        url: './2/1863.png',
        name: 'BSTC#1863',
        rank: 'Rank1411',
    },
    {
        url: './2/679.png',
        name: 'BSTC#679',
        rank: 'Rank1412',
    },
    {
        url: './2/2028.png',
        name: 'BSTC#2028',
        rank: 'Rank1413',
    },
    {
        url: './2/719.png',
        name: 'BSTC#719',
        rank: 'Rank1414',
    },
    {
        url: './2/1533.png',
        name: 'BSTC#1533',
        rank: 'Rank1415',
    },
    {
        url: './2/443.png',
        name: 'BSTC#443',
        rank: 'Rank1416',
    },
    {
        url: './2/284.png',
        name: 'BSTC#284',
        rank: 'Rank1417',
    },
    {
        url: './2/2491.png',
        name: 'BSTC#2491',
        rank: 'Rank1418',
    },
    {
        url: './2/3077.png',
        name: 'BSTC#3077',
        rank: 'Rank1419',
    },
    {
        url: './2/1623.png',
        name: 'BSTC#1623',
        rank: 'Rank1420',
    },
    {
        url: './2/2793.png',
        name: 'BSTC#2793',
        rank: 'Rank1421',
    },
    {
        url: './2/208.png',
        name: 'BSTC#208',
        rank: 'Rank1422',
    },
    {
        url: './2/140.png',
        name: 'BSTC#140',
        rank: 'Rank1423',
    },
    {
        url: './2/1765.png',
        name: 'BSTC#1765',
        rank: 'Rank1424',
    },
    {
        url: './2/382.png',
        name: 'BSTC#382',
        rank: 'Rank1425',
    },
    {
        url: './2/1818.png',
        name: 'BSTC#1818',
        rank: 'Rank1426',
    },
    {
        url: './2/1918.png',
        name: 'BSTC#1918',
        rank: 'Rank1427',
    },
    {
        url: './2/1647.png',
        name: 'BSTC#1647',
        rank: 'Rank1428',
    },
    {
        url: './2/1008.png',
        name: 'BSTC#1008',
        rank: 'Rank1429',
    },
    {
        url: './2/3084.png',
        name: 'BSTC#3084',
        rank: 'Rank1430',
    },
    {
        url: './2/393.png',
        name: 'BSTC#393',
        rank: 'Rank1431',
    },
    {
        url: './2/856.png',
        name: 'BSTC#856',
        rank: 'Rank1432',
    },
    {
        url: './2/1581.png',
        name: 'BSTC#1581',
        rank: 'Rank1433',
    },
    {
        url: './2/1771.png',
        name: 'BSTC#1771',
        rank: 'Rank1434',
    },
    {
        url: './2/2025.png',
        name: 'BSTC#2025',
        rank: 'Rank1435',
    },
    {
        url: './2/2056.png',
        name: 'BSTC#2056',
        rank: 'Rank1436',
    },
    {
        url: './2/2338.png',
        name: 'BSTC#2338',
        rank: 'Rank1437',
    },
    {
        url: './2/2487.png',
        name: 'BSTC#2487',
        rank: 'Rank1438',
    },
    {
        url: './2/666.png',
        name: 'BSTC#666',
        rank: 'Rank1439',
    },
    {
        url: './2/2975.png',
        name: 'BSTC#2975',
        rank: 'Rank1440',
    },
    {
        url: './2/2627.png',
        name: 'BSTC#2627',
        rank: 'Rank1441',
    },
    {
        url: './2/1677.png',
        name: 'BSTC#1677',
        rank: 'Rank1442',
    },
    {
        url: './2/2768.png',
        name: 'BSTC#2768',
        rank: 'Rank1443',
    },
    {
        url: './2/2706.png',
        name: 'BSTC#2706',
        rank: 'Rank1444',
    },
    {
        url: './2/2332.png',
        name: 'BSTC#2332',
        rank: 'Rank1445',
    },
    {
        url: './2/2903.png',
        name: 'BSTC#2903',
        rank: 'Rank1446',
    },
    {
        url: './2/1598.png',
        name: 'BSTC#1598',
        rank: 'Rank1447',
    },
    {
        url: './2/2214.png',
        name: 'BSTC#2214',
        rank: 'Rank1448',
    },
    {
        url: './2/2741.png',
        name: 'BSTC#2741',
        rank: 'Rank1449',
    },
    {
        url: './2/70.png',
        name: 'BSTC#70',
        rank: 'Rank1450',
    },
    {
        url: './2/418.png',
        name: 'BSTC#418',
        rank: 'Rank1451',
    },
    {
        url: './2/1889.png',
        name: 'BSTC#1889',
        rank: 'Rank1452',
    },
    {
        url: './2/2574.png',
        name: 'BSTC#2574',
        rank: 'Rank1453',
    },
    {
        url: './2/1071.png',
        name: 'BSTC#1071',
        rank: 'Rank1454',
    },
    {
        url: './2/1904.png',
        name: 'BSTC#1904',
        rank: 'Rank1455',
    },
    {
        url: './2/1661.png',
        name: 'BSTC#1661',
        rank: 'Rank1456',
    },
    {
        url: './2/2086.png',
        name: 'BSTC#2086',
        rank: 'Rank1457',
    },
    {
        url: './2/2117.png',
        name: 'BSTC#2117',
        rank: 'Rank1458',
    },
    {
        url: './2/1104.png',
        name: 'BSTC#1104',
        rank: 'Rank1459',
    },
    {
        url: './2/1851.png',
        name: 'BSTC#1851',
        rank: 'Rank1460',
    },
    {
        url: './2/1938.png',
        name: 'BSTC#1938',
        rank: 'Rank1461',
    },
    {
        url: './2/181.png',
        name: 'BSTC#181',
        rank: 'Rank1462',
    },
    {
        url: './2/407.png',
        name: 'BSTC#407',
        rank: 'Rank1463',
    },
    {
        url: './2/1280.png',
        name: 'BSTC#1280',
        rank: 'Rank1464',
    },
    {
        url: './2/1809.png',
        name: 'BSTC#1809',
        rank: 'Rank1465',
    },
    {
        url: './2/696.png',
        name: 'BSTC#696',
        rank: 'Rank1466',
    },
    {
        url: './2/1011.png',
        name: 'BSTC#1011',
        rank: 'Rank1467',
    },
    {
        url: './2/1978.png',
        name: 'BSTC#1978',
        rank: 'Rank1468',
    },
    {
        url: './2/2069.png',
        name: 'BSTC#2069',
        rank: 'Rank1469',
    },
    {
        url: './2/14.png',
        name: 'BSTC#14',
        rank: 'Rank1470',
    },
    {
        url: './2/374.png',
        name: 'BSTC#374',
        rank: 'Rank1471',
    },
    {
        url: './2/1974.png',
        name: 'BSTC#1974',
        rank: 'Rank1472',
    },
    {
        url: './2/1260.png',
        name: 'BSTC#1260',
        rank: 'Rank1473',
    },
    {
        url: './2/1674.png',
        name: 'BSTC#1674',
        rank: 'Rank1474',
    },
    {
        url: './2/1856.png',
        name: 'BSTC#1856',
        rank: 'Rank1475',
    },
    {
        url: './2/1947.png',
        name: 'BSTC#1947',
        rank: 'Rank1476',
    },
    {
        url: './2/2005.png',
        name: 'BSTC#2005',
        rank: 'Rank1477',
    },
    {
        url: './2/2708.png',
        name: 'BSTC#2708',
        rank: 'Rank1478',
    },
    {
        url: './2/2747.png',
        name: 'BSTC#2747',
        rank: 'Rank1479',
    },
    {
        url: './2/2822.png',
        name: 'BSTC#2822',
        rank: 'Rank1480',
    },
    {
        url: './2/1285.png',
        name: 'BSTC#1285',
        rank: 'Rank1481',
    },
    {
        url: './2/3041.png',
        name: 'BSTC#3041',
        rank: 'Rank1482',
    },
    {
        url: './2/2685.png',
        name: 'BSTC#2685',
        rank: 'Rank1483',
    },
    {
        url: './2/344.png',
        name: 'BSTC#344',
        rank: 'Rank1484',
    },
    {
        url: './2/128.png',
        name: 'BSTC#128',
        rank: 'Rank1485',
    },
    {
        url: './2/2200.png',
        name: 'BSTC#2200',
        rank: 'Rank1486',
    },
    {
        url: './2/148.png',
        name: 'BSTC#148',
        rank: 'Rank1487',
    },
    {
        url: './2/2555.png',
        name: 'BSTC#2555',
        rank: 'Rank1488',
    },
    {
        url: './2/573.png',
        name: 'BSTC#573',
        rank: 'Rank1489',
    },
    {
        url: './2/183.png',
        name: 'BSTC#183',
        rank: 'Rank1490',
    },
    {
        url: './2/633.png',
        name: 'BSTC#633',
        rank: 'Rank1491',
    },
    {
        url: './2/964.png',
        name: 'BSTC#964',
        rank: 'Rank1492',
    },
    {
        url: './2/3057.png',
        name: 'BSTC#3057',
        rank: 'Rank1493',
    },
    {
        url: './2/2690.png',
        name: 'BSTC#2690',
        rank: 'Rank1494',
    },
    {
        url: './2/566.png',
        name: 'BSTC#566',
        rank: 'Rank1495',
    },
    {
        url: './2/1982.png',
        name: 'BSTC#1982',
        rank: 'Rank1496',
    },
    {
        url: './2/2928.png',
        name: 'BSTC#2928',
        rank: 'Rank1497',
    },
    {
        url: './2/1528.png',
        name: 'BSTC#1528',
        rank: 'Rank1498',
    },
    {
        url: './2/2432.png',
        name: 'BSTC#2432',
        rank: 'Rank1499',
    },
    {
        url: './2/159.png',
        name: 'BSTC#159',
        rank: 'Rank1500',
    },
    {
        url: './2/801.png',
        name: 'BSTC#801',
        rank: 'Rank1501',
    },
    {
        url: './2/643.png',
        name: 'BSTC#643',
        rank: 'Rank1502',
    },
    {
        url: './2/2713.png',
        name: 'BSTC#2713',
        rank: 'Rank1503',
    },
    {
        url: './2/35.png',
        name: 'BSTC#35',
        rank: 'Rank1504',
    },
    {
        url: './2/592.png',
        name: 'BSTC#592',
        rank: 'Rank1505',
    },
    {
        url: './2/597.png',
        name: 'BSTC#597',
        rank: 'Rank1506',
    },
    {
        url: './2/3001.png',
        name: 'BSTC#3001',
        rank: 'Rank1507',
    },
    {
        url: './2/510.png',
        name: 'BSTC#510',
        rank: 'Rank1508',
    },
    {
        url: './2/669.png',
        name: 'BSTC#669',
        rank: 'Rank1509',
    },
    {
        url: './2/2977.png',
        name: 'BSTC#2977',
        rank: 'Rank1510',
    },
    {
        url: './2/276.png',
        name: 'BSTC#276',
        rank: 'Rank1511',
    },
    {
        url: './2/584.png',
        name: 'BSTC#584',
        rank: 'Rank1512',
    },
    {
        url: './2/720.png',
        name: 'BSTC#720',
        rank: 'Rank1513',
    },
    {
        url: './2/1786.png',
        name: 'BSTC#1786',
        rank: 'Rank1514',
    },
    {
        url: './2/2253.png',
        name: 'BSTC#2253',
        rank: 'Rank1515',
    },
    {
        url: './2/885.png',
        name: 'BSTC#885',
        rank: 'Rank1516',
    },
    {
        url: './2/1156.png',
        name: 'BSTC#1156',
        rank: 'Rank1517',
    },
    {
        url: './2/2275.png',
        name: 'BSTC#2275',
        rank: 'Rank1518',
    },
    {
        url: './2/2635.png',
        name: 'BSTC#2635',
        rank: 'Rank1519',
    },
    {
        url: './2/359.png',
        name: 'BSTC#359',
        rank: 'Rank1520',
    },
    {
        url: './2/16.png',
        name: 'BSTC#16',
        rank: 'Rank1521',
    },
    {
        url: './2/124.png',
        name: 'BSTC#124',
        rank: 'Rank1522',
    },
    {
        url: './2/768.png',
        name: 'BSTC#768',
        rank: 'Rank1523',
    },
    {
        url: './2/256.png',
        name: 'BSTC#256',
        rank: 'Rank1524',
    },
    {
        url: './2/557.png',
        name: 'BSTC#557',
        rank: 'Rank1525',
    },
    {
        url: './2/650.png',
        name: 'BSTC#650',
        rank: 'Rank1526',
    },
    {
        url: './2/705.png',
        name: 'BSTC#705',
        rank: 'Rank1527',
    },
    {
        url: './2/724.png',
        name: 'BSTC#724',
        rank: 'Rank1528',
    },
    {
        url: './2/1031.png',
        name: 'BSTC#1031',
        rank: 'Rank1529',
    },
    {
        url: './2/1277.png',
        name: 'BSTC#1277',
        rank: 'Rank1530',
    },
    {
        url: './2/1480.png',
        name: 'BSTC#1480',
        rank: 'Rank1531',
    },
    {
        url: './2/2268.png',
        name: 'BSTC#2268',
        rank: 'Rank1532',
    },
    {
        url: './2/2350.png',
        name: 'BSTC#2350',
        rank: 'Rank1533',
    },
    {
        url: './2/2622.png',
        name: 'BSTC#2622',
        rank: 'Rank1534',
    },
    {
        url: './2/2626.png',
        name: 'BSTC#2626',
        rank: 'Rank1535',
    },
    {
        url: './2/2722.png',
        name: 'BSTC#2722',
        rank: 'Rank1536',
    },
    {
        url: './2/3103.png',
        name: 'BSTC#3103',
        rank: 'Rank1537',
    },
    {
        url: './2/1087.png',
        name: 'BSTC#1087',
        rank: 'Rank1538',
    },
    {
        url: './2/2647.png',
        name: 'BSTC#2647',
        rank: 'Rank1539',
    },
    {
        url: './2/413.png',
        name: 'BSTC#413',
        rank: 'Rank1540',
    },
    {
        url: './2/2261.png',
        name: 'BSTC#2261',
        rank: 'Rank1541',
    },
    {
        url: './2/2811.png',
        name: 'BSTC#2811',
        rank: 'Rank1542',
    },
    {
        url: './2/1344.png',
        name: 'BSTC#1344',
        rank: 'Rank1543',
    },
    {
        url: './2/2412.png',
        name: 'BSTC#2412',
        rank: 'Rank1544',
    },
    {
        url: './2/2390.png',
        name: 'BSTC#2390',
        rank: 'Rank1545',
    },
    {
        url: './2/258.png',
        name: 'BSTC#258',
        rank: 'Rank1546',
    },
    {
        url: './2/1542.png',
        name: 'BSTC#1542',
        rank: 'Rank1547',
    },
    {
        url: './2/661.png',
        name: 'BSTC#661',
        rank: 'Rank1548',
    },
    {
        url: './2/496.png',
        name: 'BSTC#496',
        rank: 'Rank1549',
    },
    {
        url: './2/2603.png',
        name: 'BSTC#2603',
        rank: 'Rank1550',
    },
    {
        url: './2/1636.png',
        name: 'BSTC#1636',
        rank: 'Rank1551',
    },
    {
        url: './2/652.png',
        name: 'BSTC#652',
        rank: 'Rank1552',
    },
    {
        url: './2/2728.png',
        name: 'BSTC#2728',
        rank: 'Rank1553',
    },
    {
        url: './2/1420.png',
        name: 'BSTC#1420',
        rank: 'Rank1554',
    },
    {
        url: './2/672.png',
        name: 'BSTC#672',
        rank: 'Rank1555',
    },
    {
        url: './2/1034.png',
        name: 'BSTC#1034',
        rank: 'Rank1556',
    },
    {
        url: './2/737.png',
        name: 'BSTC#737',
        rank: 'Rank1557',
    },
    {
        url: './2/1616.png',
        name: 'BSTC#1616',
        rank: 'Rank1558',
    },
    {
        url: './2/2132.png',
        name: 'BSTC#2132',
        rank: 'Rank1559',
    },
    {
        url: './2/2149.png',
        name: 'BSTC#2149',
        rank: 'Rank1560',
    },
    {
        url: './2/2688.png',
        name: 'BSTC#2688',
        rank: 'Rank1561',
    },
    {
        url: './2/2701.png',
        name: 'BSTC#2701',
        rank: 'Rank1562',
    },
    {
        url: './2/611.png',
        name: 'BSTC#611',
        rank: 'Rank1563',
    },
    {
        url: './2/529.png',
        name: 'BSTC#529',
        rank: 'Rank1564',
    },
    {
        url: './2/1310.png',
        name: 'BSTC#1310',
        rank: 'Rank1565',
    },
    {
        url: './2/1630.png',
        name: 'BSTC#1630',
        rank: 'Rank1566',
    },
    {
        url: './2/2142.png',
        name: 'BSTC#2142',
        rank: 'Rank1567',
    },
    {
        url: './2/2169.png',
        name: 'BSTC#2169',
        rank: 'Rank1568',
    },
    {
        url: './2/2404.png',
        name: 'BSTC#2404',
        rank: 'Rank1569',
    },
    {
        url: './2/771.png',
        name: 'BSTC#771',
        rank: 'Rank1570',
    },
    {
        url: './2/1189.png',
        name: 'BSTC#1189',
        rank: 'Rank1571',
    },
    {
        url: './2/1509.png',
        name: 'BSTC#1509',
        rank: 'Rank1572',
    },
    {
        url: './2/1823.png',
        name: 'BSTC#1823',
        rank: 'Rank1573',
    },
    {
        url: './2/1994.png',
        name: 'BSTC#1994',
        rank: 'Rank1574',
    },
    {
        url: './2/2435.png',
        name: 'BSTC#2435',
        rank: 'Rank1575',
    },
    {
        url: './2/2523.png',
        name: 'BSTC#2523',
        rank: 'Rank1576',
    },
    {
        url: './2/2661.png',
        name: 'BSTC#2661',
        rank: 'Rank1577',
    },
    {
        url: './2/2896.png',
        name: 'BSTC#2896',
        rank: 'Rank1578',
    },
    {
        url: './2/2991.png',
        name: 'BSTC#2991',
        rank: 'Rank1579',
    },
    {
        url: './2/3130.png',
        name: 'BSTC#3130',
        rank: 'Rank1580',
    },
    {
        url: './2/254.png',
        name: 'BSTC#254',
        rank: 'Rank1581',
    },
    {
        url: './2/554.png',
        name: 'BSTC#554',
        rank: 'Rank1582',
    },
    {
        url: './2/2614.png',
        name: 'BSTC#2614',
        rank: 'Rank1583',
    },
    {
        url: './2/357.png',
        name: 'BSTC#357',
        rank: 'Rank1584',
    },
    {
        url: './2/1286.png',
        name: 'BSTC#1286',
        rank: 'Rank1585',
    },
    {
        url: './2/2964.png',
        name: 'BSTC#2964',
        rank: 'Rank1586',
    },
    {
        url: './2/3099.png',
        name: 'BSTC#3099',
        rank: 'Rank1587',
    },
    {
        url: './2/72.png',
        name: 'BSTC#72',
        rank: 'Rank1588',
    },
    {
        url: './2/2652.png',
        name: 'BSTC#2652',
        rank: 'Rank1589',
    },
    {
        url: './2/1296.png',
        name: 'BSTC#1296',
        rank: 'Rank1590',
    },
    {
        url: './2/725.png',
        name: 'BSTC#725',
        rank: 'Rank1591',
    },
    {
        url: './2/1615.png',
        name: 'BSTC#1615',
        rank: 'Rank1592',
    },
    {
        url: './2/68.png',
        name: 'BSTC#68',
        rank: 'Rank1593',
    },
    {
        url: './2/2588.png',
        name: 'BSTC#2588',
        rank: 'Rank1594',
    },
    {
        url: './2/955.png',
        name: 'BSTC#955',
        rank: 'Rank1595',
    },
    {
        url: './2/619.png',
        name: 'BSTC#619',
        rank: 'Rank1596',
    },
    {
        url: './2/708.png',
        name: 'BSTC#708',
        rank: 'Rank1597',
    },
    {
        url: './2/1161.png',
        name: 'BSTC#1161',
        rank: 'Rank1598',
    },
    {
        url: './2/1976.png',
        name: 'BSTC#1976',
        rank: 'Rank1599',
    },
    {
        url: './2/2125.png',
        name: 'BSTC#2125',
        rank: 'Rank1600',
    },
    {
        url: './2/644.png',
        name: 'BSTC#644',
        rank: 'Rank1601',
    },
    {
        url: './2/916.png',
        name: 'BSTC#916',
        rank: 'Rank1602',
    },
    {
        url: './2/2986.png',
        name: 'BSTC#2986',
        rank: 'Rank1603',
    },
    {
        url: './2/353.png',
        name: 'BSTC#353',
        rank: 'Rank1604',
    },
    {
        url: './2/355.png',
        name: 'BSTC#355',
        rank: 'Rank1605',
    },
    {
        url: './2/1709.png',
        name: 'BSTC#1709',
        rank: 'Rank1606',
    },
    {
        url: './2/383.png',
        name: 'BSTC#383',
        rank: 'Rank1607',
    },
    {
        url: './2/1278.png',
        name: 'BSTC#1278',
        rank: 'Rank1608',
    },
    {
        url: './2/1389.png',
        name: 'BSTC#1389',
        rank: 'Rank1609',
    },
    {
        url: './2/2933.png',
        name: 'BSTC#2933',
        rank: 'Rank1610',
    },
    {
        url: './2/1983.png',
        name: 'BSTC#1983',
        rank: 'Rank1611',
    },
    {
        url: './2/223.png',
        name: 'BSTC#223',
        rank: 'Rank1612',
    },
    {
        url: './2/552.png',
        name: 'BSTC#552',
        rank: 'Rank1613',
    },
    {
        url: './2/577.png',
        name: 'BSTC#577',
        rank: 'Rank1614',
    },
    {
        url: './2/1968.png',
        name: 'BSTC#1968',
        rank: 'Rank1615',
    },
    {
        url: './2/2226.png',
        name: 'BSTC#2226',
        rank: 'Rank1616',
    },
    {
        url: './2/3033.png',
        name: 'BSTC#3033',
        rank: 'Rank1617',
    },
    {
        url: './2/834.png',
        name: 'BSTC#834',
        rank: 'Rank1618',
    },
    {
        url: './2/1246.png',
        name: 'BSTC#1246',
        rank: 'Rank1619',
    },
    {
        url: './2/2365.png',
        name: 'BSTC#2365',
        rank: 'Rank1620',
    },
    {
        url: './2/71.png',
        name: 'BSTC#71',
        rank: 'Rank1621',
    },
    {
        url: './2/1347.png',
        name: 'BSTC#1347',
        rank: 'Rank1622',
    },
    {
        url: './2/992.png',
        name: 'BSTC#992',
        rank: 'Rank1623',
    },
    {
        url: './2/1706.png',
        name: 'BSTC#1706',
        rank: 'Rank1624',
    },
    {
        url: './2/1038.png',
        name: 'BSTC#1038',
        rank: 'Rank1625',
    },
    {
        url: './2/535.png',
        name: 'BSTC#535',
        rank: 'Rank1626',
    },
    {
        url: './2/3047.png',
        name: 'BSTC#3047',
        rank: 'Rank1627',
    },
    {
        url: './2/2987.png',
        name: 'BSTC#2987',
        rank: 'Rank1628',
    },
    {
        url: './2/848.png',
        name: 'BSTC#848',
        rank: 'Rank1629',
    },
    {
        url: './2/1582.png',
        name: 'BSTC#1582',
        rank: 'Rank1630',
    },
    {
        url: './2/2083.png',
        name: 'BSTC#2083',
        rank: 'Rank1631',
    },
    {
        url: './2/945.png',
        name: 'BSTC#945',
        rank: 'Rank1632',
    },
    {
        url: './2/1461.png',
        name: 'BSTC#1461',
        rank: 'Rank1633',
    },
    {
        url: './2/2197.png',
        name: 'BSTC#2197',
        rank: 'Rank1634',
    },
    {
        url: './2/3031.png',
        name: 'BSTC#3031',
        rank: 'Rank1635',
    },
    {
        url: './2/583.png',
        name: 'BSTC#583',
        rank: 'Rank1636',
    },
    {
        url: './2/1704.png',
        name: 'BSTC#1704',
        rank: 'Rank1637',
    },
    {
        url: './2/1622.png',
        name: 'BSTC#1622',
        rank: 'Rank1638',
    },
    {
        url: './2/1940.png',
        name: 'BSTC#1940',
        rank: 'Rank1639',
    },
    {
        url: './2/2537.png',
        name: 'BSTC#2537',
        rank: 'Rank1640',
    },
    {
        url: './2/2776.png',
        name: 'BSTC#2776',
        rank: 'Rank1641',
    },
    {
        url: './2/143.png',
        name: 'BSTC#143',
        rank: 'Rank1642',
    },
    {
        url: './2/342.png',
        name: 'BSTC#342',
        rank: 'Rank1643',
    },
    {
        url: './2/668.png',
        name: 'BSTC#668',
        rank: 'Rank1644',
    },
    {
        url: './2/746.png',
        name: 'BSTC#746',
        rank: 'Rank1645',
    },
    {
        url: './2/1073.png',
        name: 'BSTC#1073',
        rank: 'Rank1646',
    },
    {
        url: './2/1215.png',
        name: 'BSTC#1215',
        rank: 'Rank1647',
    },
    {
        url: './2/1268.png',
        name: 'BSTC#1268',
        rank: 'Rank1648',
    },
    {
        url: './2/1660.png',
        name: 'BSTC#1660',
        rank: 'Rank1649',
    },
    {
        url: './2/2410.png',
        name: 'BSTC#2410',
        rank: 'Rank1650',
    },
    {
        url: './2/3012.png',
        name: 'BSTC#3012',
        rank: 'Rank1651',
    },
    {
        url: './2/23.png',
        name: 'BSTC#23',
        rank: 'Rank1652',
    },
    {
        url: './2/1292.png',
        name: 'BSTC#1292',
        rank: 'Rank1653',
    },
    {
        url: './2/380.png',
        name: 'BSTC#380',
        rank: 'Rank1654',
    },
    {
        url: './2/1028.png',
        name: 'BSTC#1028',
        rank: 'Rank1655',
    },
    {
        url: './2/2238.png',
        name: 'BSTC#2238',
        rank: 'Rank1656',
    },
    {
        url: './2/2650.png',
        name: 'BSTC#2650',
        rank: 'Rank1657',
    },
    {
        url: './2/173.png',
        name: 'BSTC#173',
        rank: 'Rank1658',
    },
    {
        url: './2/864.png',
        name: 'BSTC#864',
        rank: 'Rank1659',
    },
    {
        url: './2/2512.png',
        name: 'BSTC#2512',
        rank: 'Rank1660',
    },
    {
        url: './2/1794.png',
        name: 'BSTC#1794',
        rank: 'Rank1661',
    },
    {
        url: './2/1538.png',
        name: 'BSTC#1538',
        rank: 'Rank1662',
    },
    {
        url: './2/1876.png',
        name: 'BSTC#1876',
        rank: 'Rank1663',
    },
    {
        url: './2/499.png',
        name: 'BSTC#499',
        rank: 'Rank1664',
    },
    {
        url: './2/647.png',
        name: 'BSTC#647',
        rank: 'Rank1665',
    },
    {
        url: './2/119.png',
        name: 'BSTC#119',
        rank: 'Rank1666',
    },
    {
        url: './2/2471.png',
        name: 'BSTC#2471',
        rank: 'Rank1667',
    },
    {
        url: './2/1869.png',
        name: 'BSTC#1869',
        rank: 'Rank1668',
    },
    {
        url: './2/2967.png',
        name: 'BSTC#2967',
        rank: 'Rank1669',
    },
    {
        url: './2/1764.png',
        name: 'BSTC#1764',
        rank: 'Rank1670',
    },
    {
        url: './2/2597.png',
        name: 'BSTC#2597',
        rank: 'Rank1671',
    },
    {
        url: './2/2251.png',
        name: 'BSTC#2251',
        rank: 'Rank1672',
    },
    {
        url: './2/1227.png',
        name: 'BSTC#1227',
        rank: 'Rank1673',
    },
    {
        url: './2/1742.png',
        name: 'BSTC#1742',
        rank: 'Rank1674',
    },
    {
        url: './2/904.png',
        name: 'BSTC#904',
        rank: 'Rank1675',
    },
    {
        url: './2/1233.png',
        name: 'BSTC#1233',
        rank: 'Rank1676',
    },
    {
        url: './2/1460.png',
        name: 'BSTC#1460',
        rank: 'Rank1677',
    },
    {
        url: './2/3050.png',
        name: 'BSTC#3050',
        rank: 'Rank1678',
    },
    {
        url: './2/2118.png',
        name: 'BSTC#2118',
        rank: 'Rank1679',
    },
    {
        url: './2/246.png',
        name: 'BSTC#246',
        rank: 'Rank1680',
    },
    {
        url: './2/1093.png',
        name: 'BSTC#1093',
        rank: 'Rank1681',
    },
    {
        url: './2/1386.png',
        name: 'BSTC#1386',
        rank: 'Rank1682',
    },
    {
        url: './2/2054.png',
        name: 'BSTC#2054',
        rank: 'Rank1683',
    },
    {
        url: './2/792.png',
        name: 'BSTC#792',
        rank: 'Rank1684',
    },
    {
        url: './2/742.png',
        name: 'BSTC#742',
        rank: 'Rank1685',
    },
    {
        url: './2/1523.png',
        name: 'BSTC#1523',
        rank: 'Rank1686',
    },
    {
        url: './2/1763.png',
        name: 'BSTC#1763',
        rank: 'Rank1687',
    },
    {
        url: './2/2556.png',
        name: 'BSTC#2556',
        rank: 'Rank1688',
    },
    {
        url: './2/2783.png',
        name: 'BSTC#2783',
        rank: 'Rank1689',
    },
    {
        url: './2/1462.png',
        name: 'BSTC#1462',
        rank: 'Rank1690',
    },
    {
        url: './2/745.png',
        name: 'BSTC#745',
        rank: 'Rank1691',
    },
    {
        url: './2/788.png',
        name: 'BSTC#788',
        rank: 'Rank1692',
    },
    {
        url: './2/1487.png',
        name: 'BSTC#1487',
        rank: 'Rank1693',
    },
    {
        url: './2/1659.png',
        name: 'BSTC#1659',
        rank: 'Rank1694',
    },
    {
        url: './2/1666.png',
        name: 'BSTC#1666',
        rank: 'Rank1695',
    },
    {
        url: './2/1696.png',
        name: 'BSTC#1696',
        rank: 'Rank1696',
    },
    {
        url: './2/1718.png',
        name: 'BSTC#1718',
        rank: 'Rank1697',
    },
    {
        url: './2/1837.png',
        name: 'BSTC#1837',
        rank: 'Rank1698',
    },
    {
        url: './2/1971.png',
        name: 'BSTC#1971',
        rank: 'Rank1699',
    },
    {
        url: './2/2387.png',
        name: 'BSTC#2387',
        rank: 'Rank1700',
    },
    {
        url: './2/2905.png',
        name: 'BSTC#2905',
        rank: 'Rank1701',
    },
    {
        url: './2/87.png',
        name: 'BSTC#87',
        rank: 'Rank1702',
    },
    {
        url: './2/2464.png',
        name: 'BSTC#2464',
        rank: 'Rank1703',
    },
    {
        url: './2/1090.png',
        name: 'BSTC#1090',
        rank: 'Rank1704',
    },
    {
        url: './2/1010.png',
        name: 'BSTC#1010',
        rank: 'Rank1705',
    },
    {
        url: './2/2126.png',
        name: 'BSTC#2126',
        rank: 'Rank1706',
    },
    {
        url: './2/3098.png',
        name: 'BSTC#3098',
        rank: 'Rank1707',
    },
    {
        url: './2/1745.png',
        name: 'BSTC#1745',
        rank: 'Rank1708',
    },
    {
        url: './2/2714.png',
        name: 'BSTC#2714',
        rank: 'Rank1709',
    },
    {
        url: './2/2850.png',
        name: 'BSTC#2850',
        rank: 'Rank1710',
    },
    {
        url: './2/2871.png',
        name: 'BSTC#2871',
        rank: 'Rank1711',
    },
    {
        url: './2/2966.png',
        name: 'BSTC#2966',
        rank: 'Rank1712',
    },
    {
        url: './2/1070.png',
        name: 'BSTC#1070',
        rank: 'Rank1713',
    },
    {
        url: './2/1118.png',
        name: 'BSTC#1118',
        rank: 'Rank1714',
    },
    {
        url: './2/2151.png',
        name: 'BSTC#2151',
        rank: 'Rank1715',
    },
    {
        url: './2/2340.png',
        name: 'BSTC#2340',
        rank: 'Rank1716',
    },
    {
        url: './2/1973.png',
        name: 'BSTC#1973',
        rank: 'Rank1717',
    },
    {
        url: './2/164.png',
        name: 'BSTC#164',
        rank: 'Rank1718',
    },
    {
        url: './2/20.png',
        name: 'BSTC#20',
        rank: 'Rank1719',
    },
    {
        url: './2/404.png',
        name: 'BSTC#404',
        rank: 'Rank1720',
    },
    {
        url: './2/749.png',
        name: 'BSTC#749',
        rank: 'Rank1721',
    },
    {
        url: './2/1258.png',
        name: 'BSTC#1258',
        rank: 'Rank1722',
    },
    {
        url: './2/1283.png',
        name: 'BSTC#1283',
        rank: 'Rank1723',
    },
    {
        url: './2/2185.png',
        name: 'BSTC#2185',
        rank: 'Rank1724',
    },
    {
        url: './2/38.png',
        name: 'BSTC#38',
        rank: 'Rank1725',
    },
    {
        url: './2/1472.png',
        name: 'BSTC#1472',
        rank: 'Rank1726',
    },
    {
        url: './2/2188.png',
        name: 'BSTC#2188',
        rank: 'Rank1727',
    },
    {
        url: './2/1035.png',
        name: 'BSTC#1035',
        rank: 'Rank1728',
    },
    {
        url: './2/910.png',
        name: 'BSTC#910',
        rank: 'Rank1729',
    },
    {
        url: './2/330.png',
        name: 'BSTC#330',
        rank: 'Rank1730',
    },
    {
        url: './2/2765.png',
        name: 'BSTC#2765',
        rank: 'Rank1731',
    },
    {
        url: './2/378.png',
        name: 'BSTC#378',
        rank: 'Rank1732',
    },
    {
        url: './2/92.png',
        name: 'BSTC#92',
        rank: 'Rank1733',
    },
    {
        url: './2/1338.png',
        name: 'BSTC#1338',
        rank: 'Rank1734',
    },
    {
        url: './2/836.png',
        name: 'BSTC#836',
        rank: 'Rank1735',
    },
    {
        url: './2/1699.png',
        name: 'BSTC#1699',
        rank: 'Rank1736',
    },
    {
        url: './2/3007.png',
        name: 'BSTC#3007',
        rank: 'Rank1737',
    },
    {
        url: './2/376.png',
        name: 'BSTC#376',
        rank: 'Rank1738',
    },
    {
        url: './2/444.png',
        name: 'BSTC#444',
        rank: 'Rank1739',
    },
    {
        url: './2/1916.png',
        name: 'BSTC#1916',
        rank: 'Rank1740',
    },
    {
        url: './2/1122.png',
        name: 'BSTC#1122',
        rank: 'Rank1741',
    },
    {
        url: './2/1514.png',
        name: 'BSTC#1514',
        rank: 'Rank1742',
    },
    {
        url: './2/1337.png',
        name: 'BSTC#1337',
        rank: 'Rank1743',
    },
    {
        url: './2/2310.png',
        name: 'BSTC#2310',
        rank: 'Rank1744',
    },
    {
        url: './2/2781.png',
        name: 'BSTC#2781',
        rank: 'Rank1745',
    },
    {
        url: './2/1953.png',
        name: 'BSTC#1953',
        rank: 'Rank1746',
    },
    {
        url: './2/2959.png',
        name: 'BSTC#2959',
        rank: 'Rank1747',
    },
    {
        url: './2/206.png',
        name: 'BSTC#206',
        rank: 'Rank1748',
    },
    {
        url: './2/736.png',
        name: 'BSTC#736',
        rank: 'Rank1749',
    },
    {
        url: './2/1683.png',
        name: 'BSTC#1683',
        rank: 'Rank1750',
    },
    {
        url: './2/3076.png',
        name: 'BSTC#3076',
        rank: 'Rank1751',
    },
    {
        url: './2/2451.png',
        name: 'BSTC#2451',
        rank: 'Rank1752',
    },
    {
        url: './2/1922.png',
        name: 'BSTC#1922',
        rank: 'Rank1753',
    },
    {
        url: './2/3013.png',
        name: 'BSTC#3013',
        rank: 'Rank1754',
    },
    {
        url: './2/2630.png',
        name: 'BSTC#2630',
        rank: 'Rank1755',
    },
    {
        url: './2/1814.png',
        name: 'BSTC#1814',
        rank: 'Rank1756',
    },
    {
        url: './2/1119.png',
        name: 'BSTC#1119',
        rank: 'Rank1757',
    },
    {
        url: './2/1403.png',
        name: 'BSTC#1403',
        rank: 'Rank1758',
    },
    {
        url: './2/2088.png',
        name: 'BSTC#2088',
        rank: 'Rank1759',
    },
    {
        url: './2/1419.png',
        name: 'BSTC#1419',
        rank: 'Rank1760',
    },
    {
        url: './2/1758.png',
        name: 'BSTC#1758',
        rank: 'Rank1761',
    },
    {
        url: './2/1364.png',
        name: 'BSTC#1364',
        rank: 'Rank1762',
    },
    {
        url: './2/1634.png',
        name: 'BSTC#1634',
        rank: 'Rank1763',
    },
    {
        url: './2/1866.png',
        name: 'BSTC#1866',
        rank: 'Rank1764',
    },
    {
        url: './2/2068.png',
        name: 'BSTC#2068',
        rank: 'Rank1765',
    },
    {
        url: './2/2246.png',
        name: 'BSTC#2246',
        rank: 'Rank1766',
    },
    {
        url: './2/2687.png',
        name: 'BSTC#2687',
        rank: 'Rank1767',
    },
    {
        url: './2/2859.png',
        name: 'BSTC#2859',
        rank: 'Rank1768',
    },
    {
        url: './2/903.png',
        name: 'BSTC#903',
        rank: 'Rank1769',
    },
    {
        url: './2/313.png',
        name: 'BSTC#313',
        rank: 'Rank1770',
    },
    {
        url: './2/448.png',
        name: 'BSTC#448',
        rank: 'Rank1771',
    },
    {
        url: './2/1039.png',
        name: 'BSTC#1039',
        rank: 'Rank1772',
    },
    {
        url: './2/1425.png',
        name: 'BSTC#1425',
        rank: 'Rank1773',
    },
    {
        url: './2/185.png',
        name: 'BSTC#185',
        rank: 'Rank1774',
    },
    {
        url: './2/365.png',
        name: 'BSTC#365',
        rank: 'Rank1775',
    },
    {
        url: './2/638.png',
        name: 'BSTC#638',
        rank: 'Rank1776',
    },
    {
        url: './2/816.png',
        name: 'BSTC#816',
        rank: 'Rank1777',
    },
    {
        url: './2/1485.png',
        name: 'BSTC#1485',
        rank: 'Rank1778',
    },
    {
        url: './2/1491.png',
        name: 'BSTC#1491',
        rank: 'Rank1779',
    },
    {
        url: './2/2641.png',
        name: 'BSTC#2641',
        rank: 'Rank1780',
    },
    {
        url: './2/3010.png',
        name: 'BSTC#3010',
        rank: 'Rank1781',
    },
    {
        url: './2/1182.png',
        name: 'BSTC#1182',
        rank: 'Rank1782',
    },
    {
        url: './2/2485.png',
        name: 'BSTC#2485',
        rank: 'Rank1783',
    },
    {
        url: './2/1924.png',
        name: 'BSTC#1924',
        rank: 'Rank1784',
    },
    {
        url: './2/2187.png',
        name: 'BSTC#2187',
        rank: 'Rank1785',
    },
    {
        url: './2/1827.png',
        name: 'BSTC#1827',
        rank: 'Rank1786',
    },
    {
        url: './2/1256.png',
        name: 'BSTC#1256',
        rank: 'Rank1787',
    },
    {
        url: './2/1795.png',
        name: 'BSTC#1795',
        rank: 'Rank1788',
    },
    {
        url: './2/734.png',
        name: 'BSTC#734',
        rank: 'Rank1789',
    },
    {
        url: './2/3129.png',
        name: 'BSTC#3129',
        rank: 'Rank1790',
    },
    {
        url: './2/614.png',
        name: 'BSTC#614',
        rank: 'Rank1791',
    },
    {
        url: './2/748.png',
        name: 'BSTC#748',
        rank: 'Rank1792',
    },
    {
        url: './2/2129.png',
        name: 'BSTC#2129',
        rank: 'Rank1793',
    },
    {
        url: './2/431.png',
        name: 'BSTC#431',
        rank: 'Rank1794',
    },
    {
        url: './2/2254.png',
        name: 'BSTC#2254',
        rank: 'Rank1795',
    },
    {
        url: './2/1114.png',
        name: 'BSTC#1114',
        rank: 'Rank1796',
    },
    {
        url: './2/1135.png',
        name: 'BSTC#1135',
        rank: 'Rank1797',
    },
    {
        url: './2/1281.png',
        name: 'BSTC#1281',
        rank: 'Rank1798',
    },
    {
        url: './2/2124.png',
        name: 'BSTC#2124',
        rank: 'Rank1799',
    },
    {
        url: './2/2925.png',
        name: 'BSTC#2925',
        rank: 'Rank1800',
    },
    {
        url: './2/2183.png',
        name: 'BSTC#2183',
        rank: 'Rank1801',
    },
    {
        url: './2/2594.png',
        name: 'BSTC#2594',
        rank: 'Rank1802',
    },
    {
        url: './2/1047.png',
        name: 'BSTC#1047',
        rank: 'Rank1803',
    },
    {
        url: './2/1184.png',
        name: 'BSTC#1184',
        rank: 'Rank1804',
    },
    {
        url: './2/1886.png',
        name: 'BSTC#1886',
        rank: 'Rank1805',
    },
    {
        url: './2/2221.png',
        name: 'BSTC#2221',
        rank: 'Rank1806',
    },
    {
        url: './2/2915.png',
        name: 'BSTC#2915',
        rank: 'Rank1807',
    },
    {
        url: './2/2985.png',
        name: 'BSTC#2985',
        rank: 'Rank1808',
    },
    {
        url: './2/3030.png',
        name: 'BSTC#3030',
        rank: 'Rank1809',
    },
    {
        url: './2/2139.png',
        name: 'BSTC#2139',
        rank: 'Rank1810',
    },
    {
        url: './2/1549.png',
        name: 'BSTC#1549',
        rank: 'Rank1811',
    },
    {
        url: './2/1589.png',
        name: 'BSTC#1589',
        rank: 'Rank1812',
    },
    {
        url: './2/2396.png',
        name: 'BSTC#2396',
        rank: 'Rank1813',
    },
    {
        url: './2/2448.png',
        name: 'BSTC#2448',
        rank: 'Rank1814',
    },
    {
        url: './2/29.png',
        name: 'BSTC#29',
        rank: 'Rank1815',
    },
    {
        url: './2/350.png',
        name: 'BSTC#350',
        rank: 'Rank1816',
    },
    {
        url: './2/396.png',
        name: 'BSTC#396',
        rank: 'Rank1817',
    },
    {
        url: './2/997.png',
        name: 'BSTC#997',
        rank: 'Rank1818',
    },
    {
        url: './2/1176.png',
        name: 'BSTC#1176',
        rank: 'Rank1819',
    },
    {
        url: './2/2613.png',
        name: 'BSTC#2613',
        rank: 'Rank1820',
    },
    {
        url: './2/2997.png',
        name: 'BSTC#2997',
        rank: 'Rank1821',
    },
    {
        url: './2/1546.png',
        name: 'BSTC#1546',
        rank: 'Rank1822',
    },
    {
        url: './2/1605.png',
        name: 'BSTC#1605',
        rank: 'Rank1823',
    },
    {
        url: './2/2066.png',
        name: 'BSTC#2066',
        rank: 'Rank1824',
    },
    {
        url: './2/2608.png',
        name: 'BSTC#2608',
        rank: 'Rank1825',
    },
    {
        url: './2/667.png',
        name: 'BSTC#667',
        rank: 'Rank1826',
    },
    {
        url: './2/2601.png',
        name: 'BSTC#2601',
        rank: 'Rank1827',
    },
    {
        url: './2/765.png',
        name: 'BSTC#765',
        rank: 'Rank1828',
    },
    {
        url: './2/389.png',
        name: 'BSTC#389',
        rank: 'Rank1829',
    },
    {
        url: './2/2376.png',
        name: 'BSTC#2376',
        rank: 'Rank1830',
    },
    {
        url: './2/2437.png',
        name: 'BSTC#2437',
        rank: 'Rank1831',
    },
    {
        url: './2/949.png',
        name: 'BSTC#949',
        rank: 'Rank1832',
    },
    {
        url: './2/1534.png',
        name: 'BSTC#1534',
        rank: 'Rank1833',
    },
    {
        url: './2/402.png',
        name: 'BSTC#402',
        rank: 'Rank1834',
    },
    {
        url: './2/2481.png',
        name: 'BSTC#2481',
        rank: 'Rank1835',
    },
    {
        url: './2/646.png',
        name: 'BSTC#646',
        rank: 'Rank1836',
    },
    {
        url: './2/858.png',
        name: 'BSTC#858',
        rank: 'Rank1837',
    },
    {
        url: './2/3138.png',
        name: 'BSTC#3138',
        rank: 'Rank1838',
    },
    {
        url: './2/1192.png',
        name: 'BSTC#1192',
        rank: 'Rank1839',
    },
    {
        url: './2/1609.png',
        name: 'BSTC#1609',
        rank: 'Rank1840',
    },
    {
        url: './2/1872.png',
        name: 'BSTC#1872',
        rank: 'Rank1841',
    },
    {
        url: './2/2415.png',
        name: 'BSTC#2415',
        rank: 'Rank1842',
    },
    {
        url: './2/253.png',
        name: 'BSTC#253',
        rank: 'Rank1843',
    },
    {
        url: './2/1305.png',
        name: 'BSTC#1305',
        rank: 'Rank1844',
    },
    {
        url: './2/2838.png',
        name: 'BSTC#2838',
        rank: 'Rank1845',
    },
    {
        url: './2/158.png',
        name: 'BSTC#158',
        rank: 'Rank1846',
    },
    {
        url: './2/994.png',
        name: 'BSTC#994',
        rank: 'Rank1847',
    },
    {
        url: './2/1335.png',
        name: 'BSTC#1335',
        rank: 'Rank1848',
    },
    {
        url: './2/2817.png',
        name: 'BSTC#2817',
        rank: 'Rank1849',
    },
    {
        url: './2/244.png',
        name: 'BSTC#244',
        rank: 'Rank1850',
    },
    {
        url: './2/1830.png',
        name: 'BSTC#1830',
        rank: 'Rank1851',
    },
    {
        url: './2/2322.png',
        name: 'BSTC#2322',
        rank: 'Rank1852',
    },
    {
        url: './2/3091.png',
        name: 'BSTC#3091',
        rank: 'Rank1853',
    },
    {
        url: './2/419.png',
        name: 'BSTC#419',
        rank: 'Rank1854',
    },
    {
        url: './2/5.png',
        name: 'BSTC#5',
        rank: 'Rank1855',
    },
    {
        url: './2/97.png',
        name: 'BSTC#97',
        rank: 'Rank1856',
    },
    {
        url: './2/1896.png',
        name: 'BSTC#1896',
        rank: 'Rank1857',
    },
    {
        url: './2/265.png',
        name: 'BSTC#265',
        rank: 'Rank1858',
    },
    {
        url: './2/461.png',
        name: 'BSTC#461',
        rank: 'Rank1859',
    },
    {
        url: './2/463.png',
        name: 'BSTC#463',
        rank: 'Rank1860',
    },
    {
        url: './2/2818.png',
        name: 'BSTC#2818',
        rank: 'Rank1861',
    },
    {
        url: './2/589.png',
        name: 'BSTC#589',
        rank: 'Rank1862',
    },
    {
        url: './2/2270.png',
        name: 'BSTC#2270',
        rank: 'Rank1863',
    },
    {
        url: './2/101.png',
        name: 'BSTC#101',
        rank: 'Rank1864',
    },
    {
        url: './2/627.png',
        name: 'BSTC#627',
        rank: 'Rank1865',
    },
    {
        url: './2/1987.png',
        name: 'BSTC#1987',
        rank: 'Rank1866',
    },
    {
        url: './2/2230.png',
        name: 'BSTC#2230',
        rank: 'Rank1867',
    },
    {
        url: './2/2358.png',
        name: 'BSTC#2358',
        rank: 'Rank1868',
    },
    {
        url: './2/2881.png',
        name: 'BSTC#2881',
        rank: 'Rank1869',
    },
    {
        url: './2/458.png',
        name: 'BSTC#458',
        rank: 'Rank1870',
    },
    {
        url: './2/1168.png',
        name: 'BSTC#1168',
        rank: 'Rank1871',
    },
    {
        url: './2/1816.png',
        name: 'BSTC#1816',
        rank: 'Rank1872',
    },
    {
        url: './2/2099.png',
        name: 'BSTC#2099',
        rank: 'Rank1873',
    },
    {
        url: './2/797.png',
        name: 'BSTC#797',
        rank: 'Rank1874',
    },
    {
        url: './2/2269.png',
        name: 'BSTC#2269',
        rank: 'Rank1875',
    },
    {
        url: './2/1350.png',
        name: 'BSTC#1350',
        rank: 'Rank1876',
    },
    {
        url: './2/2792.png',
        name: 'BSTC#2792',
        rank: 'Rank1877',
    },
    {
        url: './2/1574.png',
        name: 'BSTC#1574',
        rank: 'Rank1878',
    },
    {
        url: './2/2425.png',
        name: 'BSTC#2425',
        rank: 'Rank1879',
    },
    {
        url: './2/2565.png',
        name: 'BSTC#2565',
        rank: 'Rank1880',
    },
    {
        url: './2/82.png',
        name: 'BSTC#82',
        rank: 'Rank1881',
    },
    {
        url: './2/96.png',
        name: 'BSTC#96',
        rank: 'Rank1882',
    },
    {
        url: './2/1853.png',
        name: 'BSTC#1853',
        rank: 'Rank1883',
    },
    {
        url: './2/2120.png',
        name: 'BSTC#2120',
        rank: 'Rank1884',
    },
    {
        url: './2/2616.png',
        name: 'BSTC#2616',
        rank: 'Rank1885',
    },
    {
        url: './2/2726.png',
        name: 'BSTC#2726',
        rank: 'Rank1886',
    },
    {
        url: './2/2849.png',
        name: 'BSTC#2849',
        rank: 'Rank1887',
    },
    {
        url: './2/2900.png',
        name: 'BSTC#2900',
        rank: 'Rank1888',
    },
    {
        url: './2/429.png',
        name: 'BSTC#429',
        rank: 'Rank1889',
    },
    {
        url: './2/1174.png',
        name: 'BSTC#1174',
        rank: 'Rank1890',
    },
    {
        url: './2/2699.png',
        name: 'BSTC#2699',
        rank: 'Rank1891',
    },
    {
        url: './2/824.png',
        name: 'BSTC#824',
        rank: 'Rank1892',
    },
    {
        url: './2/1562.png',
        name: 'BSTC#1562',
        rank: 'Rank1893',
    },
    {
        url: './2/2049.png',
        name: 'BSTC#2049',
        rank: 'Rank1894',
    },
    {
        url: './2/2922.png',
        name: 'BSTC#2922',
        rank: 'Rank1895',
    },
    {
        url: './2/323.png',
        name: 'BSTC#323',
        rank: 'Rank1896',
    },
    {
        url: './2/713.png',
        name: 'BSTC#713',
        rank: 'Rank1897',
    },
    {
        url: './2/1219.png',
        name: 'BSTC#1219',
        rank: 'Rank1898',
    },
    {
        url: './2/160.png',
        name: 'BSTC#160',
        rank: 'Rank1899',
    },
    {
        url: './2/923.png',
        name: 'BSTC#923',
        rank: 'Rank1900',
    },
    {
        url: './2/2212.png',
        name: 'BSTC#2212',
        rank: 'Rank1901',
    },
    {
        url: './2/1315.png',
        name: 'BSTC#1315',
        rank: 'Rank1902',
    },
    {
        url: './2/1726.png',
        name: 'BSTC#1726',
        rank: 'Rank1903',
    },
    {
        url: './2/2584.png',
        name: 'BSTC#2584',
        rank: 'Rank1904',
    },
    {
        url: './2/1232.png',
        name: 'BSTC#1232',
        rank: 'Rank1905',
    },
    {
        url: './2/63.png',
        name: 'BSTC#63',
        rank: 'Rank1906',
    },
    {
        url: './2/250.png',
        name: 'BSTC#250',
        rank: 'Rank1907',
    },
    {
        url: './2/970.png',
        name: 'BSTC#970',
        rank: 'Rank1908',
    },
    {
        url: './2/1803.png',
        name: 'BSTC#1803',
        rank: 'Rank1909',
    },
    {
        url: './2/1569.png',
        name: 'BSTC#1569',
        rank: 'Rank1910',
    },
    {
        url: './2/1530.png',
        name: 'BSTC#1530',
        rank: 'Rank1911',
    },
    {
        url: './2/2461.png',
        name: 'BSTC#2461',
        rank: 'Rank1912',
    },
    {
        url: './2/417.png',
        name: 'BSTC#417',
        rank: 'Rank1913',
    },
    {
        url: './2/819.png',
        name: 'BSTC#819',
        rank: 'Rank1914',
    },
    {
        url: './2/1557.png',
        name: 'BSTC#1557',
        rank: 'Rank1915',
    },
    {
        url: './2/1901.png',
        name: 'BSTC#1901',
        rank: 'Rank1916',
    },
    {
        url: './2/2521.png',
        name: 'BSTC#2521',
        rank: 'Rank1917',
    },
    {
        url: './2/2804.png',
        name: 'BSTC#2804',
        rank: 'Rank1918',
    },
    {
        url: './2/3079.png',
        name: 'BSTC#3079',
        rank: 'Rank1919',
    },
    {
        url: './2/2189.png',
        name: 'BSTC#2189',
        rank: 'Rank1920',
    },
    {
        url: './2/2885.png',
        name: 'BSTC#2885',
        rank: 'Rank1921',
    },
    {
        url: './2/2051.png',
        name: 'BSTC#2051',
        rank: 'Rank1922',
    },
    {
        url: './2/887.png',
        name: 'BSTC#887',
        rank: 'Rank1923',
    },
    {
        url: './2/1736.png',
        name: 'BSTC#1736',
        rank: 'Rank1924',
    },
    {
        url: './2/1691.png',
        name: 'BSTC#1691',
        rank: 'Rank1925',
    },
    {
        url: './2/773.png',
        name: 'BSTC#773',
        rank: 'Rank1926',
    },
    {
        url: './2/273.png',
        name: 'BSTC#273',
        rank: 'Rank1927',
    },
    {
        url: './2/2279.png',
        name: 'BSTC#2279',
        rank: 'Rank1928',
    },
    {
        url: './2/2873.png',
        name: 'BSTC#2873',
        rank: 'Rank1929',
    },
    {
        url: './2/1796.png',
        name: 'BSTC#1796',
        rank: 'Rank1930',
    },
    {
        url: './2/25.png',
        name: 'BSTC#25',
        rank: 'Rank1931',
    },
    {
        url: './2/336.png',
        name: 'BSTC#336',
        rank: 'Rank1932',
    },
    {
        url: './2/1027.png',
        name: 'BSTC#1027',
        rank: 'Rank1933',
    },
    {
        url: './2/1329.png',
        name: 'BSTC#1329',
        rank: 'Rank1934',
    },
    {
        url: './2/2009.png',
        name: 'BSTC#2009',
        rank: 'Rank1935',
    },
    {
        url: './2/2524.png',
        name: 'BSTC#2524',
        rank: 'Rank1936',
    },
    {
        url: './2/3087.png',
        name: 'BSTC#3087',
        rank: 'Rank1937',
    },
    {
        url: './2/2520.png',
        name: 'BSTC#2520',
        rank: 'Rank1938',
    },
    {
        url: './2/2281.png',
        name: 'BSTC#2281',
        rank: 'Rank1939',
    },
    {
        url: './2/2531.png',
        name: 'BSTC#2531',
        rank: 'Rank1940',
    },
    {
        url: './2/432.png',
        name: 'BSTC#432',
        rank: 'Rank1941',
    },
    {
        url: './2/1415.png',
        name: 'BSTC#1415',
        rank: 'Rank1942',
    },
    {
        url: './2/2178.png',
        name: 'BSTC#2178',
        rank: 'Rank1943',
    },
    {
        url: './2/1941.png',
        name: 'BSTC#1941',
        rank: 'Rank1944',
    },
    {
        url: './2/203.png',
        name: 'BSTC#203',
        rank: 'Rank1945',
    },
    {
        url: './2/3039.png',
        name: 'BSTC#3039',
        rank: 'Rank1946',
    },
    {
        url: './2/512.png',
        name: 'BSTC#512',
        rank: 'Rank1947',
    },
    {
        url: './2/618.png',
        name: 'BSTC#618',
        rank: 'Rank1948',
    },
    {
        url: './2/3112.png',
        name: 'BSTC#3112',
        rank: 'Rank1949',
    },
    {
        url: './2/1203.png',
        name: 'BSTC#1203',
        rank: 'Rank1950',
    },
    {
        url: './2/2401.png',
        name: 'BSTC#2401',
        rank: 'Rank1951',
    },
    {
        url: './2/605.png',
        name: 'BSTC#605',
        rank: 'Rank1952',
    },
    {
        url: './2/1064.png',
        name: 'BSTC#1064',
        rank: 'Rank1953',
    },
    {
        url: './2/1220.png',
        name: 'BSTC#1220',
        rank: 'Rank1954',
    },
    {
        url: './2/1840.png',
        name: 'BSTC#1840',
        rank: 'Rank1955',
    },
    {
        url: './2/2528.png',
        name: 'BSTC#2528',
        rank: 'Rank1956',
    },
    {
        url: './2/3072.png',
        name: 'BSTC#3072',
        rank: 'Rank1957',
    },
    {
        url: './2/1579.png',
        name: 'BSTC#1579',
        rank: 'Rank1958',
    },
    {
        url: './2/2392.png',
        name: 'BSTC#2392',
        rank: 'Rank1959',
    },
    {
        url: './2/451.png',
        name: 'BSTC#451',
        rank: 'Rank1960',
    },
    {
        url: './2/1252.png',
        name: 'BSTC#1252',
        rank: 'Rank1961',
    },
    {
        url: './2/827.png',
        name: 'BSTC#827',
        rank: 'Rank1962',
    },
    {
        url: './2/918.png',
        name: 'BSTC#918',
        rank: 'Rank1963',
    },
    {
        url: './2/1969.png',
        name: 'BSTC#1969',
        rank: 'Rank1964',
    },
    {
        url: './2/2055.png',
        name: 'BSTC#2055',
        rank: 'Rank1965',
    },
    {
        url: './2/3101.png',
        name: 'BSTC#3101',
        rank: 'Rank1966',
    },
    {
        url: './2/2552.png',
        name: 'BSTC#2552',
        rank: 'Rank1967',
    },
    {
        url: './2/1602.png',
        name: 'BSTC#1602',
        rank: 'Rank1968',
    },
    {
        url: './2/109.png',
        name: 'BSTC#109',
        rank: 'Rank1969',
    },
    {
        url: './2/272.png',
        name: 'BSTC#272',
        rank: 'Rank1970',
    },
    {
        url: './2/283.png',
        name: 'BSTC#283',
        rank: 'Rank1971',
    },
    {
        url: './2/961.png',
        name: 'BSTC#961',
        rank: 'Rank1972',
    },
    {
        url: './2/653.png',
        name: 'BSTC#653',
        rank: 'Rank1973',
    },
    {
        url: './2/2672.png',
        name: 'BSTC#2672',
        rank: 'Rank1974',
    },
    {
        url: './2/2735.png',
        name: 'BSTC#2735',
        rank: 'Rank1975',
    },
    {
        url: './2/2766.png',
        name: 'BSTC#2766',
        rank: 'Rank1976',
    },
    {
        url: './2/874.png',
        name: 'BSTC#874',
        rank: 'Rank1977',
    },
    {
        url: './2/3111.png',
        name: 'BSTC#3111',
        rank: 'Rank1978',
    },
    {
        url: './2/1482.png',
        name: 'BSTC#1482',
        rank: 'Rank1979',
    },
    {
        url: './2/2778.png',
        name: 'BSTC#2778',
        rank: 'Rank1980',
    },
    {
        url: './2/103.png',
        name: 'BSTC#103',
        rank: 'Rank1981',
    },
    {
        url: './2/2789.png',
        name: 'BSTC#2789',
        rank: 'Rank1982',
    },
    {
        url: './2/2052.png',
        name: 'BSTC#2052',
        rank: 'Rank1983',
    },
    {
        url: './2/236.png',
        name: 'BSTC#236',
        rank: 'Rank1984',
    },
    {
        url: './2/891.png',
        name: 'BSTC#891',
        rank: 'Rank1985',
    },
    {
        url: './2/2162.png',
        name: 'BSTC#2162',
        rank: 'Rank1986',
    },
    {
        url: './2/1888.png',
        name: 'BSTC#1888',
        rank: 'Rank1987',
    },
    {
        url: './2/129.png',
        name: 'BSTC#129',
        rank: 'Rank1988',
    },
    {
        url: './2/1249.png',
        name: 'BSTC#1249',
        rank: 'Rank1989',
    },
    {
        url: './2/45.png',
        name: 'BSTC#45',
        rank: 'Rank1990',
    },
    {
        url: './2/369.png',
        name: 'BSTC#369',
        rank: 'Rank1991',
    },
    {
        url: './2/703.png',
        name: 'BSTC#703',
        rank: 'Rank1992',
    },
    {
        url: './2/3088.png',
        name: 'BSTC#3088',
        rank: 'Rank1993',
    },
    {
        url: './2/55.png',
        name: 'BSTC#55',
        rank: 'Rank1994',
    },
    {
        url: './2/462.png',
        name: 'BSTC#462',
        rank: 'Rank1995',
    },
    {
        url: './2/1214.png',
        name: 'BSTC#1214',
        rank: 'Rank1996',
    },
    {
        url: './2/2762.png',
        name: 'BSTC#2762',
        rank: 'Rank1997',
    },
    {
        url: './2/2094.png',
        name: 'BSTC#2094',
        rank: 'Rank1998',
    },
    {
        url: './2/591.png',
        name: 'BSTC#591',
        rank: 'Rank1999',
    },
    {
        url: './2/1568.png',
        name: 'BSTC#1568',
        rank: 'Rank2000',
    },
    {
        url: './2/1681.png',
        name: 'BSTC#1681',
        rank: 'Rank2001',
    },
    {
        url: './2/911.png',
        name: 'BSTC#911',
        rank: 'Rank2002',
    },
    {
        url: './2/1517.png',
        name: 'BSTC#1517',
        rank: 'Rank2003',
    },
    {
        url: './2/1854.png',
        name: 'BSTC#1854',
        rank: 'Rank2004',
    },
    {
        url: './2/2354.png',
        name: 'BSTC#2354',
        rank: 'Rank2005',
    },
    {
        url: './2/999.png',
        name: 'BSTC#999',
        rank: 'Rank2006',
    },
    {
        url: './2/581.png',
        name: 'BSTC#581',
        rank: 'Rank2007',
    },
    {
        url: './2/2675.png',
        name: 'BSTC#2675',
        rank: 'Rank2008',
    },
    {
        url: './2/1760.png',
        name: 'BSTC#1760',
        rank: 'Rank2009',
    },
    {
        url: './2/2342.png',
        name: 'BSTC#2342',
        rank: 'Rank2010',
    },
    {
        url: './2/116.png',
        name: 'BSTC#116',
        rank: 'Rank2011',
    },
    {
        url: './2/1302.png',
        name: 'BSTC#1302',
        rank: 'Rank2012',
    },
    {
        url: './2/104.png',
        name: 'BSTC#104',
        rank: 'Rank2013',
    },
    {
        url: './2/1543.png',
        name: 'BSTC#1543',
        rank: 'Rank2014',
    },
    {
        url: './2/1229.png',
        name: 'BSTC#1229',
        rank: 'Rank2015',
    },
    {
        url: './2/1857.png',
        name: 'BSTC#1857',
        rank: 'Rank2016',
    },
    {
        url: './2/2517.png',
        name: 'BSTC#2517',
        rank: 'Rank2017',
    },
    {
        url: './2/2602.png',
        name: 'BSTC#2602',
        rank: 'Rank2018',
    },
    {
        url: './2/2105.png',
        name: 'BSTC#2105',
        rank: 'Rank2019',
    },
    {
        url: './2/2237.png',
        name: 'BSTC#2237',
        rank: 'Rank2020',
    },
    {
        url: './2/449.png',
        name: 'BSTC#449',
        rank: 'Rank2021',
    },
    {
        url: './2/722.png',
        name: 'BSTC#722',
        rank: 'Rank2022',
    },
    {
        url: './2/1169.png',
        name: 'BSTC#1169',
        rank: 'Rank2023',
    },
    {
        url: './2/1652.png',
        name: 'BSTC#1652',
        rank: 'Rank2024',
    },
    {
        url: './2/2745.png',
        name: 'BSTC#2745',
        rank: 'Rank2025',
    },
    {
        url: './2/833.png',
        name: 'BSTC#833',
        rank: 'Rank2026',
    },
    {
        url: './2/1195.png',
        name: 'BSTC#1195',
        rank: 'Rank2027',
    },
    {
        url: './2/2586.png',
        name: 'BSTC#2586',
        rank: 'Rank2028',
    },
    {
        url: './2/796.png',
        name: 'BSTC#796',
        rank: 'Rank2029',
    },
    {
        url: './2/815.png',
        name: 'BSTC#815',
        rank: 'Rank2030',
    },
    {
        url: './2/957.png',
        name: 'BSTC#957',
        rank: 'Rank2031',
    },
    {
        url: './2/2971.png',
        name: 'BSTC#2971',
        rank: 'Rank2032',
    },
    {
        url: './2/1972.png',
        name: 'BSTC#1972',
        rank: 'Rank2033',
    },
    {
        url: './2/1506.png',
        name: 'BSTC#1506',
        rank: 'Rank2034',
    },
    {
        url: './2/3034.png',
        name: 'BSTC#3034',
        rank: 'Rank2035',
    },
    {
        url: './2/1753.png',
        name: 'BSTC#1753',
        rank: 'Rank2036',
    },
    {
        url: './2/195.png',
        name: 'BSTC#195',
        rank: 'Rank2037',
    },
    {
        url: './2/474.png',
        name: 'BSTC#474',
        rank: 'Rank2038',
    },
    {
        url: './2/558.png',
        name: 'BSTC#558',
        rank: 'Rank2039',
    },
    {
        url: './2/578.png',
        name: 'BSTC#578',
        rank: 'Rank2040',
    },
    {
        url: './2/1563.png',
        name: 'BSTC#1563',
        rank: 'Rank2041',
    },
    {
        url: './2/1566.png',
        name: 'BSTC#1566',
        rank: 'Rank2042',
    },
    {
        url: './2/1749.png',
        name: 'BSTC#1749',
        rank: 'Rank2043',
    },
    {
        url: './2/2206.png',
        name: 'BSTC#2206',
        rank: 'Rank2044',
    },
    {
        url: './2/2651.png',
        name: 'BSTC#2651',
        rank: 'Rank2045',
    },
    {
        url: './2/2346.png',
        name: 'BSTC#2346',
        rank: 'Rank2046',
    },
    {
        url: './2/954.png',
        name: 'BSTC#954',
        rank: 'Rank2047',
    },
    {
        url: './2/1852.png',
        name: 'BSTC#1852',
        rank: 'Rank2048',
    },
    {
        url: './2/1928.png',
        name: 'BSTC#1928',
        rank: 'Rank2049',
    },
    {
        url: './2/2078.png',
        name: 'BSTC#2078',
        rank: 'Rank2050',
    },
    {
        url: './2/296.png',
        name: 'BSTC#296',
        rank: 'Rank2051',
    },
    {
        url: './2/876.png',
        name: 'BSTC#876',
        rank: 'Rank2052',
    },
    {
        url: './2/1133.png',
        name: 'BSTC#1133',
        rank: 'Rank2053',
    },
    {
        url: './2/1065.png',
        name: 'BSTC#1065',
        rank: 'Rank2054',
    },
    {
        url: './2/361.png',
        name: 'BSTC#361',
        rank: 'Rank2055',
    },
    {
        url: './2/1613.png',
        name: 'BSTC#1613',
        rank: 'Rank2056',
    },
    {
        url: './2/153.png',
        name: 'BSTC#153',
        rank: 'Rank2057',
    },
    {
        url: './2/2775.png',
        name: 'BSTC#2775',
        rank: 'Rank2058',
    },
    {
        url: './2/2951.png',
        name: 'BSTC#2951',
        rank: 'Rank2059',
    },
    {
        url: './2/133.png',
        name: 'BSTC#133',
        rank: 'Rank2060',
    },
    {
        url: './2/142.png',
        name: 'BSTC#142',
        rank: 'Rank2061',
    },
    {
        url: './2/715.png',
        name: 'BSTC#715',
        rank: 'Rank2062',
    },
    {
        url: './2/959.png',
        name: 'BSTC#959',
        rank: 'Rank2063',
    },
    {
        url: './2/1486.png',
        name: 'BSTC#1486',
        rank: 'Rank2064',
    },
    {
        url: './2/2205.png',
        name: 'BSTC#2205',
        rank: 'Rank2065',
    },
    {
        url: './2/2364.png',
        name: 'BSTC#2364',
        rank: 'Rank2066',
    },
    {
        url: './2/2159.png',
        name: 'BSTC#2159',
        rank: 'Rank2067',
    },
    {
        url: './2/186.png',
        name: 'BSTC#186',
        rank: 'Rank2068',
    },
    {
        url: './2/1120.png',
        name: 'BSTC#1120',
        rank: 'Rank2069',
    },
    {
        url: './2/1895.png',
        name: 'BSTC#1895',
        rank: 'Rank2070',
    },
    {
        url: './2/897.png',
        name: 'BSTC#897',
        rank: 'Rank2071',
    },
    {
        url: './2/1662.png',
        name: 'BSTC#1662',
        rank: 'Rank2072',
    },
    {
        url: './2/2173.png',
        name: 'BSTC#2173',
        rank: 'Rank2073',
    },
    {
        url: './2/2312.png',
        name: 'BSTC#2312',
        rank: 'Rank2074',
    },
    {
        url: './2/3113.png',
        name: 'BSTC#3113',
        rank: 'Rank2075',
    },
    {
        url: './2/789.png',
        name: 'BSTC#789',
        rank: 'Rank2076',
    },
    {
        url: './2/1132.png',
        name: 'BSTC#1132',
        rank: 'Rank2077',
    },
    {
        url: './2/1716.png',
        name: 'BSTC#1716',
        rank: 'Rank2078',
    },
    {
        url: './2/1841.png',
        name: 'BSTC#1841',
        rank: 'Rank2079',
    },
    {
        url: './2/2307.png',
        name: 'BSTC#2307',
        rank: 'Rank2080',
    },
    {
        url: './2/2876.png',
        name: 'BSTC#2876',
        rank: 'Rank2081',
    },
    {
        url: './2/3046.png',
        name: 'BSTC#3046',
        rank: 'Rank2082',
    },
    {
        url: './2/3058.png',
        name: 'BSTC#3058',
        rank: 'Rank2083',
    },
    {
        url: './2/1897.png',
        name: 'BSTC#1897',
        rank: 'Rank2084',
    },
    {
        url: './2/2146.png',
        name: 'BSTC#2146',
        rank: 'Rank2085',
    },
    {
        url: './2/1205.png',
        name: 'BSTC#1205',
        rank: 'Rank2086',
    },
    {
        url: './2/1202.png',
        name: 'BSTC#1202',
        rank: 'Rank2087',
    },
    {
        url: './2/1707.png',
        name: 'BSTC#1707',
        rank: 'Rank2088',
    },
    {
        url: './2/2265.png',
        name: 'BSTC#2265',
        rank: 'Rank2089',
    },
    {
        url: './2/2549.png',
        name: 'BSTC#2549',
        rank: 'Rank2090',
    },
    {
        url: './2/1915.png',
        name: 'BSTC#1915',
        rank: 'Rank2091',
    },
    {
        url: './2/1785.png',
        name: 'BSTC#1785',
        rank: 'Rank2092',
    },
    {
        url: './2/1468.png',
        name: 'BSTC#1468',
        rank: 'Rank2093',
    },
    {
        url: './2/26.png',
        name: 'BSTC#26',
        rank: 'Rank2094',
    },
    {
        url: './2/2739.png',
        name: 'BSTC#2739',
        rank: 'Rank2095',
    },
    {
        url: './2/1072.png',
        name: 'BSTC#1072',
        rank: 'Rank2096',
    },
    {
        url: './2/79.png',
        name: 'BSTC#79',
        rank: 'Rank2097',
    },
    {
        url: './2/286.png',
        name: 'BSTC#286',
        rank: 'Rank2098',
    },
    {
        url: './2/898.png',
        name: 'BSTC#898',
        rank: 'Rank2099',
    },
    {
        url: './2/913.png',
        name: 'BSTC#913',
        rank: 'Rank2100',
    },
    {
        url: './2/1125.png',
        name: 'BSTC#1125',
        rank: 'Rank2101',
    },
    {
        url: './2/2388.png',
        name: 'BSTC#2388',
        rank: 'Rank2102',
    },
    {
        url: './2/2795.png',
        name: 'BSTC#2795',
        rank: 'Rank2103',
    },
    {
        url: './2/2379.png',
        name: 'BSTC#2379',
        rank: 'Rank2104',
    },
    {
        url: './2/2557.png',
        name: 'BSTC#2557',
        rank: 'Rank2105',
    },
    {
        url: './2/2135.png',
        name: 'BSTC#2135',
        rank: 'Rank2106',
    },
    {
        url: './2/2619.png',
        name: 'BSTC#2619',
        rank: 'Rank2107',
    },
    {
        url: './2/469.png',
        name: 'BSTC#469',
        rank: 'Rank2108',
    },
    {
        url: './2/214.png',
        name: 'BSTC#214',
        rank: 'Rank2109',
    },
    {
        url: './2/406.png',
        name: 'BSTC#406',
        rank: 'Rank2110',
    },
    {
        url: './2/1396.png',
        name: 'BSTC#1396',
        rank: 'Rank2111',
    },
    {
        url: './2/485.png',
        name: 'BSTC#485',
        rank: 'Rank2112',
    },
    {
        url: './2/1544.png',
        name: 'BSTC#1544',
        rank: 'Rank2113',
    },
    {
        url: './2/83.png',
        name: 'BSTC#83',
        rank: 'Rank2114',
    },
    {
        url: './2/520.png',
        name: 'BSTC#520',
        rank: 'Rank2115',
    },
    {
        url: './2/700.png',
        name: 'BSTC#700',
        rank: 'Rank2116',
    },
    {
        url: './2/883.png',
        name: 'BSTC#883',
        rank: 'Rank2117',
    },
    {
        url: './2/1747.png',
        name: 'BSTC#1747',
        rank: 'Rank2118',
    },
    {
        url: './2/1808.png',
        name: 'BSTC#1808',
        rank: 'Rank2119',
    },
    {
        url: './2/2438.png',
        name: 'BSTC#2438',
        rank: 'Rank2120',
    },
    {
        url: './2/2577.png',
        name: 'BSTC#2577',
        rank: 'Rank2121',
    },
    {
        url: './2/85.png',
        name: 'BSTC#85',
        rank: 'Rank2122',
    },
    {
        url: './2/2075.png',
        name: 'BSTC#2075',
        rank: 'Rank2123',
    },
    {
        url: './2/604.png',
        name: 'BSTC#604',
        rank: 'Rank2124',
    },
    {
        url: './2/755.png',
        name: 'BSTC#755',
        rank: 'Rank2125',
    },
    {
        url: './2/2145.png',
        name: 'BSTC#2145',
        rank: 'Rank2126',
    },
    {
        url: './2/2869.png',
        name: 'BSTC#2869',
        rank: 'Rank2127',
    },
    {
        url: './2/61.png',
        name: 'BSTC#61',
        rank: 'Rank2128',
    },
    {
        url: './2/886.png',
        name: 'BSTC#886',
        rank: 'Rank2129',
    },
    {
        url: './2/900.png',
        name: 'BSTC#900',
        rank: 'Rank2130',
    },
    {
        url: './2/1313.png',
        name: 'BSTC#1313',
        rank: 'Rank2131',
    },
    {
        url: './2/1997.png',
        name: 'BSTC#1997',
        rank: 'Rank2132',
    },
    {
        url: './2/2493.png',
        name: 'BSTC#2493',
        rank: 'Rank2133',
    },
    {
        url: './2/399.png',
        name: 'BSTC#399',
        rank: 'Rank2134',
    },
    {
        url: './2/2927.png',
        name: 'BSTC#2927',
        rank: 'Rank2135',
    },
    {
        url: './2/161.png',
        name: 'BSTC#161',
        rank: 'Rank2136',
    },
    {
        url: './2/2998.png',
        name: 'BSTC#2998',
        rank: 'Rank2137',
    },
    {
        url: './2/268.png',
        name: 'BSTC#268',
        rank: 'Rank2138',
    },
    {
        url: './2/1017.png',
        name: 'BSTC#1017',
        rank: 'Rank2139',
    },
    {
        url: './2/1575.png',
        name: 'BSTC#1575',
        rank: 'Rank2140',
    },
    {
        url: './2/2156.png',
        name: 'BSTC#2156',
        rank: 'Rank2141',
    },
    {
        url: './2/2330.png',
        name: 'BSTC#2330',
        rank: 'Rank2142',
    },
    {
        url: './2/2522.png',
        name: 'BSTC#2522',
        rank: 'Rank2143',
    },
    {
        url: './2/2337.png',
        name: 'BSTC#2337',
        rank: 'Rank2144',
    },
    {
        url: './2/550.png',
        name: 'BSTC#550',
        rank: 'Rank2145',
    },
    {
        url: './2/1240.png',
        name: 'BSTC#1240',
        rank: 'Rank2146',
    },
    {
        url: './2/1434.png',
        name: 'BSTC#1434',
        rank: 'Rank2147',
    },
    {
        url: './2/2618.png',
        name: 'BSTC#2618',
        rank: 'Rank2148',
    },
    {
        url: './2/2637.png',
        name: 'BSTC#2637',
        rank: 'Rank2149',
    },
    {
        url: './2/2878.png',
        name: 'BSTC#2878',
        rank: 'Rank2150',
    },
    {
        url: './2/3131.png',
        name: 'BSTC#3131',
        rank: 'Rank2151',
    },
    {
        url: './2/1687.png',
        name: 'BSTC#1687',
        rank: 'Rank2152',
    },
    {
        url: './2/1919.png',
        name: 'BSTC#1919',
        rank: 'Rank2153',
    },
    {
        url: './2/1013.png',
        name: 'BSTC#1013',
        rank: 'Rank2154',
    },
    {
        url: './2/1645.png',
        name: 'BSTC#1645',
        rank: 'Rank2155',
    },
    {
        url: './2/99.png',
        name: 'BSTC#99',
        rank: 'Rank2156',
    },
    {
        url: './2/841.png',
        name: 'BSTC#841',
        rank: 'Rank2157',
    },
    {
        url: './2/1410.png',
        name: 'BSTC#1410',
        rank: 'Rank2158',
    },
    {
        url: './2/2993.png',
        name: 'BSTC#2993',
        rank: 'Rank2159',
    },
    {
        url: './2/2363.png',
        name: 'BSTC#2363',
        rank: 'Rank2160',
    },
    {
        url: './2/2846.png',
        name: 'BSTC#2846',
        rank: 'Rank2161',
    },
    {
        url: './2/2909.png',
        name: 'BSTC#2909',
        rank: 'Rank2162',
    },
    {
        url: './2/2582.png',
        name: 'BSTC#2582',
        rank: 'Rank2163',
    },
    {
        url: './2/2990.png',
        name: 'BSTC#2990',
        rank: 'Rank2164',
    },
    {
        url: './2/269.png',
        name: 'BSTC#269',
        rank: 'Rank2165',
    },
    {
        url: './2/1639.png',
        name: 'BSTC#1639',
        rank: 'Rank2166',
    },
    {
        url: './2/340.png',
        name: 'BSTC#340',
        rank: 'Rank2167',
    },
    {
        url: './2/1208.png',
        name: 'BSTC#1208',
        rank: 'Rank2168',
    },
    {
        url: './2/184.png',
        name: 'BSTC#184',
        rank: 'Rank2169',
    },
    {
        url: './2/2029.png',
        name: 'BSTC#2029',
        rank: 'Rank2170',
    },
    {
        url: './2/15.png',
        name: 'BSTC#15',
        rank: 'Rank2171',
    },
    {
        url: './2/1673.png',
        name: 'BSTC#1673',
        rank: 'Rank2172',
    },
    {
        url: './2/1089.png',
        name: 'BSTC#1089',
        rank: 'Rank2173',
    },
    {
        url: './2/1437.png',
        name: 'BSTC#1437',
        rank: 'Rank2174',
    },
    {
        url: './2/1739.png',
        name: 'BSTC#1739',
        rank: 'Rank2175',
    },
    {
        url: './2/2234.png',
        name: 'BSTC#2234',
        rank: 'Rank2176',
    },
    {
        url: './2/750.png',
        name: 'BSTC#750',
        rank: 'Rank2177',
    },
    {
        url: './2/1858.png',
        name: 'BSTC#1858',
        rank: 'Rank2178',
    },
    {
        url: './2/1040.png',
        name: 'BSTC#1040',
        rank: 'Rank2179',
    },
    {
        url: './2/215.png',
        name: 'BSTC#215',
        rank: 'Rank2180',
    },
    {
        url: './2/839.png',
        name: 'BSTC#839',
        rank: 'Rank2181',
    },
    {
        url: './2/969.png',
        name: 'BSTC#969',
        rank: 'Rank2182',
    },
    {
        url: './2/1100.png',
        name: 'BSTC#1100',
        rank: 'Rank2183',
    },
    {
        url: './2/1684.png',
        name: 'BSTC#1684',
        rank: 'Rank2184',
    },
    {
        url: './2/1845.png',
        name: 'BSTC#1845',
        rank: 'Rank2185',
    },
    {
        url: './2/2193.png',
        name: 'BSTC#2193',
        rank: 'Rank2186',
    },
    {
        url: './2/2320.png',
        name: 'BSTC#2320',
        rank: 'Rank2187',
    },
    {
        url: './2/2866.png',
        name: 'BSTC#2866',
        rank: 'Rank2188',
    },
    {
        url: './2/423.png',
        name: 'BSTC#423',
        rank: 'Rank2189',
    },
    {
        url: './2/1024.png',
        name: 'BSTC#1024',
        rank: 'Rank2190',
    },
    {
        url: './2/1913.png',
        name: 'BSTC#1913',
        rank: 'Rank2191',
    },
    {
        url: './2/484.png',
        name: 'BSTC#484',
        rank: 'Rank2192',
    },
    {
        url: './2/882.png',
        name: 'BSTC#882',
        rank: 'Rank2193',
    },
    {
        url: './2/2790.png',
        name: 'BSTC#2790',
        rank: 'Rank2194',
    },
    {
        url: './2/1002.png',
        name: 'BSTC#1002',
        rank: 'Rank2195',
    },
    {
        url: './2/1484.png',
        name: 'BSTC#1484',
        rank: 'Rank2196',
    },
    {
        url: './2/51.png',
        name: 'BSTC#51',
        rank: 'Rank2197',
    },
    {
        url: './2/227.png',
        name: 'BSTC#227',
        rank: 'Rank2198',
    },
    {
        url: './2/1380.png',
        name: 'BSTC#1380',
        rank: 'Rank2199',
    },
    {
        url: './2/1680.png',
        name: 'BSTC#1680',
        rank: 'Rank2200',
    },
    {
        url: './2/1943.png',
        name: 'BSTC#1943',
        rank: 'Rank2201',
    },
    {
        url: './2/1967.png',
        name: 'BSTC#1967',
        rank: 'Rank2202',
    },
    {
        url: './2/2273.png',
        name: 'BSTC#2273',
        rank: 'Rank2203',
    },
    {
        url: './2/2824.png',
        name: 'BSTC#2824',
        rank: 'Rank2204',
    },
    {
        url: './2/648.png',
        name: 'BSTC#648',
        rank: 'Rank2205',
    },
    {
        url: './2/1360.png',
        name: 'BSTC#1360',
        rank: 'Rank2206',
    },
    {
        url: './2/838.png',
        name: 'BSTC#838',
        rank: 'Rank2207',
    },
    {
        url: './2/1428.png',
        name: 'BSTC#1428',
        rank: 'Rank2208',
    },
    {
        url: './2/1890.png',
        name: 'BSTC#1890',
        rank: 'Rank2209',
    },
    {
        url: './2/2165.png',
        name: 'BSTC#2165',
        rank: 'Rank2210',
    },
    {
        url: './2/1721.png',
        name: 'BSTC#1721',
        rank: 'Rank2211',
    },
    {
        url: './2/2648.png',
        name: 'BSTC#2648',
        rank: 'Rank2212',
    },
    {
        url: './2/2155.png',
        name: 'BSTC#2155',
        rank: 'Rank2213',
    },
    {
        url: './2/178.png',
        name: 'BSTC#178',
        rank: 'Rank2214',
    },
    {
        url: './2/387.png',
        name: 'BSTC#387',
        rank: 'Rank2215',
    },
    {
        url: './2/1842.png',
        name: 'BSTC#1842',
        rank: 'Rank2216',
    },
    {
        url: './2/1143.png',
        name: 'BSTC#1143',
        rank: 'Rank2217',
    },
    {
        url: './2/579.png',
        name: 'BSTC#579',
        rank: 'Rank2218',
    },
    {
        url: './2/2730.png',
        name: 'BSTC#2730',
        rank: 'Rank2219',
    },
    {
        url: './2/3053.png',
        name: 'BSTC#3053',
        rank: 'Rank2220',
    },
    {
        url: './2/213.png',
        name: 'BSTC#213',
        rank: 'Rank2221',
    },
    {
        url: './2/69.png',
        name: 'BSTC#69',
        rank: 'Rank2222',
    },
    {
        url: './2/2936.png',
        name: 'BSTC#2936',
        rank: 'Rank2223',
    },
    {
        url: './2/2082.png',
        name: 'BSTC#2082',
        rank: 'Rank2224',
    },
    {
        url: './2/3044.png',
        name: 'BSTC#3044',
        rank: 'Rank2225',
    },
    {
        url: './2/2769.png',
        name: 'BSTC#2769',
        rank: 'Rank2226',
    },
    {
        url: './2/2333.png',
        name: 'BSTC#2333',
        rank: 'Rank2227',
    },
    {
        url: './2/57.png',
        name: 'BSTC#57',
        rank: 'Rank2228',
    },
    {
        url: './2/551.png',
        name: 'BSTC#551',
        rank: 'Rank2229',
    },
    {
        url: './2/835.png',
        name: 'BSTC#835',
        rank: 'Rank2230',
    },
    {
        url: './2/798.png',
        name: 'BSTC#798',
        rank: 'Rank2231',
    },
    {
        url: './2/2359.png',
        name: 'BSTC#2359',
        rank: 'Rank2232',
    },
    {
        url: './2/2786.png',
        name: 'BSTC#2786',
        rank: 'Rank2233',
    },
    {
        url: './2/3128.png',
        name: 'BSTC#3128',
        rank: 'Rank2234',
    },
    {
        url: './2/194.png',
        name: 'BSTC#194',
        rank: 'Rank2235',
    },
    {
        url: './2/2911.png',
        name: 'BSTC#2911',
        rank: 'Rank2236',
    },
    {
        url: './2/1756.png',
        name: 'BSTC#1756',
        rank: 'Rank2237',
    },
    {
        url: './2/753.png',
        name: 'BSTC#753',
        rank: 'Rank2238',
    },
    {
        url: './2/1526.png',
        name: 'BSTC#1526',
        rank: 'Rank2239',
    },
    {
        url: './2/2999.png',
        name: 'BSTC#2999',
        rank: 'Rank2240',
    },
    {
        url: './2/760.png',
        name: 'BSTC#760',
        rank: 'Rank2241',
    },
    {
        url: './2/1464.png',
        name: 'BSTC#1464',
        rank: 'Rank2242',
    },
    {
        url: './2/281.png',
        name: 'BSTC#281',
        rank: 'Rank2243',
    },
    {
        url: './2/689.png',
        name: 'BSTC#689',
        rank: 'Rank2244',
    },
    {
        url: './2/2462.png',
        name: 'BSTC#2462',
        rank: 'Rank2245',
    },
    {
        url: './2/240.png',
        name: 'BSTC#240',
        rank: 'Rank2246',
    },
    {
        url: './2/1254.png',
        name: 'BSTC#1254',
        rank: 'Rank2247',
    },
    {
        url: './2/1264.png',
        name: 'BSTC#1264',
        rank: 'Rank2248',
    },
    {
        url: './2/2542.png',
        name: 'BSTC#2542',
        rank: 'Rank2249',
    },
    {
        url: './2/324.png',
        name: 'BSTC#324',
        rank: 'Rank2250',
    },
    {
        url: './2/2847.png',
        name: 'BSTC#2847',
        rank: 'Rank2251',
    },
    {
        url: './2/3.png',
        name: 'BSTC#3',
        rank: 'Rank2252',
    },
    {
        url: './2/1390.png',
        name: 'BSTC#1390',
        rank: 'Rank2253',
    },
    {
        url: './2/1891.png',
        name: 'BSTC#1891',
        rank: 'Rank2254',
    },
    {
        url: './2/2070.png',
        name: 'BSTC#2070',
        rank: 'Rank2255',
    },
    {
        url: './2/1493.png',
        name: 'BSTC#1493',
        rank: 'Rank2256',
    },
    {
        url: './2/1108.png',
        name: 'BSTC#1108',
        rank: 'Rank2257',
    },
    {
        url: './2/1977.png',
        name: 'BSTC#1977',
        rank: 'Rank2258',
    },
    {
        url: './2/2291.png',
        name: 'BSTC#2291',
        rank: 'Rank2259',
    },
    {
        url: './2/2210.png',
        name: 'BSTC#2210',
        rank: 'Rank2260',
    },
    {
        url: './2/2949.png',
        name: 'BSTC#2949',
        rank: 'Rank2261',
    },
    {
        url: './2/197.png',
        name: 'BSTC#197',
        rank: 'Rank2262',
    },
    {
        url: './2/17.png',
        name: 'BSTC#17',
        rank: 'Rank2263',
    },
    {
        url: './2/1960.png',
        name: 'BSTC#1960',
        rank: 'Rank2264',
    },
    {
        url: './2/1966.png',
        name: 'BSTC#1966',
        rank: 'Rank2265',
    },
    {
        url: './2/2657.png',
        name: 'BSTC#2657',
        rank: 'Rank2266',
    },
    {
        url: './2/2946.png',
        name: 'BSTC#2946',
        rank: 'Rank2267',
    },
    {
        url: './2/2972.png',
        name: 'BSTC#2972',
        rank: 'Rank2268',
    },
    {
        url: './2/466.png',
        name: 'BSTC#466',
        rank: 'Rank2269',
    },
    {
        url: './2/2548.png',
        name: 'BSTC#2548',
        rank: 'Rank2270',
    },
    {
        url: './2/2576.png',
        name: 'BSTC#2576',
        rank: 'Rank2271',
    },
    {
        url: './2/1867.png',
        name: 'BSTC#1867',
        rank: 'Rank2272',
    },
    {
        url: './2/2992.png',
        name: 'BSTC#2992',
        rank: 'Rank2273',
    },
    {
        url: './2/2093.png',
        name: 'BSTC#2093',
        rank: 'Rank2274',
    },
    {
        url: './2/108.png',
        name: 'BSTC#108',
        rank: 'Rank2275',
    },
    {
        url: './2/1900.png',
        name: 'BSTC#1900',
        rank: 'Rank2276',
    },
    {
        url: './2/289.png',
        name: 'BSTC#289',
        rank: 'Rank2277',
    },
    {
        url: './2/363.png',
        name: 'BSTC#363',
        rank: 'Rank2278',
    },
    {
        url: './2/727.png',
        name: 'BSTC#727',
        rank: 'Rank2279',
    },
    {
        url: './2/921.png',
        name: 'BSTC#921',
        rank: 'Rank2280',
    },
    {
        url: './2/935.png',
        name: 'BSTC#935',
        rank: 'Rank2281',
    },
    {
        url: './2/1531.png',
        name: 'BSTC#1531',
        rank: 'Rank2282',
    },
    {
        url: './2/2222.png',
        name: 'BSTC#2222',
        rank: 'Rank2283',
    },
    {
        url: './2/2327.png',
        name: 'BSTC#2327',
        rank: 'Rank2284',
    },
    {
        url: './2/2421.png',
        name: 'BSTC#2421',
        rank: 'Rank2285',
    },
    {
        url: './2/339.png',
        name: 'BSTC#339',
        rank: 'Rank2286',
    },
    {
        url: './2/1892.png',
        name: 'BSTC#1892',
        rank: 'Rank2287',
    },
    {
        url: './2/473.png',
        name: 'BSTC#473',
        rank: 'Rank2288',
    },
    {
        url: './2/2204.png',
        name: 'BSTC#2204',
        rank: 'Rank2289',
    },
    {
        url: './2/1117.png',
        name: 'BSTC#1117',
        rank: 'Rank2290',
    },
    {
        url: './2/1570.png',
        name: 'BSTC#1570',
        rank: 'Rank2291',
    },
    {
        url: './2/975.png',
        name: 'BSTC#975',
        rank: 'Rank2292',
    },
    {
        url: './2/1864.png',
        name: 'BSTC#1864',
        rank: 'Rank2293',
    },
    {
        url: './2/8.png',
        name: 'BSTC#8',
        rank: 'Rank2294',
    },
    {
        url: './2/516.png',
        name: 'BSTC#516',
        rank: 'Rank2295',
    },
    {
        url: './2/555.png',
        name: 'BSTC#555',
        rank: 'Rank2296',
    },
    {
        url: './2/2114.png',
        name: 'BSTC#2114',
        rank: 'Rank2297',
    },
    {
        url: './2/225.png',
        name: 'BSTC#225',
        rank: 'Rank2298',
    },
    {
        url: './2/1030.png',
        name: 'BSTC#1030',
        rank: 'Rank2299',
    },
    {
        url: './2/1098.png',
        name: 'BSTC#1098',
        rank: 'Rank2300',
    },
    {
        url: './2/3137.png',
        name: 'BSTC#3137',
        rank: 'Rank2301',
    },
    {
        url: './2/1406.png',
        name: 'BSTC#1406',
        rank: 'Rank2302',
    },
    {
        url: './2/392.png',
        name: 'BSTC#392',
        rank: 'Rank2303',
    },
    {
        url: './2/3026.png',
        name: 'BSTC#3026',
        rank: 'Rank2304',
    },
    {
        url: './2/1497.png',
        name: 'BSTC#1497',
        rank: 'Rank2305',
    },
    {
        url: './2/905.png',
        name: 'BSTC#905',
        rank: 'Rank2306',
    },
    {
        url: './2/1879.png',
        name: 'BSTC#1879',
        rank: 'Rank2307',
    },
    {
        url: './2/2247.png',
        name: 'BSTC#2247',
        rank: 'Rank2308',
    },
    {
        url: './2/3078.png',
        name: 'BSTC#3078',
        rank: 'Rank2309',
    },
    {
        url: './2/345.png',
        name: 'BSTC#345',
        rank: 'Rank2310',
    },
    {
        url: './2/616.png',
        name: 'BSTC#616',
        rank: 'Rank2311',
    },
    {
        url: './2/1638.png',
        name: 'BSTC#1638',
        rank: 'Rank2312',
    },
    {
        url: './2/1288.png',
        name: 'BSTC#1288',
        rank: 'Rank2313',
    },
    {
        url: './2/1154.png',
        name: 'BSTC#1154',
        rank: 'Rank2314',
    },
    {
        url: './2/2740.png',
        name: 'BSTC#2740',
        rank: 'Rank2315',
    },
    {
        url: './2/2760.png',
        name: 'BSTC#2760',
        rank: 'Rank2316',
    },
    {
        url: './2/2770.png',
        name: 'BSTC#2770',
        rank: 'Rank2317',
    },
    {
        url: './2/1136.png',
        name: 'BSTC#1136',
        rank: 'Rank2318',
    },
    {
        url: './2/1651.png',
        name: 'BSTC#1651',
        rank: 'Rank2319',
    },
    {
        url: './2/1873.png',
        name: 'BSTC#1873',
        rank: 'Rank2320',
    },
    {
        url: './2/2147.png',
        name: 'BSTC#2147',
        rank: 'Rank2321',
    },
    {
        url: './2/1467.png',
        name: 'BSTC#1467',
        rank: 'Rank2322',
    },
    {
        url: './2/2801.png',
        name: 'BSTC#2801',
        rank: 'Rank2323',
    },
    {
        url: './2/852.png',
        name: 'BSTC#852',
        rank: 'Rank2324',
    },
    {
        url: './2/1058.png',
        name: 'BSTC#1058',
        rank: 'Rank2325',
    },
    {
        url: './2/1835.png',
        name: 'BSTC#1835',
        rank: 'Rank2326',
    },
    {
        url: './2/388.png',
        name: 'BSTC#388',
        rank: 'Rank2327',
    },
    {
        url: './2/3120.png',
        name: 'BSTC#3120',
        rank: 'Rank2328',
    },
    {
        url: './2/2229.png',
        name: 'BSTC#2229',
        rank: 'Rank2329',
    },
    {
        url: './2/756.png',
        name: 'BSTC#756',
        rank: 'Rank2330',
    },
    {
        url: './2/1354.png',
        name: 'BSTC#1354',
        rank: 'Rank2331',
    },
    {
        url: './2/1832.png',
        name: 'BSTC#1832',
        rank: 'Rank2332',
    },
    {
        url: './2/681.png',
        name: 'BSTC#681',
        rank: 'Rank2333',
    },
    {
        url: './2/855.png',
        name: 'BSTC#855',
        rank: 'Rank2334',
    },
    {
        url: './2/1025.png',
        name: 'BSTC#1025',
        rank: 'Rank2335',
    },
    {
        url: './2/1151.png',
        name: 'BSTC#1151',
        rank: 'Rank2336',
    },
    {
        url: './2/2478.png',
        name: 'BSTC#2478',
        rank: 'Rank2337',
    },
    {
        url: './2/226.png',
        name: 'BSTC#226',
        rank: 'Rank2338',
    },
    {
        url: './2/436.png',
        name: 'BSTC#436',
        rank: 'Rank2339',
    },
    {
        url: './2/2525.png',
        name: 'BSTC#2525',
        rank: 'Rank2340',
    },
    {
        url: './2/1470.png',
        name: 'BSTC#1470',
        rank: 'Rank2341',
    },
    {
        url: './2/2201.png',
        name: 'BSTC#2201',
        rank: 'Rank2342',
    },
    {
        url: './2/2446.png',
        name: 'BSTC#2446',
        rank: 'Rank2343',
    },
    {
        url: './2/1432.png',
        name: 'BSTC#1432',
        rank: 'Rank2344',
    },
    {
        url: './2/1723.png',
        name: 'BSTC#1723',
        rank: 'Rank2345',
    },
    {
        url: './2/2196.png',
        name: 'BSTC#2196',
        rank: 'Rank2346',
    },
    {
        url: './2/1519.png',
        name: 'BSTC#1519',
        rank: 'Rank2347',
    },
    {
        url: './2/1353.png',
        name: 'BSTC#1353',
        rank: 'Rank2348',
    },
    {
        url: './2/1642.png',
        name: 'BSTC#1642',
        rank: 'Rank2349',
    },
    {
        url: './2/2803.png',
        name: 'BSTC#2803',
        rank: 'Rank2350',
    },
    {
        url: './2/531.png',
        name: 'BSTC#531',
        rank: 'Rank2351',
    },
    {
        url: './2/2495.png',
        name: 'BSTC#2495',
        rank: 'Rank2352',
    },
    {
        url: './2/307.png',
        name: 'BSTC#307',
        rank: 'Rank2353',
    },
    {
        url: './2/1552.png',
        name: 'BSTC#1552',
        rank: 'Rank2354',
    },
    {
        url: './2/1320.png',
        name: 'BSTC#1320',
        rank: 'Rank2355',
    },
    {
        url: './2/2060.png',
        name: 'BSTC#2060',
        rank: 'Rank2356',
    },
    {
        url: './2/2013.png',
        name: 'BSTC#2013',
        rank: 'Rank2357',
    },
    {
        url: './2/1235.png',
        name: 'BSTC#1235',
        rank: 'Rank2358',
    },
    {
        url: './2/303.png',
        name: 'BSTC#303',
        rank: 'Rank2359',
    },
    {
        url: './2/343.png',
        name: 'BSTC#343',
        rank: 'Rank2360',
    },
    {
        url: './2/1424.png',
        name: 'BSTC#1424',
        rank: 'Rank2361',
    },
    {
        url: './2/845.png',
        name: 'BSTC#845',
        rank: 'Rank2362',
    },
    {
        url: './2/2109.png',
        name: 'BSTC#2109',
        rank: 'Rank2363',
    },
    {
        url: './2/2417.png',
        name: 'BSTC#2417',
        rank: 'Rank2364',
    },
    {
        url: './2/2880.png',
        name: 'BSTC#2880',
        rank: 'Rank2365',
    },
    {
        url: './2/322.png',
        name: 'BSTC#322',
        rank: 'Rank2366',
    },
    {
        url: './2/460.png',
        name: 'BSTC#460',
        rank: 'Rank2367',
    },
    {
        url: './2/1447.png',
        name: 'BSTC#1447',
        rank: 'Rank2368',
    },
    {
        url: './2/2172.png',
        name: 'BSTC#2172',
        rank: 'Rank2369',
    },
    {
        url: './2/3100.png',
        name: 'BSTC#3100',
        rank: 'Rank2370',
    },
    {
        url: './2/2289.png',
        name: 'BSTC#2289',
        rank: 'Rank2371',
    },
    {
        url: './2/205.png',
        name: 'BSTC#205',
        rank: 'Rank2372',
    },
    {
        url: './2/794.png',
        name: 'BSTC#794',
        rank: 'Rank2373',
    },
    {
        url: './2/1586.png',
        name: 'BSTC#1586',
        rank: 'Rank2374',
    },
    {
        url: './2/951.png',
        name: 'BSTC#951',
        rank: 'Rank2375',
    },
    {
        url: './2/209.png',
        name: 'BSTC#209',
        rank: 'Rank2376',
    },
    {
        url: './2/544.png',
        name: 'BSTC#544',
        rank: 'Rank2377',
    },
    {
        url: './2/1874.png',
        name: 'BSTC#1874',
        rank: 'Rank2378',
    },
    {
        url: './2/2131.png',
        name: 'BSTC#2131',
        rank: 'Rank2379',
    },
    {
        url: './2/1331.png',
        name: 'BSTC#1331',
        rank: 'Rank2380',
    },
    {
        url: './2/217.png',
        name: 'BSTC#217',
        rank: 'Rank2381',
    },
    {
        url: './2/302.png',
        name: 'BSTC#302',
        rank: 'Rank2382',
    },
    {
        url: './2/859.png',
        name: 'BSTC#859',
        rank: 'Rank2383',
    },
    {
        url: './2/1239.png',
        name: 'BSTC#1239',
        rank: 'Rank2384',
    },
    {
        url: './2/1392.png',
        name: 'BSTC#1392',
        rank: 'Rank2385',
    },
    {
        url: './2/1933.png',
        name: 'BSTC#1933',
        rank: 'Rank2386',
    },
    {
        url: './2/2092.png',
        name: 'BSTC#2092',
        rank: 'Rank2387',
    },
    {
        url: './2/2257.png',
        name: 'BSTC#2257',
        rank: 'Rank2388',
    },
    {
        url: './2/2669.png',
        name: 'BSTC#2669',
        rank: 'Rank2389',
    },
    {
        url: './2/1700.png',
        name: 'BSTC#1700',
        rank: 'Rank2390',
    },
    {
        url: './2/1633.png',
        name: 'BSTC#1633',
        rank: 'Rank2391',
    },
    {
        url: './2/971.png',
        name: 'BSTC#971',
        rank: 'Rank2392',
    },
    {
        url: './2/986.png',
        name: 'BSTC#986',
        rank: 'Rank2393',
    },
    {
        url: './2/2746.png',
        name: 'BSTC#2746',
        rank: 'Rank2394',
    },
    {
        url: './2/2759.png',
        name: 'BSTC#2759',
        rank: 'Rank2395',
    },
    {
        url: './2/2860.png',
        name: 'BSTC#2860',
        rank: 'Rank2396',
    },
    {
        url: './2/3068.png',
        name: 'BSTC#3068',
        rank: 'Rank2397',
    },
    {
        url: './2/3133.png',
        name: 'BSTC#3133',
        rank: 'Rank2398',
    },
    {
        url: './2/127.png',
        name: 'BSTC#127',
        rank: 'Rank2399',
    },
    {
        url: './2/2942.png',
        name: 'BSTC#2942',
        rank: 'Rank2400',
    },
    {
        url: './2/525.png',
        name: 'BSTC#525',
        rank: 'Rank2401',
    },
    {
        url: './2/2499.png',
        name: 'BSTC#2499',
        rank: 'Rank2402',
    },
    {
        url: './2/2424.png',
        name: 'BSTC#2424',
        rank: 'Rank2403',
    },
    {
        url: './2/1153.png',
        name: 'BSTC#1153',
        rank: 'Rank2404',
    },
    {
        url: './2/966.png',
        name: 'BSTC#966',
        rank: 'Rank2405',
    },
    {
        url: './2/3011.png',
        name: 'BSTC#3011',
        rank: 'Rank2406',
    },
    {
        url: './2/27.png',
        name: 'BSTC#27',
        rank: 'Rank2407',
    },
    {
        url: './2/1345.png',
        name: 'BSTC#1345',
        rank: 'Rank2408',
    },
    {
        url: './2/2090.png',
        name: 'BSTC#2090',
        rank: 'Rank2409',
    },
    {
        url: './2/2503.png',
        name: 'BSTC#2503',
        rank: 'Rank2410',
    },
    {
        url: './2/114.png',
        name: 'BSTC#114',
        rank: 'Rank2411',
    },
    {
        url: './2/2819.png',
        name: 'BSTC#2819',
        rank: 'Rank2412',
    },
    {
        url: './2/527.png',
        name: 'BSTC#527',
        rank: 'Rank2413',
    },
    {
        url: './2/1551.png',
        name: 'BSTC#1551',
        rank: 'Rank2414',
    },
    {
        url: './2/2887.png',
        name: 'BSTC#2887',
        rank: 'Rank2415',
    },
    {
        url: './2/1274.png',
        name: 'BSTC#1274',
        rank: 'Rank2416',
    },
    {
        url: './2/1321.png',
        name: 'BSTC#1321',
        rank: 'Rank2417',
    },
    {
        url: './2/2399.png',
        name: 'BSTC#2399',
        rank: 'Rank2418',
    },
    {
        url: './2/1824.png',
        name: 'BSTC#1824',
        rank: 'Rank2419',
    },
    {
        url: './2/2450.png',
        name: 'BSTC#2450',
        rank: 'Rank2420',
    },
    {
        url: './2/1583.png',
        name: 'BSTC#1583',
        rank: 'Rank2421',
    },
    {
        url: './2/1183.png',
        name: 'BSTC#1183',
        rank: 'Rank2422',
    },
    {
        url: './2/940.png',
        name: 'BSTC#940',
        rank: 'Rank2423',
    },
    {
        url: './2/1481.png',
        name: 'BSTC#1481',
        rank: 'Rank2424',
    },
    {
        url: './2/936.png',
        name: 'BSTC#936',
        rank: 'Rank2425',
    },
    {
        url: './2/942.png',
        name: 'BSTC#942',
        rank: 'Rank2426',
    },
    {
        url: './2/972.png',
        name: 'BSTC#972',
        rank: 'Rank2427',
    },
    {
        url: './2/1658.png',
        name: 'BSTC#1658',
        rank: 'Rank2428',
    },
    {
        url: './2/2087.png',
        name: 'BSTC#2087',
        rank: 'Rank2429',
    },
    {
        url: './2/2115.png',
        name: 'BSTC#2115',
        rank: 'Rank2430',
    },
    {
        url: './2/2600.png',
        name: 'BSTC#2600',
        rank: 'Rank2431',
    },
    {
        url: './2/1332.png',
        name: 'BSTC#1332',
        rank: 'Rank2432',
    },
    {
        url: './2/1342.png',
        name: 'BSTC#1342',
        rank: 'Rank2433',
    },
    {
        url: './2/2628.png',
        name: 'BSTC#2628',
        rank: 'Rank2434',
    },
    {
        url: './2/11.png',
        name: 'BSTC#11',
        rank: 'Rank2435',
    },
    {
        url: './2/1080.png',
        name: 'BSTC#1080',
        rank: 'Rank2436',
    },
    {
        url: './2/606.png',
        name: 'BSTC#606',
        rank: 'Rank2437',
    },
    {
        url: './2/2479.png',
        name: 'BSTC#2479',
        rank: 'Rank2438',
    },
    {
        url: './2/1961.png',
        name: 'BSTC#1961',
        rank: 'Rank2439',
    },
    {
        url: './2/291.png',
        name: 'BSTC#291',
        rank: 'Rank2440',
    },
    {
        url: './2/988.png',
        name: 'BSTC#988',
        rank: 'Rank2441',
    },
    {
        url: './2/2022.png',
        name: 'BSTC#2022',
        rank: 'Rank2442',
    },
    {
        url: './2/2566.png',
        name: 'BSTC#2566',
        rank: 'Rank2443',
    },
    {
        url: './2/574.png',
        name: 'BSTC#574',
        rank: 'Rank2444',
    },
    {
        url: './2/660.png',
        name: 'BSTC#660',
        rank: 'Rank2445',
    },
    {
        url: './2/2445.png',
        name: 'BSTC#2445',
        rank: 'Rank2446',
    },
    {
        url: './2/2436.png',
        name: 'BSTC#2436',
        rank: 'Rank2447',
    },
    {
        url: './2/744.png',
        name: 'BSTC#744',
        rank: 'Rank2448',
    },
    {
        url: './2/1334.png',
        name: 'BSTC#1334',
        rank: 'Rank2449',
    },
    {
        url: './2/1703.png',
        name: 'BSTC#1703',
        rank: 'Rank2450',
    },
    {
        url: './2/2003.png',
        name: 'BSTC#2003',
        rank: 'Rank2451',
    },
    {
        url: './2/2677.png',
        name: 'BSTC#2677',
        rank: 'Rank2452',
    },
    {
        url: './2/3073.png',
        name: 'BSTC#3073',
        rank: 'Rank2453',
    },
    {
        url: './2/415.png',
        name: 'BSTC#415',
        rank: 'Rank2454',
    },
    {
        url: './2/740.png',
        name: 'BSTC#740',
        rank: 'Rank2455',
    },
    {
        url: './2/1414.png',
        name: 'BSTC#1414',
        rank: 'Rank2456',
    },
    {
        url: './2/2654.png',
        name: 'BSTC#2654',
        rank: 'Rank2457',
    },
    {
        url: './2/2821.png',
        name: 'BSTC#2821',
        rank: 'Rank2458',
    },
    {
        url: './2/2182.png',
        name: 'BSTC#2182',
        rank: 'Rank2459',
    },
    {
        url: './2/1020.png',
        name: 'BSTC#1020',
        rank: 'Rank2460',
    },
    {
        url: './2/479.png',
        name: 'BSTC#479',
        rank: 'Rank2461',
    },
    {
        url: './2/553.png',
        name: 'BSTC#553',
        rank: 'Rank2462',
    },
    {
        url: './2/2171.png',
        name: 'BSTC#2171',
        rank: 'Rank2463',
    },
    {
        url: './2/2218.png',
        name: 'BSTC#2218',
        rank: 'Rank2464',
    },
    {
        url: './2/1435.png',
        name: 'BSTC#1435',
        rank: 'Rank2465',
    },
    {
        url: './2/3005.png',
        name: 'BSTC#3005',
        rank: 'Rank2466',
    },
    {
        url: './2/187.png',
        name: 'BSTC#187',
        rank: 'Rank2467',
    },
    {
        url: './2/351.png',
        name: 'BSTC#351',
        rank: 'Rank2468',
    },
    {
        url: './2/2857.png',
        name: 'BSTC#2857',
        rank: 'Rank2469',
    },
    {
        url: './2/1757.png',
        name: 'BSTC#1757',
        rank: 'Rank2470',
    },
    {
        url: './2/1000.png',
        name: 'BSTC#1000',
        rank: 'Rank2471',
    },
    {
        url: './2/428.png',
        name: 'BSTC#428',
        rank: 'Rank2472',
    },
    {
        url: './2/2543.png',
        name: 'BSTC#2543',
        rank: 'Rank2473',
    },
    {
        url: './2/1477.png',
        name: 'BSTC#1477',
        rank: 'Rank2474',
    },
    {
        url: './2/2384.png',
        name: 'BSTC#2384',
        rank: 'Rank2475',
    },
    {
        url: './2/2721.png',
        name: 'BSTC#2721',
        rank: 'Rank2476',
    },
    {
        url: './2/478.png',
        name: 'BSTC#478',
        rank: 'Rank2477',
    },
    {
        url: './2/1144.png',
        name: 'BSTC#1144',
        rank: 'Rank2478',
    },
    {
        url: './2/1560.png',
        name: 'BSTC#1560',
        rank: 'Rank2479',
    },
    {
        url: './2/1624.png',
        name: 'BSTC#1624',
        rank: 'Rank2480',
    },
    {
        url: './2/1641.png',
        name: 'BSTC#1641',
        rank: 'Rank2481',
    },
    {
        url: './2/2134.png',
        name: 'BSTC#2134',
        rank: 'Rank2482',
    },
    {
        url: './2/2877.png',
        name: 'BSTC#2877',
        rank: 'Rank2483',
    },
    {
        url: './2/781.png',
        name: 'BSTC#781',
        rank: 'Rank2484',
    },
    {
        url: './2/1537.png',
        name: 'BSTC#1537',
        rank: 'Rank2485',
    },
    {
        url: './2/2122.png',
        name: 'BSTC#2122',
        rank: 'Rank2486',
    },
    {
        url: './2/1045.png',
        name: 'BSTC#1045',
        rank: 'Rank2487',
    },
    {
        url: './2/2810.png',
        name: 'BSTC#2810',
        rank: 'Rank2488',
    },
    {
        url: './2/2489.png',
        name: 'BSTC#2489',
        rank: 'Rank2489',
    },
    {
        url: './2/895.png',
        name: 'BSTC#895',
        rank: 'Rank2490',
    },
    {
        url: './2/1375.png',
        name: 'BSTC#1375',
        rank: 'Rank2491',
    },
    {
        url: './2/2041.png',
        name: 'BSTC#2041',
        rank: 'Rank2492',
    },
    {
        url: './2/2313.png',
        name: 'BSTC#2313',
        rank: 'Rank2493',
    },
    {
        url: './2/2808.png',
        name: 'BSTC#2808',
        rank: 'Rank2494',
    },
    {
        url: './2/2258.png',
        name: 'BSTC#2258',
        rank: 'Rank2495',
    },
    {
        url: './2/1711.png',
        name: 'BSTC#1711',
        rank: 'Rank2496',
    },
    {
        url: './2/2170.png',
        name: 'BSTC#2170',
        rank: 'Rank2497',
    },
    {
        url: './2/1561.png',
        name: 'BSTC#1561',
        rank: 'Rank2498',
    },
    {
        url: './2/1198.png',
        name: 'BSTC#1198',
        rank: 'Rank2499',
    },
    {
        url: './2/105.png',
        name: 'BSTC#105',
        rank: 'Rank2500',
    },
    {
        url: './2/626.png',
        name: 'BSTC#626',
        rank: 'Rank2501',
    },
    {
        url: './2/2897.png',
        name: 'BSTC#2897',
        rank: 'Rank2502',
    },
    {
        url: './2/1378.png',
        name: 'BSTC#1378',
        rank: 'Rank2503',
    },
    {
        url: './2/2443.png',
        name: 'BSTC#2443',
        rank: 'Rank2504',
    },
    {
        url: './2/996.png',
        name: 'BSTC#996',
        rank: 'Rank2505',
    },
    {
        url: './2/1336.png',
        name: 'BSTC#1336',
        rank: 'Rank2506',
    },
    {
        url: './2/1355.png',
        name: 'BSTC#1355',
        rank: 'Rank2507',
    },
    {
        url: './2/1489.png',
        name: 'BSTC#1489',
        rank: 'Rank2508',
    },
    {
        url: './2/2299.png',
        name: 'BSTC#2299',
        rank: 'Rank2509',
    },
    {
        url: './2/2150.png',
        name: 'BSTC#2150',
        rank: 'Rank2510',
    },
    {
        url: './2/950.png',
        name: 'BSTC#950',
        rank: 'Rank2511',
    },
    {
        url: './2/1829.png',
        name: 'BSTC#1829',
        rank: 'Rank2512',
    },
    {
        url: './2/2590.png',
        name: 'BSTC#2590',
        rank: 'Rank2513',
    },
    {
        url: './2/115.png',
        name: 'BSTC#115',
        rank: 'Rank2514',
    },
    {
        url: './2/649.png',
        name: 'BSTC#649',
        rank: 'Rank2515',
    },
    {
        url: './2/1137.png',
        name: 'BSTC#1137',
        rank: 'Rank2516',
    },
    {
        url: './2/2157.png',
        name: 'BSTC#2157',
        rank: 'Rank2517',
    },
    {
        url: './2/2890.png',
        name: 'BSTC#2890',
        rank: 'Rank2518',
    },
    {
        url: './2/634.png',
        name: 'BSTC#634',
        rank: 'Rank2519',
    },
    {
        url: './2/1178.png',
        name: 'BSTC#1178',
        rank: 'Rank2520',
    },
    {
        url: './2/1417.png',
        name: 'BSTC#1417',
        rank: 'Rank2521',
    },
    {
        url: './2/2076.png',
        name: 'BSTC#2076',
        rank: 'Rank2522',
    },
    {
        url: './2/2899.png',
        name: 'BSTC#2899',
        rank: 'Rank2523',
    },
    {
        url: './2/3136.png',
        name: 'BSTC#3136',
        rank: 'Rank2524',
    },
    {
        url: './2/471.png',
        name: 'BSTC#471',
        rank: 'Rank2525',
    },
    {
        url: './2/837.png',
        name: 'BSTC#837',
        rank: 'Rank2526',
    },
    {
        url: './2/91.png',
        name: 'BSTC#91',
        rank: 'Rank2527',
    },
    {
        url: './2/1618.png',
        name: 'BSTC#1618',
        rank: 'Rank2528',
    },
    {
        url: './2/1812.png',
        name: 'BSTC#1812',
        rank: 'Rank2529',
    },
    {
        url: './2/1772.png',
        name: 'BSTC#1772',
        rank: 'Rank2530',
    },
    {
        url: './2/2748.png',
        name: 'BSTC#2748',
        rank: 'Rank2531',
    },
    {
        url: './2/1761.png',
        name: 'BSTC#1761',
        rank: 'Rank2532',
    },
    {
        url: './2/1388.png',
        name: 'BSTC#1388',
        rank: 'Rank2533',
    },
    {
        url: './2/126.png',
        name: 'BSTC#126',
        rank: 'Rank2534',
    },
    {
        url: './2/1063.png',
        name: 'BSTC#1063',
        rank: 'Rank2535',
    },
    {
        url: './2/2844.png',
        name: 'BSTC#2844',
        rank: 'Rank2536',
    },
    {
        url: './2/2611.png',
        name: 'BSTC#2611',
        rank: 'Rank2537',
    },
    {
        url: './2/3016.png',
        name: 'BSTC#3016',
        rank: 'Rank2538',
    },
    {
        url: './2/338.png',
        name: 'BSTC#338',
        rank: 'Rank2539',
    },
    {
        url: './2/400.png',
        name: 'BSTC#400',
        rank: 'Rank2540',
    },
    {
        url: './2/441.png',
        name: 'BSTC#441',
        rank: 'Rank2541',
    },
    {
        url: './2/601.png',
        name: 'BSTC#601',
        rank: 'Rank2542',
    },
    {
        url: './2/1675.png',
        name: 'BSTC#1675',
        rank: 'Rank2543',
    },
    {
        url: './2/2176.png',
        name: 'BSTC#2176',
        rank: 'Rank2544',
    },
    {
        url: './2/688.png',
        name: 'BSTC#688',
        rank: 'Rank2545',
    },
    {
        url: './2/132.png',
        name: 'BSTC#132',
        rank: 'Rank2546',
    },
    {
        url: './2/2870.png',
        name: 'BSTC#2870',
        rank: 'Rank2547',
    },
    {
        url: './2/2166.png',
        name: 'BSTC#2166',
        rank: 'Rank2548',
    },
    {
        url: './2/1956.png',
        name: 'BSTC#1956',
        rank: 'Rank2549',
    },
    {
        url: './2/2729.png',
        name: 'BSTC#2729',
        rank: 'Rank2550',
    },
    {
        url: './2/3018.png',
        name: 'BSTC#3018',
        rank: 'Rank2551',
    },
    {
        url: './2/784.png',
        name: 'BSTC#784',
        rank: 'Rank2552',
    },
    {
        url: './2/321.png',
        name: 'BSTC#321',
        rank: 'Rank2553',
    },
    {
        url: './2/1539.png',
        name: 'BSTC#1539',
        rank: 'Rank2554',
    },
    {
        url: './2/2474.png',
        name: 'BSTC#2474',
        rank: 'Rank2555',
    },
    {
        url: './2/1194.png',
        name: 'BSTC#1194',
        rank: 'Rank2556',
    },
    {
        url: './2/3122.png',
        name: 'BSTC#3122',
        rank: 'Rank2557',
    },
    {
        url: './2/980.png',
        name: 'BSTC#980',
        rank: 'Rank2558',
    },
    {
        url: './2/2033.png',
        name: 'BSTC#2033',
        rank: 'Rank2559',
    },
    {
        url: './2/850.png',
        name: 'BSTC#850',
        rank: 'Rank2560',
    },
    {
        url: './2/1413.png',
        name: 'BSTC#1413',
        rank: 'Rank2561',
    },
    {
        url: './2/1500.png',
        name: 'BSTC#1500',
        rank: 'Rank2562',
    },
    {
        url: './2/1271.png',
        name: 'BSTC#1271',
        rank: 'Rank2563',
    },
    {
        url: './2/2663.png',
        name: 'BSTC#2663',
        rank: 'Rank2564',
    },
    {
        url: './2/770.png',
        name: 'BSTC#770',
        rank: 'Rank2565',
    },
    {
        url: './2/1290.png',
        name: 'BSTC#1290',
        rank: 'Rank2566',
    },
    {
        url: './2/2163.png',
        name: 'BSTC#2163',
        rank: 'Rank2567',
    },
    {
        url: './2/2583.png',
        name: 'BSTC#2583',
        rank: 'Rank2568',
    },
    {
        url: './2/121.png',
        name: 'BSTC#121',
        rank: 'Rank2569',
    },
    {
        url: './2/2960.png',
        name: 'BSTC#2960',
        rank: 'Rank2570',
    },
    {
        url: './2/2978.png',
        name: 'BSTC#2978',
        rank: 'Rank2571',
    },
    {
        url: './2/967.png',
        name: 'BSTC#967',
        rank: 'Rank2572',
    },
    {
        url: './2/1061.png',
        name: 'BSTC#1061',
        rank: 'Rank2573',
    },
    {
        url: './2/294.png',
        name: 'BSTC#294',
        rank: 'Rank2574',
    },
    {
        url: './2/714.png',
        name: 'BSTC#714',
        rank: 'Rank2575',
    },
    {
        url: './2/793.png',
        name: 'BSTC#793',
        rank: 'Rank2576',
    },
    {
        url: './2/1698.png',
        name: 'BSTC#1698',
        rank: 'Rank2577',
    },
    {
        url: './2/1737.png',
        name: 'BSTC#1737',
        rank: 'Rank2578',
    },
    {
        url: './2/1984.png',
        name: 'BSTC#1984',
        rank: 'Rank2579',
    },
    {
        url: './2/2154.png',
        name: 'BSTC#2154',
        rank: 'Rank2580',
    },
    {
        url: './2/2589.png',
        name: 'BSTC#2589',
        rank: 'Rank2581',
    },
    {
        url: './2/2784.png',
        name: 'BSTC#2784',
        rank: 'Rank2582',
    },
    {
        url: './2/1625.png',
        name: 'BSTC#1625',
        rank: 'Rank2583',
    },
    {
        url: './2/810.png',
        name: 'BSTC#810',
        rank: 'Rank2584',
    },
    {
        url: './2/3132.png',
        name: 'BSTC#3132',
        rank: 'Rank2585',
    },
    {
        url: './2/1444.png',
        name: 'BSTC#1444',
        rank: 'Rank2586',
    },
    {
        url: './2/2138.png',
        name: 'BSTC#2138',
        rank: 'Rank2587',
    },
    {
        url: './2/2271.png',
        name: 'BSTC#2271',
        rank: 'Rank2588',
    },
    {
        url: './2/1501.png',
        name: 'BSTC#1501',
        rank: 'Rank2589',
    },
    {
        url: './2/175.png',
        name: 'BSTC#175',
        rank: 'Rank2590',
    },
    {
        url: './2/1612.png',
        name: 'BSTC#1612',
        rank: 'Rank2591',
    },
    {
        url: './2/1689.png',
        name: 'BSTC#1689',
        rank: 'Rank2592',
    },
    {
        url: './2/2779.png',
        name: 'BSTC#2779',
        rank: 'Rank2593',
    },
    {
        url: './2/1565.png',
        name: 'BSTC#1565',
        rank: 'Rank2594',
    },
    {
        url: './2/2286.png',
        name: 'BSTC#2286',
        rank: 'Rank2595',
    },
    {
        url: './2/548.png',
        name: 'BSTC#548',
        rank: 'Rank2596',
    },
    {
        url: './2/1504.png',
        name: 'BSTC#1504',
        rank: 'Rank2597',
    },
    {
        url: './2/2288.png',
        name: 'BSTC#2288',
        rank: 'Rank2598',
    },
    {
        url: './2/2353.png',
        name: 'BSTC#2353',
        rank: 'Rank2599',
    },
    {
        url: './2/2532.png',
        name: 'BSTC#2532',
        rank: 'Rank2600',
    },
    {
        url: './2/2148.png',
        name: 'BSTC#2148',
        rank: 'Rank2601',
    },
    {
        url: './2/1139.png',
        name: 'BSTC#1139',
        rank: 'Rank2602',
    },
    {
        url: './2/3023.png',
        name: 'BSTC#3023',
        rank: 'Rank2603',
    },
    {
        url: './2/144.png',
        name: 'BSTC#144',
        rank: 'Rank2604',
    },
    {
        url: './2/676.png',
        name: 'BSTC#676',
        rank: 'Rank2605',
    },
    {
        url: './2/1653.png',
        name: 'BSTC#1653',
        rank: 'Rank2606',
    },
    {
        url: './2/2806.png',
        name: 'BSTC#2806',
        rank: 'Rank2607',
    },
    {
        url: './2/2666.png',
        name: 'BSTC#2666',
        rank: 'Rank2608',
    },
    {
        url: './2/1078.png',
        name: 'BSTC#1078',
        rank: 'Rank2609',
    },
    {
        url: './2/641.png',
        name: 'BSTC#641',
        rank: 'Rank2610',
    },
    {
        url: './2/1199.png',
        name: 'BSTC#1199',
        rank: 'Rank2611',
    },
    {
        url: './2/594.png',
        name: 'BSTC#594',
        rank: 'Rank2612',
    },
    {
        url: './2/2407.png',
        name: 'BSTC#2407',
        rank: 'Rank2613',
    },
    {
        url: './2/564.png',
        name: 'BSTC#564',
        rank: 'Rank2614',
    },
    {
        url: './2/678.png',
        name: 'BSTC#678',
        rank: 'Rank2615',
    },
    {
        url: './2/1085.png',
        name: 'BSTC#1085',
        rank: 'Rank2616',
    },
    {
        url: './2/1590.png',
        name: 'BSTC#1590',
        rank: 'Rank2617',
    },
    {
        url: './2/2311.png',
        name: 'BSTC#2311',
        rank: 'Rank2618',
    },
    {
        url: './2/2976.png',
        name: 'BSTC#2976',
        rank: 'Rank2619',
    },
    {
        url: './2/1759.png',
        name: 'BSTC#1759',
        rank: 'Rank2620',
    },
    {
        url: './2/88.png',
        name: 'BSTC#88',
        rank: 'Rank2621',
    },
    {
        url: './2/741.png',
        name: 'BSTC#741',
        rank: 'Rank2622',
    },
    {
        url: './2/1871.png',
        name: 'BSTC#1871',
        rank: 'Rank2623',
    },
    {
        url: './2/1898.png',
        name: 'BSTC#1898',
        rank: 'Rank2624',
    },
    {
        url: './2/1981.png',
        name: 'BSTC#1981',
        rank: 'Rank2625',
    },
    {
        url: './2/2020.png',
        name: 'BSTC#2020',
        rank: 'Rank2626',
    },
    {
        url: './2/2406.png',
        name: 'BSTC#2406',
        rank: 'Rank2627',
    },
    {
        url: './2/28.png',
        name: 'BSTC#28',
        rank: 'Rank2628',
    },
    {
        url: './2/477.png',
        name: 'BSTC#477',
        rank: 'Rank2629',
    },
    {
        url: './2/2772.png',
        name: 'BSTC#2772',
        rank: 'Rank2630',
    },
    {
        url: './2/2317.png',
        name: 'BSTC#2317',
        rank: 'Rank2631',
    },
    {
        url: './2/264.png',
        name: 'BSTC#264',
        rank: 'Rank2632',
    },
    {
        url: './2/1663.png',
        name: 'BSTC#1663',
        rank: 'Rank2633',
    },
    {
        url: './2/1054.png',
        name: 'BSTC#1054',
        rank: 'Rank2634',
    },
    {
        url: './2/1865.png',
        name: 'BSTC#1865',
        rank: 'Rank2635',
    },
    {
        url: './2/2194.png',
        name: 'BSTC#2194',
        rank: 'Rank2636',
    },
    {
        url: './2/1029.png',
        name: 'BSTC#1029',
        rank: 'Rank2637',
    },
    {
        url: './2/93.png',
        name: 'BSTC#93',
        rank: 'Rank2638',
    },
    {
        url: './2/95.png',
        name: 'BSTC#95',
        rank: 'Rank2639',
    },
    {
        url: './2/2202.png',
        name: 'BSTC#2202',
        rank: 'Rank2640',
    },
    {
        url: './2/2742.png',
        name: 'BSTC#2742',
        rank: 'Rank2641',
    },
    {
        url: './2/2006.png',
        name: 'BSTC#2006',
        rank: 'Rank2642',
    },
    {
        url: './2/1822.png',
        name: 'BSTC#1822',
        rank: 'Rank2643',
    },
    {
        url: './2/191.png',
        name: 'BSTC#191',
        rank: 'Rank2644',
    },
    {
        url: './2/2130.png',
        name: 'BSTC#2130',
        rank: 'Rank2645',
    },
    {
        url: './2/2636.png',
        name: 'BSTC#2636',
        rank: 'Rank2646',
    },
    {
        url: './2/2798.png',
        name: 'BSTC#2798',
        rank: 'Rank2647',
    },
    {
        url: './2/3024.png',
        name: 'BSTC#3024',
        rank: 'Rank2648',
    },
    {
        url: './2/3061.png',
        name: 'BSTC#3061',
        rank: 'Rank2649',
    },
    {
        url: './2/391.png',
        name: 'BSTC#391',
        rank: 'Rank2650',
    },
    {
        url: './2/706.png',
        name: 'BSTC#706',
        rank: 'Rank2651',
    },
    {
        url: './2/2853.png',
        name: 'BSTC#2853',
        rank: 'Rank2652',
    },
    {
        url: './2/1147.png',
        name: 'BSTC#1147',
        rank: 'Rank2653',
    },
    {
        url: './2/1611.png',
        name: 'BSTC#1611',
        rank: 'Rank2654',
    },
    {
        url: './2/1959.png',
        name: 'BSTC#1959',
        rank: 'Rank2655',
    },
    {
        url: './2/1459.png',
        name: 'BSTC#1459',
        rank: 'Rank2656',
    },
    {
        url: './2/1457.png',
        name: 'BSTC#1457',
        rank: 'Rank2657',
    },
    {
        url: './2/1678.png',
        name: 'BSTC#1678',
        rank: 'Rank2658',
    },
    {
        url: './2/1163.png',
        name: 'BSTC#1163',
        rank: 'Rank2659',
    },
    {
        url: './2/1333.png',
        name: 'BSTC#1333',
        rank: 'Rank2660',
    },
    {
        url: './2/1367.png',
        name: 'BSTC#1367',
        rank: 'Rank2661',
    },
    {
        url: './2/2858.png',
        name: 'BSTC#2858',
        rank: 'Rank2662',
    },
    {
        url: './2/271.png',
        name: 'BSTC#271',
        rank: 'Rank2663',
    },
    {
        url: './2/279.png',
        name: 'BSTC#279',
        rank: 'Rank2664',
    },
    {
        url: './2/685.png',
        name: 'BSTC#685',
        rank: 'Rank2665',
    },
    {
        url: './2/687.png',
        name: 'BSTC#687',
        rank: 'Rank2666',
    },
    {
        url: './2/1157.png',
        name: 'BSTC#1157',
        rank: 'Rank2667',
    },
    {
        url: './2/1877.png',
        name: 'BSTC#1877',
        rank: 'Rank2668',
    },
    {
        url: './2/2638.png',
        name: 'BSTC#2638',
        rank: 'Rank2669',
    },
    {
        url: './2/639.png',
        name: 'BSTC#639',
        rank: 'Rank2670',
    },
    {
        url: './2/1369.png',
        name: 'BSTC#1369',
        rank: 'Rank2671',
    },
    {
        url: './2/2692.png',
        name: 'BSTC#2692',
        rank: 'Rank2672',
    },
    {
        url: './2/2278.png',
        name: 'BSTC#2278',
        rank: 'Rank2673',
    },
    {
        url: './2/489.png',
        name: 'BSTC#489',
        rank: 'Rank2674',
    },
    {
        url: './2/1719.png',
        name: 'BSTC#1719',
        rank: 'Rank2675',
    },
    {
        url: './2/2508.png',
        name: 'BSTC#2508',
        rank: 'Rank2676',
    },
    {
        url: './2/1505.png',
        name: 'BSTC#1505',
        rank: 'Rank2677',
    },
    {
        url: './2/385.png',
        name: 'BSTC#385',
        rank: 'Rank2678',
    },
    {
        url: './2/2593.png',
        name: 'BSTC#2593',
        rank: 'Rank2679',
    },
    {
        url: './2/196.png',
        name: 'BSTC#196',
        rank: 'Rank2680',
    },
    {
        url: './2/411.png',
        name: 'BSTC#411',
        rank: 'Rank2681',
    },
    {
        url: './2/2724.png',
        name: 'BSTC#2724',
        rank: 'Rank2682',
    },
    {
        url: './2/772.png',
        name: 'BSTC#772',
        rank: 'Rank2683',
    },
    {
        url: './2/1801.png',
        name: 'BSTC#1801',
        rank: 'Rank2684',
    },
    {
        url: './2/216.png',
        name: 'BSTC#216',
        rank: 'Rank2685',
    },
    {
        url: './2/1210.png',
        name: 'BSTC#1210',
        rank: 'Rank2686',
    },
    {
        url: './2/497.png',
        name: 'BSTC#497',
        rank: 'Rank2687',
    },
    {
        url: './2/670.png',
        name: 'BSTC#670',
        rank: 'Rank2688',
    },
    {
        url: './2/2290.png',
        name: 'BSTC#2290',
        rank: 'Rank2689',
    },
    {
        url: './2/438.png',
        name: 'BSTC#438',
        rank: 'Rank2690',
    },
    {
        url: './2/90.png',
        name: 'BSTC#90',
        rank: 'Rank2691',
    },
    {
        url: './2/1783.png',
        name: 'BSTC#1783',
        rank: 'Rank2692',
    },
    {
        url: './2/1456.png',
        name: 'BSTC#1456',
        rank: 'Rank2693',
    },
    {
        url: './2/2703.png',
        name: 'BSTC#2703',
        rank: 'Rank2694',
    },
    {
        url: './2/642.png',
        name: 'BSTC#642',
        rank: 'Rank2695',
    },
    {
        url: './2/2710.png',
        name: 'BSTC#2710',
        rank: 'Rank2696',
    },
    {
        url: './2/260.png',
        name: 'BSTC#260',
        rank: 'Rank2697',
    },
    {
        url: './2/300.png',
        name: 'BSTC#300',
        rank: 'Rank2698',
    },
    {
        url: './2/481.png',
        name: 'BSTC#481',
        rank: 'Rank2699',
    },
    {
        url: './2/1131.png',
        name: 'BSTC#1131',
        rank: 'Rank2700',
    },
    {
        url: './2/1755.png',
        name: 'BSTC#1755',
        rank: 'Rank2701',
    },
    {
        url: './2/1925.png',
        name: 'BSTC#1925',
        rank: 'Rank2702',
    },
    {
        url: './2/1929.png',
        name: 'BSTC#1929',
        rank: 'Rank2703',
    },
    {
        url: './2/2303.png',
        name: 'BSTC#2303',
        rank: 'Rank2704',
    },
    {
        url: './2/2473.png',
        name: 'BSTC#2473',
        rank: 'Rank2705',
    },
    {
        url: './2/2695.png',
        name: 'BSTC#2695',
        rank: 'Rank2706',
    },
    {
        url: './2/847.png',
        name: 'BSTC#847',
        rank: 'Rank2707',
    },
    {
        url: './2/130.png',
        name: 'BSTC#130',
        rank: 'Rank2708',
    },
    {
        url: './2/204.png',
        name: 'BSTC#204',
        rank: 'Rank2709',
    },
    {
        url: './2/533.png',
        name: 'BSTC#533',
        rank: 'Rank2710',
    },
    {
        url: './2/743.png',
        name: 'BSTC#743',
        rank: 'Rank2711',
    },
    {
        url: './2/1170.png',
        name: 'BSTC#1170',
        rank: 'Rank2712',
    },
    {
        url: './2/2901.png',
        name: 'BSTC#2901',
        rank: 'Rank2713',
    },
    {
        url: './2/673.png',
        name: 'BSTC#673',
        rank: 'Rank2714',
    },
    {
        url: './2/735.png',
        name: 'BSTC#735',
        rank: 'Rank2715',
    },
    {
        url: './2/1368.png',
        name: 'BSTC#1368',
        rank: 'Rank2716',
    },
    {
        url: './2/1433.png',
        name: 'BSTC#1433',
        rank: 'Rank2717',
    },
    {
        url: './2/1817.png',
        name: 'BSTC#1817',
        rank: 'Rank2718',
    },
    {
        url: './2/2036.png',
        name: 'BSTC#2036',
        rank: 'Rank2719',
    },
    {
        url: './2/1793.png',
        name: 'BSTC#1793',
        rank: 'Rank2720',
    },
    {
        url: './2/2483.png',
        name: 'BSTC#2483',
        rank: 'Rank2721',
    },
    {
        url: './2/946.png',
        name: 'BSTC#946',
        rank: 'Rank2722',
    },
    {
        url: './2/1376.png',
        name: 'BSTC#1376',
        rank: 'Rank2723',
    },
    {
        url: './2/877.png',
        name: 'BSTC#877',
        rank: 'Rank2724',
    },
    {
        url: './2/974.png',
        name: 'BSTC#974',
        rank: 'Rank2725',
    },
    {
        url: './2/1754.png',
        name: 'BSTC#1754',
        rank: 'Rank2726',
    },
    {
        url: './2/1423.png',
        name: 'BSTC#1423',
        rank: 'Rank2727',
    },
    {
        url: './2/1441.png',
        name: 'BSTC#1441',
        rank: 'Rank2728',
    },
    {
        url: './2/301.png',
        name: 'BSTC#301',
        rank: 'Rank2729',
    },
    {
        url: './2/1998.png',
        name: 'BSTC#1998',
        rank: 'Rank2730',
    },
    {
        url: './2/2241.png',
        name: 'BSTC#2241',
        rank: 'Rank2731',
    },
    {
        url: './2/982.png',
        name: 'BSTC#982',
        rank: 'Rank2732',
    },
    {
        url: './2/909.png',
        name: 'BSTC#909',
        rank: 'Rank2733',
    },
    {
        url: './2/1936.png',
        name: 'BSTC#1936',
        rank: 'Rank2734',
    },
    {
        url: './2/2047.png',
        name: 'BSTC#2047',
        rank: 'Rank2735',
    },
    {
        url: './2/2604.png',
        name: 'BSTC#2604',
        rank: 'Rank2736',
    },
    {
        url: './2/2854.png',
        name: 'BSTC#2854',
        rank: 'Rank2737',
    },
    {
        url: './2/2926.png',
        name: 'BSTC#2926',
        rank: 'Rank2738',
    },
    {
        url: './2/2375.png',
        name: 'BSTC#2375',
        rank: 'Rank2739',
    },
    {
        url: './2/875.png',
        name: 'BSTC#875',
        rank: 'Rank2740',
    },
    {
        url: './2/1358.png',
        name: 'BSTC#1358',
        rank: 'Rank2741',
    },
    {
        url: './2/1744.png',
        name: 'BSTC#1744',
        rank: 'Rank2742',
    },
    {
        url: './2/1448.png',
        name: 'BSTC#1448',
        rank: 'Rank2743',
    },
    {
        url: './2/2506.png',
        name: 'BSTC#2506',
        rank: 'Rank2744',
    },
    {
        url: './2/1427.png',
        name: 'BSTC#1427',
        rank: 'Rank2745',
    },
    {
        url: './2/2514.png',
        name: 'BSTC#2514',
        rank: 'Rank2746',
    },
    {
        url: './2/1282.png',
        name: 'BSTC#1282',
        rank: 'Rank2747',
    },
    {
        url: './2/983.png',
        name: 'BSTC#983',
        rank: 'Rank2748',
    },
    {
        url: './2/944.png',
        name: 'BSTC#944',
        rank: 'Rank2749',
    },
    {
        url: './2/985.png',
        name: 'BSTC#985',
        rank: 'Rank2750',
    },
    {
        url: './2/1145.png',
        name: 'BSTC#1145',
        rank: 'Rank2751',
    },
    {
        url: './2/2624.png',
        name: 'BSTC#2624',
        rank: 'Rank2752',
    },
    {
        url: './2/2644.png',
        name: 'BSTC#2644',
        rank: 'Rank2753',
    },
    {
        url: './2/426.png',
        name: 'BSTC#426',
        rank: 'Rank2754',
    },
    {
        url: './2/504.png',
        name: 'BSTC#504',
        rank: 'Rank2755',
    },
    {
        url: './2/710.png',
        name: 'BSTC#710',
        rank: 'Rank2756',
    },
    {
        url: './2/1295.png',
        name: 'BSTC#1295',
        rank: 'Rank2757',
    },
    {
        url: './2/1950.png',
        name: 'BSTC#1950',
        rank: 'Rank2758',
    },
    {
        url: './2/2605.png',
        name: 'BSTC#2605',
        rank: 'Rank2759',
    },
    {
        url: './2/2621.png',
        name: 'BSTC#2621',
        rank: 'Rank2760',
    },
    {
        url: './2/2329.png',
        name: 'BSTC#2329',
        rank: 'Rank2761',
    },
    {
        url: './2/2026.png',
        name: 'BSTC#2026',
        rank: 'Rank2762',
    },
    {
        url: './2/3104.png',
        name: 'BSTC#3104',
        rank: 'Rank2763',
    },
    {
        url: './2/459.png',
        name: 'BSTC#459',
        rank: 'Rank2764',
    },
    {
        url: './2/1326.png',
        name: 'BSTC#1326',
        rank: 'Rank2765',
    },
    {
        url: './2/435.png',
        name: 'BSTC#435',
        rank: 'Rank2766',
    },
    {
        url: './2/1324.png',
        name: 'BSTC#1324',
        rank: 'Rank2767',
    },
    {
        url: './2/337.png',
        name: 'BSTC#337',
        rank: 'Rank2768',
    },
    {
        url: './2/2282.png',
        name: 'BSTC#2282',
        rank: 'Rank2769',
    },
    {
        url: './2/2825.png',
        name: 'BSTC#2825',
        rank: 'Rank2770',
    },
    {
        url: './2/684.png',
        name: 'BSTC#684',
        rank: 'Rank2771',
    },
    {
        url: './2/807.png',
        name: 'BSTC#807',
        rank: 'Rank2772',
    },
    {
        url: './2/2509.png',
        name: 'BSTC#2509',
        rank: 'Rank2773',
    },
    {
        url: './2/346.png',
        name: 'BSTC#346',
        rank: 'Rank2774',
    },
    {
        url: './2/370.png',
        name: 'BSTC#370',
        rank: 'Rank2775',
    },
    {
        url: './2/1743.png',
        name: 'BSTC#1743',
        rank: 'Rank2776',
    },
    {
        url: './2/2039.png',
        name: 'BSTC#2039',
        rank: 'Rank2777',
    },
    {
        url: './2/2558.png',
        name: 'BSTC#2558',
        rank: 'Rank2778',
    },
    {
        url: './2/2879.png',
        name: 'BSTC#2879',
        rank: 'Rank2779',
    },
    {
        url: './2/1498.png',
        name: 'BSTC#1498',
        rank: 'Rank2780',
    },
    {
        url: './2/2334.png',
        name: 'BSTC#2334',
        rank: 'Rank2781',
    },
    {
        url: './2/261.png',
        name: 'BSTC#261',
        rank: 'Rank2782',
    },
    {
        url: './2/349.png',
        name: 'BSTC#349',
        rank: 'Rank2783',
    },
    {
        url: './2/1603.png',
        name: 'BSTC#1603',
        rank: 'Rank2784',
    },
    {
        url: './2/2408.png',
        name: 'BSTC#2408',
        rank: 'Rank2785',
    },
    {
        url: './2/2777.png',
        name: 'BSTC#2777',
        rank: 'Rank2786',
    },
    {
        url: './2/2874.png',
        name: 'BSTC#2874',
        rank: 'Rank2787',
    },
    {
        url: './2/2079.png',
        name: 'BSTC#2079',
        rank: 'Rank2788',
    },
    {
        url: './2/89.png',
        name: 'BSTC#89',
        rank: 'Rank2789',
    },
    {
        url: './2/1907.png',
        name: 'BSTC#1907',
        rank: 'Rank2790',
    },
    {
        url: './2/541.png',
        name: 'BSTC#541',
        rank: 'Rank2791',
    },
    {
        url: './2/582.png',
        name: 'BSTC#582',
        rank: 'Rank2792',
    },
    {
        url: './2/585.png',
        name: 'BSTC#585',
        rank: 'Rank2793',
    },
    {
        url: './2/1688.png',
        name: 'BSTC#1688',
        rank: 'Rank2794',
    },
    {
        url: './2/1740.png',
        name: 'BSTC#1740',
        rank: 'Rank2795',
    },
    {
        url: './2/3107.png',
        name: 'BSTC#3107',
        rank: 'Rank2796',
    },
    {
        url: './2/151.png',
        name: 'BSTC#151',
        rank: 'Rank2797',
    },
    {
        url: './2/1620.png',
        name: 'BSTC#1620',
        rank: 'Rank2798',
    },
    {
        url: './2/1990.png',
        name: 'BSTC#1990',
        rank: 'Rank2799',
    },
    {
        url: './2/2562.png',
        name: 'BSTC#2562',
        rank: 'Rank2800',
    },
    {
        url: './2/2606.png',
        name: 'BSTC#2606',
        rank: 'Rank2801',
    },
    {
        url: './2/2658.png',
        name: 'BSTC#2658',
        rank: 'Rank2802',
    },
    {
        url: './2/2397.png',
        name: 'BSTC#2397',
        rank: 'Rank2803',
    },
    {
        url: './2/2103.png',
        name: 'BSTC#2103',
        rank: 'Rank2804',
    },
    {
        url: './2/1299.png',
        name: 'BSTC#1299',
        rank: 'Rank2805',
    },
    {
        url: './2/1371.png',
        name: 'BSTC#1371',
        rank: 'Rank2806',
    },
    {
        url: './2/2216.png',
        name: 'BSTC#2216',
        rank: 'Rank2807',
    },
    {
        url: './2/3114.png',
        name: 'BSTC#3114',
        rank: 'Rank2808',
    },
    {
        url: './2/2526.png',
        name: 'BSTC#2526',
        rank: 'Rank2809',
    },
    {
        url: './2/1421.png',
        name: 'BSTC#1421',
        rank: 'Rank2810',
    },
    {
        url: './2/67.png',
        name: 'BSTC#67',
        rank: 'Rank2811',
    },
    {
        url: './2/53.png',
        name: 'BSTC#53',
        rank: 'Rank2812',
    },
    {
        url: './2/664.png',
        name: 'BSTC#664',
        rank: 'Rank2813',
    },
    {
        url: './2/2089.png',
        name: 'BSTC#2089',
        rank: 'Rank2814',
    },
    {
        url: './2/849.png',
        name: 'BSTC#849',
        rank: 'Rank2815',
    },
    {
        url: './2/1788.png',
        name: 'BSTC#1788',
        rank: 'Rank2816',
    },
    {
        url: './2/2143.png',
        name: 'BSTC#2143',
        rank: 'Rank2817',
    },
    {
        url: './2/2800.png',
        name: 'BSTC#2800',
        rank: 'Rank2818',
    },
    {
        url: './2/2981.png',
        name: 'BSTC#2981',
        rank: 'Rank2819',
    },
    {
        url: './2/2617.png',
        name: 'BSTC#2617',
        rank: 'Rank2820',
    },
    {
        url: './2/2812.png',
        name: 'BSTC#2812',
        rank: 'Rank2821',
    },
    {
        url: './2/2058.png',
        name: 'BSTC#2058',
        rank: 'Rank2822',
    },
    {
        url: './2/1037.png',
        name: 'BSTC#1037',
        rank: 'Rank2823',
    },
    {
        url: './2/328.png',
        name: 'BSTC#328',
        rank: 'Rank2824',
    },
    {
        url: './2/1128.png',
        name: 'BSTC#1128',
        rank: 'Rank2825',
    },
    {
        url: './2/1571.png',
        name: 'BSTC#1571',
        rank: 'Rank2826',
    },
    {
        url: './2/2231.png',
        name: 'BSTC#2231',
        rank: 'Rank2827',
    },
    {
        url: './2/1507.png',
        name: 'BSTC#1507',
        rank: 'Rank2828',
    },
    {
        url: './2/1838.png',
        name: 'BSTC#1838',
        rank: 'Rank2829',
    },
    {
        url: './2/1247.png',
        name: 'BSTC#1247',
        rank: 'Rank2830',
    },
    {
        url: './2/1422.png',
        name: 'BSTC#1422',
        rank: 'Rank2831',
    },
    {
        url: './2/549.png',
        name: 'BSTC#549',
        rank: 'Rank2832',
    },
    {
        url: './2/1503.png',
        name: 'BSTC#1503',
        rank: 'Rank2833',
    },
    {
        url: './2/2007.png',
        name: 'BSTC#2007',
        rank: 'Rank2834',
    },
    {
        url: './2/2287.png',
        name: 'BSTC#2287',
        rank: 'Rank2835',
    },
    {
        url: './2/612.png',
        name: 'BSTC#612',
        rank: 'Rank2836',
    },
    {
        url: './2/1165.png',
        name: 'BSTC#1165',
        rank: 'Rank2837',
    },
    {
        url: './2/1438.png',
        name: 'BSTC#1438',
        rank: 'Rank2838',
    },
    {
        url: './2/309.png',
        name: 'BSTC#309',
        rank: 'Rank2839',
    },
    {
        url: './2/1255.png',
        name: 'BSTC#1255',
        rank: 'Rank2840',
    },
    {
        url: './2/2272.png',
        name: 'BSTC#2272',
        rank: 'Rank2841',
    },
    {
        url: './2/2683.png',
        name: 'BSTC#2683',
        rank: 'Rank2842',
    },
    {
        url: './2/502.png',
        name: 'BSTC#502',
        rank: 'Rank2843',
    },
    {
        url: './2/1197.png',
        name: 'BSTC#1197',
        rank: 'Rank2844',
    },
    {
        url: './2/1762.png',
        name: 'BSTC#1762',
        rank: 'Rank2845',
    },
    {
        url: './2/695.png',
        name: 'BSTC#695',
        rank: 'Rank2846',
    },
    {
        url: './2/335.png',
        name: 'BSTC#335',
        rank: 'Rank2847',
    },
    {
        url: './2/802.png',
        name: 'BSTC#802',
        rank: 'Rank2848',
    },
    {
        url: './2/1710.png',
        name: 'BSTC#1710',
        rank: 'Rank2849',
    },
    {
        url: './2/2207.png',
        name: 'BSTC#2207',
        rank: 'Rank2850',
    },
    {
        url: './2/1057.png',
        name: 'BSTC#1057',
        rank: 'Rank2851',
    },
    {
        url: './2/220.png',
        name: 'BSTC#220',
        rank: 'Rank2852',
    },
    {
        url: './2/1906.png',
        name: 'BSTC#1906',
        rank: 'Rank2853',
    },
    {
        url: './2/169.png',
        name: 'BSTC#169',
        rank: 'Rank2854',
    },
    {
        url: './2/1511.png',
        name: 'BSTC#1511',
        rank: 'Rank2855',
    },
    {
        url: './2/2620.png',
        name: 'BSTC#2620',
        rank: 'Rank2856',
    },
    {
        url: './2/2929.png',
        name: 'BSTC#2929',
        rank: 'Rank2857',
    },
    {
        url: './2/2956.png',
        name: 'BSTC#2956',
        rank: 'Rank2858',
    },
    {
        url: './2/2656.png',
        name: 'BSTC#2656',
        rank: 'Rank2859',
    },
    {
        url: './2/952.png',
        name: 'BSTC#952',
        rank: 'Rank2860',
    },
    {
        url: './2/2071.png',
        name: 'BSTC#2071',
        rank: 'Rank2861',
    },
    {
        url: './2/2457.png',
        name: 'BSTC#2457',
        rank: 'Rank2862',
    },
    {
        url: './2/2948.png',
        name: 'BSTC#2948',
        rank: 'Rank2863',
    },
    {
        url: './2/3008.png',
        name: 'BSTC#3008',
        rank: 'Rank2864',
    },
    {
        url: './2/2700.png',
        name: 'BSTC#2700',
        rank: 'Rank2865',
    },
    {
        url: './2/1963.png',
        name: 'BSTC#1963',
        rank: 'Rank2866',
    },
    {
        url: './2/32.png',
        name: 'BSTC#32',
        rank: 'Rank2867',
    },
    {
        url: './2/2744.png',
        name: 'BSTC#2744',
        rank: 'Rank2868',
    },
    {
        url: './2/1209.png',
        name: 'BSTC#1209',
        rank: 'Rank2869',
    },
    {
        url: './2/1767.png',
        name: 'BSTC#1767',
        rank: 'Rank2870',
    },
    {
        url: './2/1327.png',
        name: 'BSTC#1327',
        rank: 'Rank2871',
    },
    {
        url: './2/2113.png',
        name: 'BSTC#2113',
        rank: 'Rank2872',
    },
    {
        url: './2/2569.png',
        name: 'BSTC#2569',
        rank: 'Rank2873',
    },
    {
        url: './2/2670.png',
        name: 'BSTC#2670',
        rank: 'Rank2874',
    },
    {
        url: './2/2773.png',
        name: 'BSTC#2773',
        rank: 'Rank2875',
    },
    {
        url: './2/2405.png',
        name: 'BSTC#2405',
        rank: 'Rank2876',
    },
    {
        url: './2/2895.png',
        name: 'BSTC#2895',
        rank: 'Rank2877',
    },
    {
        url: './2/1580.png',
        name: 'BSTC#1580',
        rank: 'Rank2878',
    },
    {
        url: './2/1241.png',
        name: 'BSTC#1241',
        rank: 'Rank2879',
    },
    {
        url: './2/1593.png',
        name: 'BSTC#1593',
        rank: 'Rank2880',
    },
    {
        url: './2/42.png',
        name: 'BSTC#42',
        rank: 'Rank2881',
    },
    {
        url: './2/278.png',
        name: 'BSTC#278',
        rank: 'Rank2882',
    },
    {
        url: './2/2321.png',
        name: 'BSTC#2321',
        rank: 'Rank2883',
    },
    {
        url: './2/2349.png',
        name: 'BSTC#2349',
        rank: 'Rank2884',
    },
    {
        url: './2/2591.png',
        name: 'BSTC#2591',
        rank: 'Rank2885',
    },
    {
        url: './2/2796.png',
        name: 'BSTC#2796',
        rank: 'Rank2886',
    },
    {
        url: './2/928.png',
        name: 'BSTC#928',
        rank: 'Rank2887',
    },
    {
        url: './2/2705.png',
        name: 'BSTC#2705',
        rank: 'Rank2888',
    },
    {
        url: './2/274.png',
        name: 'BSTC#274',
        rank: 'Rank2889',
    },
    {
        url: './2/842.png',
        name: 'BSTC#842',
        rank: 'Rank2890',
    },
    {
        url: './2/1946.png',
        name: 'BSTC#1946',
        rank: 'Rank2891',
    },
    {
        url: './2/1287.png',
        name: 'BSTC#1287',
        rank: 'Rank2892',
    },
    {
        url: './2/2228.png',
        name: 'BSTC#2228',
        rank: 'Rank2893',
    },
    {
        url: './2/3092.png',
        name: 'BSTC#3092',
        rank: 'Rank2894',
    },
    {
        url: './2/2044.png',
        name: 'BSTC#2044',
        rank: 'Rank2895',
    },
    {
        url: './2/1999.png',
        name: 'BSTC#1999',
        rank: 'Rank2896',
    },
    {
        url: './2/2910.png',
        name: 'BSTC#2910',
        rank: 'Rank2897',
    },
    {
        url: './2/2696.png',
        name: 'BSTC#2696',
        rank: 'Rank2898',
    },
    {
        url: './2/519.png',
        name: 'BSTC#519',
        rank: 'Rank2899',
    },
    {
        url: './2/1752.png',
        name: 'BSTC#1752',
        rank: 'Rank2900',
    },
    {
        url: './2/1627.png',
        name: 'BSTC#1627',
        rank: 'Rank2901',
    },
    {
        url: './2/3059.png',
        name: 'BSTC#3059',
        rank: 'Rank2902',
    },
    {
        url: './2/1811.png',
        name: 'BSTC#1811',
        rank: 'Rank2903',
    },
    {
        url: './2/2753.png',
        name: 'BSTC#2753',
        rank: 'Rank2904',
    },
    {
        url: './2/2324.png',
        name: 'BSTC#2324',
        rank: 'Rank2905',
    },
    {
        url: './2/2529.png',
        name: 'BSTC#2529',
        rank: 'Rank2906',
    },
    {
        url: './2/2633.png',
        name: 'BSTC#2633',
        rank: 'Rank2907',
    },
    {
        url: './2/1126.png',
        name: 'BSTC#1126',
        rank: 'Rank2908',
    },
    {
        url: './2/631.png',
        name: 'BSTC#631',
        rank: 'Rank2909',
    },
    {
        url: './2/1393.png',
        name: 'BSTC#1393',
        rank: 'Rank2910',
    },
    {
        url: './2/1899.png',
        name: 'BSTC#1899',
        rank: 'Rank2911',
    },
    {
        url: './2/522.png',
        name: 'BSTC#522',
        rank: 'Rank2912',
    },
    {
        url: './2/2035.png',
        name: 'BSTC#2035',
        rank: 'Rank2913',
    },
    {
        url: './2/561.png',
        name: 'BSTC#561',
        rank: 'Rank2914',
    },
    {
        url: './2/1578.png',
        name: 'BSTC#1578',
        rank: 'Rank2915',
    },
    {
        url: './2/166.png',
        name: 'BSTC#166',
        rank: 'Rank2916',
    },
    {
        url: './2/508.png',
        name: 'BSTC#508',
        rank: 'Rank2917',
    },
    {
        url: './2/1251.png',
        name: 'BSTC#1251',
        rank: 'Rank2918',
    },
    {
        url: './2/2832.png',
        name: 'BSTC#2832',
        rank: 'Rank2919',
    },
    {
        url: './2/368.png',
        name: 'BSTC#368',
        rank: 'Rank2920',
    },
    {
        url: './2/1576.png',
        name: 'BSTC#1576',
        rank: 'Rank2921',
    },
    {
        url: './2/1730.png',
        name: 'BSTC#1730',
        rank: 'Rank2922',
    },
    {
        url: './2/2077.png',
        name: 'BSTC#2077',
        rank: 'Rank2923',
    },
    {
        url: './2/2260.png',
        name: 'BSTC#2260',
        rank: 'Rank2924',
    },
    {
        url: './2/2277.png',
        name: 'BSTC#2277',
        rank: 'Rank2925',
    },
    {
        url: './2/2660.png',
        name: 'BSTC#2660',
        rank: 'Rank2926',
    },
    {
        url: './2/3022.png',
        name: 'BSTC#3022',
        rank: 'Rank2927',
    },
    {
        url: './2/3071.png',
        name: 'BSTC#3071',
        rank: 'Rank2928',
    },
    {
        url: './2/494.png',
        name: 'BSTC#494',
        rank: 'Rank2929',
    },
    {
        url: './2/977.png',
        name: 'BSTC#977',
        rank: 'Rank2930',
    },
    {
        url: './2/587.png',
        name: 'BSTC#587',
        rank: 'Rank2931',
    },
    {
        url: './2/1218.png',
        name: 'BSTC#1218',
        rank: 'Rank2932',
    },
    {
        url: './2/2380.png',
        name: 'BSTC#2380',
        rank: 'Rank2933',
    },
    {
        url: './2/514.png',
        name: 'BSTC#514',
        rank: 'Rank2934',
    },
    {
        url: './2/867.png',
        name: 'BSTC#867',
        rank: 'Rank2935',
    },
    {
        url: './2/3040.png',
        name: 'BSTC#3040',
        rank: 'Rank2936',
    },
    {
        url: './2/1810.png',
        name: 'BSTC#1810',
        rank: 'Rank2937',
    },
    {
        url: './2/6.png',
        name: 'BSTC#6',
        rank: 'Rank2938',
    },
    {
        url: './2/662.png',
        name: 'BSTC#662',
        rank: 'Rank2939',
    },
    {
        url: './2/738.png',
        name: 'BSTC#738',
        rank: 'Rank2940',
    },
    {
        url: './2/2227.png',
        name: 'BSTC#2227',
        rank: 'Rank2941',
    },
    {
        url: './2/1309.png',
        name: 'BSTC#1309',
        rank: 'Rank2942',
    },
    {
        url: './2/1606.png',
        name: 'BSTC#1606',
        rank: 'Rank2943',
    },
    {
        url: './2/565.png',
        name: 'BSTC#565',
        rank: 'Rank2944',
    },
    {
        url: './2/2667.png',
        name: 'BSTC#2667',
        rank: 'Rank2945',
    },
    {
        url: './2/843.png',
        name: 'BSTC#843',
        rank: 'Rank2946',
    },
    {
        url: './2/1140.png',
        name: 'BSTC#1140',
        rank: 'Rank2947',
    },
    {
        url: './2/2466.png',
        name: 'BSTC#2466',
        rank: 'Rank2948',
    },
    {
        url: './2/1878.png',
        name: 'BSTC#1878',
        rank: 'Rank2949',
    },
    {
        url: './2/3027.png',
        name: 'BSTC#3027',
        rank: 'Rank2950',
    },
    {
        url: './2/873.png',
        name: 'BSTC#873',
        rank: 'Rank2951',
    },
    {
        url: './2/1937.png',
        name: 'BSTC#1937',
        rank: 'Rank2952',
    },
    {
        url: './2/2530.png',
        name: 'BSTC#2530',
        rank: 'Rank2953',
    },
    {
        url: './2/1727.png',
        name: 'BSTC#1727',
        rank: 'Rank2954',
    },
    {
        url: './2/871.png',
        name: 'BSTC#871',
        rank: 'Rank2955',
    },
    {
        url: './2/1518.png',
        name: 'BSTC#1518',
        rank: 'Rank2956',
    },
    {
        url: './2/2447.png',
        name: 'BSTC#2447',
        rank: 'Rank2957',
    },
    {
        url: './2/2023.png',
        name: 'BSTC#2023',
        rank: 'Rank2958',
    },
    {
        url: './2/659.png',
        name: 'BSTC#659',
        rank: 'Rank2959',
    },
    {
        url: './2/100.png',
        name: 'BSTC#100',
        rank: 'Rank2960',
    },
    {
        url: './2/1912.png',
        name: 'BSTC#1912',
        rank: 'Rank2961',
    },
    {
        url: './2/2259.png',
        name: 'BSTC#2259',
        rank: 'Rank2962',
    },
    {
        url: './2/780.png',
        name: 'BSTC#780',
        rank: 'Rank2963',
    },
    {
        url: './2/293.png',
        name: 'BSTC#293',
        rank: 'Rank2964',
    },
    {
        url: './2/295.png',
        name: 'BSTC#295',
        rank: 'Rank2965',
    },
    {
        url: './2/212.png',
        name: 'BSTC#212',
        rank: 'Rank2966',
    },
    {
        url: './2/231.png',
        name: 'BSTC#231',
        rank: 'Rank2967',
    },
    {
        url: './2/298.png',
        name: 'BSTC#298',
        rank: 'Rank2968',
    },
    {
        url: './2/255.png',
        name: 'BSTC#255',
        rank: 'Rank2969',
    },
    {
        url: './2/1610.png',
        name: 'BSTC#1610',
        rank: 'Rank2970',
    },
    {
        url: './2/1776.png',
        name: 'BSTC#1776',
        rank: 'Rank2971',
    },
    {
        url: './2/1372.png',
        name: 'BSTC#1372',
        rank: 'Rank2972',
    },
    {
        url: './2/2585.png',
        name: 'BSTC#2585',
        rank: 'Rank2973',
    },
    {
        url: './2/2645.png',
        name: 'BSTC#2645',
        rank: 'Rank2974',
    },
    {
        url: './2/524.png',
        name: 'BSTC#524',
        rank: 'Rank2975',
    },
    {
        url: './2/1555.png',
        name: 'BSTC#1555',
        rank: 'Rank2976',
    },
    {
        url: './2/674.png',
        name: 'BSTC#674',
        rank: 'Rank2977',
    },
    {
        url: './2/778.png',
        name: 'BSTC#778',
        rank: 'Rank2978',
    },
    {
        url: './2/1584.png',
        name: 'BSTC#1584',
        rank: 'Rank2979',
    },
    {
        url: './2/1770.png',
        name: 'BSTC#1770',
        rank: 'Rank2980',
    },
    {
        url: './2/2098.png',
        name: 'BSTC#2098',
        rank: 'Rank2981',
    },
    {
        url: './2/117.png',
        name: 'BSTC#117',
        rank: 'Rank2982',
    },
    {
        url: './2/1655.png',
        name: 'BSTC#1655',
        rank: 'Rank2983',
    },
    {
        url: './2/2209.png',
        name: 'BSTC#2209',
        rank: 'Rank2984',
    },
    {
        url: './2/1096.png',
        name: 'BSTC#1096',
        rank: 'Rank2985',
    },
    {
        url: './2/421.png',
        name: 'BSTC#421',
        rank: 'Rank2986',
    },
    {
        url: './2/1596.png',
        name: 'BSTC#1596',
        rank: 'Rank2987',
    },
    {
        url: './2/2456.png',
        name: 'BSTC#2456',
        rank: 'Rank2988',
    },
    {
        url: './2/1577.png',
        name: 'BSTC#1577',
        rank: 'Rank2989',
    },
    {
        url: './2/1715.png',
        name: 'BSTC#1715',
        rank: 'Rank2990',
    },
    {
        url: './2/1559.png',
        name: 'BSTC#1559',
        rank: 'Rank2991',
    },
    {
        url: './2/30.png',
        name: 'BSTC#30',
        rank: 'Rank2992',
    },
    {
        url: './2/1670.png',
        name: 'BSTC#1670',
        rank: 'Rank2993',
    },
    {
        url: './2/18.png',
        name: 'BSTC#18',
        rank: 'Rank2994',
    },
    {
        url: './2/546.png',
        name: 'BSTC#546',
        rank: 'Rank2995',
    },
    {
        url: './2/3063.png',
        name: 'BSTC#3063',
        rank: 'Rank2996',
    },
    {
        url: './2/752.png',
        name: 'BSTC#752',
        rank: 'Rank2997',
    },
    {
        url: './2/1394.png',
        name: 'BSTC#1394',
        rank: 'Rank2998',
    },
    {
        url: './2/1211.png',
        name: 'BSTC#1211',
        rank: 'Rank2999',
    },
    {
        url: './2/2104.png',
        name: 'BSTC#2104',
        rank: 'Rank3000',
    },
    {
        url: './2/94.png',
        name: 'BSTC#94',
        rank: 'Rank3001',
    },
    {
        url: './2/455.png',
        name: 'BSTC#455',
        rank: 'Rank3002',
    },
    {
        url: './2/1269.png',
        name: 'BSTC#1269',
        rank: 'Rank3003',
    },
    {
        url: './2/62.png',
        name: 'BSTC#62',
        rank: 'Rank3004',
    },
    {
        url: './2/1932.png',
        name: 'BSTC#1932',
        rank: 'Rank3005',
    },
    {
        url: './2/569.png',
        name: 'BSTC#569',
        rank: 'Rank3006',
    },
    {
        url: './2/613.png',
        name: 'BSTC#613',
        rank: 'Rank3007',
    },
    {
        url: './2/707.png',
        name: 'BSTC#707',
        rank: 'Rank3008',
    },
    {
        url: './2/1172.png',
        name: 'BSTC#1172',
        rank: 'Rank3009',
    },
    {
        url: './2/1989.png',
        name: 'BSTC#1989',
        rank: 'Rank3010',
    },
    {
        url: './2/2326.png',
        name: 'BSTC#2326',
        rank: 'Rank3011',
    },
    {
        url: './2/1836.png',
        name: 'BSTC#1836',
        rank: 'Rank3012',
    },
    {
        url: './2/1791.png',
        name: 'BSTC#1791',
        rank: 'Rank3013',
    },
    {
        url: './2/754.png',
        name: 'BSTC#754',
        rank: 'Rank3014',
    },
    {
        url: './2/3019.png',
        name: 'BSTC#3019',
        rank: 'Rank3015',
    },
    {
        url: './2/2249.png',
        name: 'BSTC#2249',
        rank: 'Rank3016',
    },
    {
        url: './2/1266.png',
        name: 'BSTC#1266',
        rank: 'Rank3017',
    },
    {
        url: './2/956.png',
        name: 'BSTC#956',
        rank: 'Rank3018',
    },
    {
        url: './2/1366.png',
        name: 'BSTC#1366',
        rank: 'Rank3019',
    },
    {
        url: './2/2957.png',
        name: 'BSTC#2957',
        rank: 'Rank3020',
    },
    {
        url: './2/1146.png',
        name: 'BSTC#1146',
        rank: 'Rank3021',
    },
    {
        url: './2/2108.png',
        name: 'BSTC#2108',
        rank: 'Rank3022',
    },
    {
        url: './2/2119.png',
        name: 'BSTC#2119',
        rank: 'Rank3023',
    },
    {
        url: './2/2780.png',
        name: 'BSTC#2780',
        rank: 'Rank3024',
    },
    {
        url: './2/1813.png',
        name: 'BSTC#1813',
        rank: 'Rank3025',
    },
    {
        url: './2/1112.png',
        name: 'BSTC#1112',
        rank: 'Rank3026',
    },
    {
        url: './2/2465.png',
        name: 'BSTC#2465',
        rank: 'Rank3027',
    },
    {
        url: './2/2452.png',
        name: 'BSTC#2452',
        rank: 'Rank3028',
    },
    {
        url: './2/1446.png',
        name: 'BSTC#1446',
        rank: 'Rank3029',
    },
    {
        url: './2/1862.png',
        name: 'BSTC#1862',
        rank: 'Rank3030',
    },
    {
        url: './2/1494.png',
        name: 'BSTC#1494',
        rank: 'Rank3031',
    },
    {
        url: './2/2190.png',
        name: 'BSTC#2190',
        rank: 'Rank3032',
    },
    {
        url: './2/3042.png',
        name: 'BSTC#3042',
        rank: 'Rank3033',
    },
    {
        url: './2/1834.png',
        name: 'BSTC#1834',
        rank: 'Rank3034',
    },
    {
        url: './2/2203.png',
        name: 'BSTC#2203',
        rank: 'Rank3035',
    },
    {
        url: './2/2882.png',
        name: 'BSTC#2882',
        rank: 'Rank3036',
    },
    {
        url: './2/540.png',
        name: 'BSTC#540',
        rank: 'Rank3037',
    },
    {
        url: './2/941.png',
        name: 'BSTC#941',
        rank: 'Rank3038',
    },
    {
        url: './2/1564.png',
        name: 'BSTC#1564',
        rank: 'Rank3039',
    },
    {
        url: './2/2283.png',
        name: 'BSTC#2283',
        rank: 'Rank3040',
    },
    {
        url: './2/2861.png',
        name: 'BSTC#2861',
        rank: 'Rank3041',
    },
    {
        url: './2/2568.png',
        name: 'BSTC#2568',
        rank: 'Rank3042',
    },
    {
        url: './2/2733.png',
        name: 'BSTC#2733',
        rank: 'Rank3043',
    },
    {
        url: './2/285.png',
        name: 'BSTC#285',
        rank: 'Rank3044',
    },
    {
        url: './2/2453.png',
        name: 'BSTC#2453',
        rank: 'Rank3045',
    },
    {
        url: './2/2913.png',
        name: 'BSTC#2913',
        rank: 'Rank3046',
    },
    {
        url: './2/2916.png',
        name: 'BSTC#2916',
        rank: 'Rank3047',
    },
    {
        url: './2/1291.png',
        name: 'BSTC#1291',
        rank: 'Rank3048',
    },
    {
        url: './2/2217.png',
        name: 'BSTC#2217',
        rank: 'Rank3049',
    },
    {
        url: './2/1193.png',
        name: 'BSTC#1193',
        rank: 'Rank3050',
    },
    {
        url: './2/1349.png',
        name: 'BSTC#1349',
        rank: 'Rank3051',
    },
    {
        url: './2/1379.png',
        name: 'BSTC#1379',
        rank: 'Rank3052',
    },
    {
        url: './2/530.png',
        name: 'BSTC#530',
        rank: 'Rank3053',
    },
    {
        url: './2/201.png',
        name: 'BSTC#201',
        rank: 'Rank3054',
    },
    {
        url: './2/475.png',
        name: 'BSTC#475',
        rank: 'Rank3055',
    },
    {
        url: './2/2012.png',
        name: 'BSTC#2012',
        rank: 'Rank3056',
    },
    {
        url: './2/356.png',
        name: 'BSTC#356',
        rank: 'Rank3057',
    },
    {
        url: './2/446.png',
        name: 'BSTC#446',
        rank: 'Rank3058',
    },
    {
        url: './2/1022.png',
        name: 'BSTC#1022',
        rank: 'Rank3059',
    },
    {
        url: './2/3121.png',
        name: 'BSTC#3121',
        rank: 'Rank3060',
    },
    {
        url: './2/2599.png',
        name: 'BSTC#2599',
        rank: 'Rank3061',
    },
    {
        url: './2/41.png',
        name: 'BSTC#41',
        rank: 'Rank3062',
    },
    {
        url: './2/3051.png',
        name: 'BSTC#3051',
        rank: 'Rank3063',
    },
    {
        url: './2/1429.png',
        name: 'BSTC#1429',
        rank: 'Rank3064',
    },
    {
        url: './2/1861.png',
        name: 'BSTC#1861',
        rank: 'Rank3065',
    },
    {
        url: './2/2662.png',
        name: 'BSTC#2662',
        rank: 'Rank3066',
    },
    {
        url: './2/420.png',
        name: 'BSTC#420',
        rank: 'Rank3067',
    },
    {
        url: './2/1162.png',
        name: 'BSTC#1162',
        rank: 'Rank3068',
    },
    {
        url: './2/521.png',
        name: 'BSTC#521',
        rank: 'Rank3069',
    },
    {
        url: './2/1244.png',
        name: 'BSTC#1244',
        rank: 'Rank3070',
    },
    {
        url: './2/394.png',
        name: 'BSTC#394',
        rank: 'Rank3071',
    },
    {
        url: './2/1001.png',
        name: 'BSTC#1001',
        rank: 'Rank3072',
    },
    {
        url: './2/3021.png',
        name: 'BSTC#3021',
        rank: 'Rank3073',
    },
    {
        url: './2/2492.png',
        name: 'BSTC#2492',
        rank: 'Rank3074',
    },
    {
        url: './2/2570.png',
        name: 'BSTC#2570',
        rank: 'Rank3075',
    },
    {
        url: './2/292.png',
        name: 'BSTC#292',
        rank: 'Rank3076',
    },
    {
        url: './2/543.png',
        name: 'BSTC#543',
        rank: 'Rank3077',
    },
    {
        url: './2/2639.png',
        name: 'BSTC#2639',
        rank: 'Rank3078',
    },
    {
        url: './2/3089.png',
        name: 'BSTC#3089',
        rank: 'Rank3079',
    },
    {
        url: './2/1225.png',
        name: 'BSTC#1225',
        rank: 'Rank3080',
    },
    {
        url: './2/1934.png',
        name: 'BSTC#1934',
        rank: 'Rank3081',
    },
    {
        url: './2/2395.png',
        name: 'BSTC#2395',
        rank: 'Rank3082',
    },
    {
        url: './2/2418.png',
        name: 'BSTC#2418',
        rank: 'Rank3083',
    },
    {
        url: './2/2472.png',
        name: 'BSTC#2472',
        rank: 'Rank3084',
    },
    {
        url: './2/401.png',
        name: 'BSTC#401',
        rank: 'Rank3085',
    },
    {
        url: './2/517.png',
        name: 'BSTC#517',
        rank: 'Rank3086',
    },
    {
        url: './2/472.png',
        name: 'BSTC#472',
        rank: 'Rank3087',
    },
    {
        url: './2/1167.png',
        name: 'BSTC#1167',
        rank: 'Rank3088',
    },
    {
        url: './2/1631.png',
        name: 'BSTC#1631',
        rank: 'Rank3089',
    },
    {
        url: './2/188.png',
        name: 'BSTC#188',
        rank: 'Rank3090',
    },
    {
        url: './2/1567.png',
        name: 'BSTC#1567',
        rank: 'Rank3091',
    },
    {
        url: './2/2498.png',
        name: 'BSTC#2498',
        rank: 'Rank3092',
    },
    {
        url: './2/1275.png',
        name: 'BSTC#1275',
        rank: 'Rank3093',
    },
    {
        url: './2/1668.png',
        name: 'BSTC#1668',
        rank: 'Rank3094',
    },
    {
        url: './2/2107.png',
        name: 'BSTC#2107',
        rank: 'Rank3095',
    },
    {
        url: './2/2245.png',
        name: 'BSTC#2245',
        rank: 'Rank3096',
    },
    {
        url: './2/2535.png',
        name: 'BSTC#2535',
        rank: 'Rank3097',
    },
    {
        url: './2/675.png',
        name: 'BSTC#675',
        rank: 'Rank3098',
    },
    {
        url: './2/2984.png',
        name: 'BSTC#2984',
        rank: 'Rank3099',
    },
    {
        url: './2/2010.png',
        name: 'BSTC#2010',
        rank: 'Rank3100',
    },
    {
        url: './2/2736.png',
        name: 'BSTC#2736',
        rank: 'Rank3101',
    },
    {
        url: './2/3045.png',
        name: 'BSTC#3045',
        rank: 'Rank3102',
    },
    {
        url: './2/1490.png',
        name: 'BSTC#1490',
        rank: 'Rank3103',
    },
    {
        url: './2/2709.png',
        name: 'BSTC#2709',
        rank: 'Rank3104',
    },
    {
        url: './2/1781.png',
        name: 'BSTC#1781',
        rank: 'Rank3105',
    },
    {
        url: './2/1042.png',
        name: 'BSTC#1042',
        rank: 'Rank3106',
    },
    {
        url: './2/2799.png',
        name: 'BSTC#2799',
        rank: 'Rank3107',
    },
    {
        url: './2/1547.png',
        name: 'BSTC#1547',
        rank: 'Rank3108',
    },
    {
        url: './2/1868.png',
        name: 'BSTC#1868',
        rank: 'Rank3109',
    },
    {
        url: './2/1657.png',
        name: 'BSTC#1657',
        rank: 'Rank3110',
    },
    {
        url: './2/120.png',
        name: 'BSTC#120',
        rank: 'Rank3111',
    },
    {
        url: './2/624.png',
        name: 'BSTC#624',
        rank: 'Rank3112',
    },
    {
        url: './2/2519.png',
        name: 'BSTC#2519',
        rank: 'Rank3113',
    },
    {
        url: './2/2224.png',
        name: 'BSTC#2224',
        rank: 'Rank3114',
    },
    {
        url: './2/1341.png',
        name: 'BSTC#1341',
        rank: 'Rank3115',
    },
    {
        url: './2/1123.png',
        name: 'BSTC#1123',
        rank: 'Rank3116',
    },
    {
        url: './2/1604.png',
        name: 'BSTC#1604',
        rank: 'Rank3117',
    },
    {
        url: './2/1875.png',
        name: 'BSTC#1875',
        rank: 'Rank3118',
    },
    {
        url: './2/2085.png',
        name: 'BSTC#2085',
        rank: 'Rank3119',
    },
    {
        url: './2/311.png',
        name: 'BSTC#311',
        rank: 'Rank3120',
    },
    {
        url: './2/1893.png',
        name: 'BSTC#1893',
        rank: 'Rank3121',
    },
    {
        url: './2/526.png',
        name: 'BSTC#526',
        rank: 'Rank3122',
    },
    {
        url: './2/2280.png',
        name: 'BSTC#2280',
        rank: 'Rank3123',
    },
    {
        url: './2/1158.png',
        name: 'BSTC#1158',
        rank: 'Rank3124',
    },
    {
        url: './2/2046.png',
        name: 'BSTC#2046',
        rank: 'Rank3125',
    },
    {
        url: './2/2440.png',
        name: 'BSTC#2440',
        rank: 'Rank3126',
    },
    {
        url: './2/1263.png',
        name: 'BSTC#1263',
        rank: 'Rank3127',
    },
    {
        url: './2/257.png',
        name: 'BSTC#257',
        rank: 'Rank3128',
    },
    {
        url: './2/2369.png',
        name: 'BSTC#2369',
        rank: 'Rank3129',
    },
    {
        url: './2/1797.png',
        name: 'BSTC#1797',
        rank: 'Rank3130',
    },
    {
        url: './2/919.png',
        name: 'BSTC#919',
        rank: 'Rank3131',
    },
    {
        url: './2/1529.png',
        name: 'BSTC#1529',
        rank: 'Rank3132',
    },
    {
        url: './2/1828.png',
        name: 'BSTC#1828',
        rank: 'Rank3133',
    },
    {
        url: './2/1179.png',
        name: 'BSTC#1179',
        rank: 'Rank3134',
    },
    {
        url: './2/1738.png',
        name: 'BSTC#1738',
        rank: 'Rank3135',
    },
    {
        url: './2/925.png',
        name: 'BSTC#925',
        rank: 'Rank3136',
    },
    {
        url: './2/1322.png',
        name: 'BSTC#1322',
        rank: 'Rank3137',
    },
    {
        url: './2/1780.png',
        name: 'BSTC#1780',
        rank: 'Rank3138',
    },
    {
        url: './2/2262.png',
        name: 'BSTC#2262',
        rank: 'Rank3139',
    },
    {
        url: './2/2561.png',
        name: 'BSTC#2561',
        rank: 'Rank3140',
    },
];

function App() {
    return (
      <MWrapper>
        <Wrapper>
          <Title>Baby Stonk Traders Club</Title>
          <SubTitle>The world's first education nft</SubTitle>
        <ImageWrapper>
          {itemData.map((item) => (
              <ListWrapper>
              <ImageListItem key={item.url}>
                <img
                    src={`${item.url}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.name}
                    loading="lazy"
                />
                <ImageListItemBar
                    title={<NameSpan>{item.name}</NameSpan>}
                    subtitle={<RankSpan>{item.rank}</RankSpan>}
                    position="below"
                />
              </ImageListItem>
              </ListWrapper>
          ))}
        </ImageWrapper>
        </Wrapper>
      </MWrapper>
  );
}

export default App;
